import { ApplicationRef, ElementRef, Injectable } from '@angular/core';
// capacitor imports
import { Plugins } from '@capacitor/core';
import { OpenVidu, Publisher, Session, Stream, StreamEvent, Subscriber } from 'openvidu-browser';
import { Subject } from 'rxjs';
import 'screen-sharing';
import { AudioLevel, sessionPrefix, StreamType, TECH_CHECK_TYPES, TECH_CHECK_VIDEO_PLAYER_MAX_TIMEOUT, UserMetaData } from 'src/constants';
import { SessionService } from './session.service';
import { StreamService } from './stream.service';

const { ScreenSharing } = Plugins;

interface StreamMap {
	session: Session;
	publisher: Publisher;
}

@Injectable({
	providedIn: 'root'
})
export class TechCheckService {

	constructor(
		private sessionService: SessionService,
		private streamService: StreamService,
		private applicationRef: ApplicationRef) { }

	public videoPlayerMaxTimeout = TECH_CHECK_VIDEO_PLAYER_MAX_TIMEOUT;
	private stream: MediaStream = null;
	private sessionID: string = null;
	private session: Session = null;
	private subscribers = new Map<string, Subscriber>();
	private streamMap: StreamMap[] = [];

	private videoPlayerTimeout: number = null;
	private videoPlayerTimeoutTries = 0;

	private audioLevelSubject: Subject<AudioLevel> = new Subject<AudioLevel>();
	public audioLevelListener = this.audioLevelSubject.asObservable();

	public currentCheck: TECH_CHECK_TYPES = TECH_CHECK_TYPES.BROWSER;

	private audioContext: AudioContext;
	private audioAnalyser: AnalyserNode;
	private audioSource: MediaStreamAudioSourceNode;
	private volumeInterval: number = null;
	private audioElement: HTMLAudioElement = new Audio();

	public checkStream(streamType: StreamType) {
		return new Promise((resolve, reject) => {
			if (!this.sessionService.sessionId || this.sessionService.sessionId.trim() == '') {
				reject('Invalid Session');
				return;
			}

			// disconnect previous
			this.stopAllStreams();

			// dummy session for tech checks, adding timestamp for randomness
			if (!this.sessionID) {
				this.sessionID = this.sessionService.sessionId + sessionPrefix;
			}
			this.sessionService.fetchToken(streamType, this.sessionService.userType, this.sessionID).then(({ token, iceServers }) => {
				const send = () => {
					if (isIOS || (isMobile && streamType != StreamType.AUDIO)) {
						this.sessionService.processToken(token, iceServers).then(res => {
							ScreenSharing.startStream({ streamType, ...res }).then(resolve).catch(reject);
						}).catch(reject);
					} else {
						this.streamService.addStream(streamType, true).then(stream => {
							resolve(this.sendStream(token, stream));
						}).catch(reject);
					}
				};

				if (!this.session) {
					this.receiveStream().then(() => send()).catch(reject);
				}
				else {
					send();
				}
			}).catch(reject);
		});
	}

	private receiveStream(): Promise<void> {
		return new Promise((resolve, reject) => {
			this.sessionService.fetchToken(null, this.sessionService.userType, this.sessionID).then(({ token }) => {
				// init openvidu
				const OV = new OpenVidu();
				if (OV && OV instanceof OpenVidu && typeof OV.initSession == 'function') {
					// init session
					this.session = OV.initSession();
					if (this.session) {
						// subscribe to only stream events
						this.session.on('streamCreated', (e: StreamEvent) => this.subscribeToStream(e));
						this.session.on('streamDestroyed', (e: StreamEvent) => this.unsubscribeToStream(e));
						// connect to session
						this.session.connect(token)
							.then(() => {
								resolve();
							}).catch(err => {
								reject('Error in connecting to server: ' + err);
							});
					} else {
						reject('Error in initializing session');
					}
				} else {
					reject('Browser not compatible');
				}
			}).catch(err => {
				reject('Error in connecting to server: ' + err);
			});
		});
	}

	private subscribeToStream(e: StreamEvent) {
		const stream: Stream = e.stream;
		let streamType: StreamType = null;
		if (stream && stream.connection && stream.connection.data && stream.connection.data.trim() !== '') {
			try {
				const data: UserMetaData = JSON.parse(stream.connection.data);
				if (data &&
					data.streamType &&
					data.userType &&
					data.userType == this.sessionService.userType
				) {
					streamType = data.streamType;
				} else {
					return;
				}

			} catch (e) {
				const err = 'Data not json parsable:' + stream.connection.data;
				logger.log(err);
			}
		} else {
			return;
		}

		const subscribe = (retry = 0) => {
			// have to subscribe in order to recieve the stream
			this.session.subscribeAsync(stream, null).then((subscriber: Subscriber) => {
				// sending stream to view
				const mediaStream = stream.getMediaStream();
				if (mediaStream.id && typeof mediaStream.getTracks == 'function' && mediaStream.getTracks().length) {
					// unsubscribe from previous
					if (this.stream && this.stream.id) {
						this.session.unsubscribe(this.subscribers.get(this.stream.id));
						this.subscribers.delete(this.stream.id);
					}
					// insert new
					this.stream = mediaStream;
					this.subscribers.set(this.stream.id, subscriber);

					if (streamType == StreamType.AUDIO) {
						// process audio
						this.processAudio(mediaStream);
					} else {

					}
				}
			}).catch(err => {
				if (retry > 5) {
					const e = 'Max retry hit, dropping subscription';
					logger.log(e);
					return;
				} else {
					const e = 'Unable to subscribe, trying again:' + err;
					logger.log(e);
					if (!stream.connection.disposed) {
						setTimeout(() => subscribe(++retry), 500);
					}
				}
			});
		};
		subscribe();
	}

	private unsubscribeToStream(e: StreamEvent) {
		const stream: Stream = e.stream;
		if (stream && stream.connection && stream.connection.connectionId && stream.connection.connectionId.trim() != '') {
			try {
				const mediaStream = stream.getMediaStream();
				const connectionID = mediaStream && mediaStream.id ? mediaStream.id : null;
				// if subscriber present then unsubscribe
				if (connectionID && this.subscribers.has(connectionID)) {
					this.session.unsubscribe(this.subscribers.get(connectionID));
					this.subscribers.delete(connectionID);
				}

				if (this.stream && this.stream.id && connectionID && this.stream.id == connectionID) {
					typeof this.stream.getTracks == 'function' && this.stream.getTracks().forEach(d => d.stop());
					this.stream = null;
				}
			} catch (e) {
				logger.error('Exception occurred', e);
			}
		} else {
			return;
		}
	}

	private sendStream(token: string, stream: MediaStream): Promise<void> {
		return new Promise((resolve, reject) => {
			// init openvidu
			const OV = new OpenVidu();
			if (OV && OV instanceof OpenVidu && typeof OV.initSession == 'function') {
				// init session
				const session = OV.initSession();
				if (session && typeof stream.getAudioTracks == 'function' && typeof stream.getVideoTracks == 'function') {

					const audioTracks = stream.getAudioTracks();
					const videoTracks = stream.getVideoTracks();

					// connect to session
					session.connect(token)
						.then(() => {
							OV.initPublisherAsync(null, {
								audioSource: audioTracks && audioTracks.length ? audioTracks[0] : false,
								videoSource: videoTracks && videoTracks.length ? videoTracks[0] : false,
								publishVideo: videoTracks && videoTracks.length ? true : false,
								publishAudio: audioTracks && audioTracks.length ? true : false,
							}).then(publisher => {
								// publishing the publisher onto stream, ref - https://docs.openvidu.io/en/2.14.0/api/openvidu-browser/classes/session.html#publish
								session.publish(publisher)
									.then(() => {
										this.streamMap.push({
											publisher,
											session
										});
										resolve();
									}).catch(reject);
							}).catch(reject);
						}).catch(reject);
				} else {
					reject('Error in initializing session');
				}
			} else {
				reject('Browser not compatible');
			}
		});
	}

	private stopAllStreams() {
		// stop all previous streams
		ScreenSharing.stopAllStreams();
		this.streamService.stopAllStreams();

		this.streamMap.forEach(d => {
			// disconnect session
			d.session && d.session.disconnect();
		});

		this.streamMap = [];

		this.stopAudioProcessing();
	}

	public stopCheck() {
		this.stopAllStreams();
		this.subscribers.forEach(d => this.session && this.session.unsubscribe(d));
		this.session && this.session.disconnect();
		this.subscribers.clear();
		this.session = null;
	}

	/**
	 * Tries to play the video player element from the stream recieved
	 * @param playerElement
	 * Rejects if unplayable in videoPlayerTimeoutTime
	 */
	public checkIfVideoPlaying(playerElement: ElementRef<HTMLMediaElement>) {
		const videoPlayerTimeoutTime = 10;
		if (this.videoPlayerTimeout) {
			clearTimeout(this.videoPlayerTimeout);
			this.videoPlayerTimeout = null;
			this.videoPlayerTimeoutTries += videoPlayerTimeoutTime;
		} else {
			this.videoPlayerTimeoutTries = 0;
		}
		return new Promise((resolve, reject) => {

			if (this.videoPlayerTimeoutTries >= this.videoPlayerMaxTimeout) {
				reject();
				return;
			}
			// presses play on element
			if (playerElement && playerElement.nativeElement && this.stream && typeof this.stream.getVideoTracks == 'function') {
				const videoTracks = this.stream.getVideoTracks();
				if (videoTracks.length && videoTracks[0].readyState == 'live') {
					const func = () => {
						const promise = playerElement.nativeElement.play();
						if (promise && promise.then && promise.catch) {
							promise
								.then(resolve)
								.catch((err) => {
									logger.error('Error playing video: ' + err);
									reject();
								})
								.finally(() => {
									if (this.videoPlayerTimeout) {
										clearTimeout(this.videoPlayerTimeout);
										this.videoPlayerTimeout = null;
									}
								});
						} else {
							reject('Browser not compatible');
						}
						this.applicationRef.tick();
						playerElement.nativeElement.removeEventListener('canplaythrough', func);
					};
					playerElement.nativeElement.srcObject = this.stream;
					playerElement.nativeElement.addEventListener('canplaythrough', func);
					if (this.videoPlayerTimeout) {
						clearTimeout(this.videoPlayerTimeout);
						this.videoPlayerTimeout = null;
					}
					this.videoPlayerTimeout = window.setTimeout(() => {
						playerElement.nativeElement.removeEventListener('canplaythrough', func);
						reject();
					}, this.videoPlayerMaxTimeout);
				} else {
					this.videoPlayerTimeout = window.setTimeout(() => resolve(this.checkIfVideoPlaying(playerElement)), videoPlayerTimeoutTime);
				}
			} else {
				this.videoPlayerTimeout = window.setTimeout(() => resolve(this.checkIfVideoPlaying(playerElement)), videoPlayerTimeoutTime);
			}
		});
	}

	/********* Audio Visualizer Code -> Modify with caution *********
	 * It sends out audio volume levels as the user is speaking
	*/

	private processAudio(stream) {
		if (typeof window.AudioContext !== 'undefined' || typeof window.webkitAudioContext !== 'undefined') {
			const audioTracks = stream.getAudioTracks();
			if (audioTracks.length) {
				const AudioContextCross = window.AudioContext || window.webkitAudioContext;
				this.audioContext = new AudioContextCross();

				// need to hook remote stream to a audio element for the webaudio api to work, no need to playit
				// bug at chromium -> https://bugs.chromium.org/p/chromium/issues/detail?id=121673
				this.audioElement.srcObject = stream;

				// create source
				this.audioSource = this.audioContext.createMediaStreamSource(stream);

				// create analyser
				this.audioAnalyser = this.audioContext.createAnalyser();
				this.audioAnalyser.fftSize = 512;
				this.audioAnalyser.minDecibels = -127;
				this.audioAnalyser.maxDecibels = 0;
				this.audioAnalyser.smoothingTimeConstant = 0.5;

				// connect source to analyser
				this.audioSource.connect(this.audioAnalyser);

				// send volume at constant interval
				this.volumeInterval = window.setInterval(() => {
					const volume = this.getAverageVolume();
					this.audioLevelSubject.next({ volume, enabled: volume > 0 });
				});
			} else {
				this.audioLevelSubject.next({ volume: 0, enabled: false });
			}
		} else {
			alert('Browser Not compatible');
		}
	}

	private getAverageVolume(): number {
		const data: Uint8Array = new Uint8Array(100);
		this.audioAnalyser.getByteFrequencyData(data);
		let values = 0;
		let volume = 0;
		if (data && data.length) {
			// get all the frequency amplitudes
			data.forEach(t => values += t);
			const average = values / data.length;
			volume = average * 100 / (this.audioAnalyser.maxDecibels - this.audioAnalyser.minDecibels);
		}
		return volume;
	}

	public stopAudioProcessing() {
		if (this.volumeInterval) {
			clearInterval(this.volumeInterval);
			this.audioSource && this.audioSource.disconnect();
			this.audioAnalyser && this.audioAnalyser.disconnect();
			this.audioContext && this.audioContext.close();
			this.volumeInterval = null;
			this.audioAnalyser = null;
			this.audioSource = null;
			this.audioContext = null;
		}
	}
}
