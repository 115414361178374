import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SvgUseComponent } from './svg-use.component';
import { SvgIconsComponent } from './svg-icons/svg-icons.component';
import { LoaderComponent } from './loader/loader.component';

@NgModule({
	declarations: [
		SvgIconsComponent,
		SvgUseComponent,
		LoaderComponent
	],
	imports: [
		CommonModule,
	],
	providers: [

	],
	exports: [
		SvgIconsComponent,
		SvgUseComponent,
		LoaderComponent
	]
})
export class SvgUseModule { }
