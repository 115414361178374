import { Component, ViewEncapsulation, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { ConnectionService } from '../../../services/connection.service';
import { ChatRoom, MessageType, CallOptions, SessionMode } from 'src/constants';
import { CommonService } from 'src/app/services/common.service';
import { SessionService } from 'src/app/services/session.service';

@Component({
	selector: 'app-tester',
	templateUrl: './tester.component.html',
	styleUrls: ['./tester.component.styl'],
	encapsulation: ViewEncapsulation.ShadowDom
})
export class TesterComponent implements AfterViewInit {

	private chatRooms: Array<ChatRoom> = [
		{
			title: 'Moderator',
			type: MessageType.TESTER_MODERATOR
		}
	];

	private callOptions: CallOptions = {
		mic: true,
		cam: true,
		screen: true,
		endSession: false,
		notes: false
	};

	constructor(
		private connectionService: ConnectionService,
		private commonService: CommonService,
		private sessionService: SessionService,
		private changeDetectorRef: ChangeDetectorRef) {
	}

	ngAfterViewInit() {
		// setting up different tabs in chat window
		this.connectionService.setChatRooms(this.chatRooms);
		this.commonService.callOptions = this.callOptions;
		this.changeDetectorRef.detectChanges();

		this.sessionService.sessionModeChangeListener.subscribe(sessionState => {
			if (sessionState.sessionMode && this.sessionService.sessionMode !== sessionState.sessionMode) {
				this.sessionService.sessionMode = sessionState.sessionMode;
				if (sessionState.sessionMode === SessionMode.DEBRIEFING) {
					this.commonService.endCall();
					alert({ text: 'The Session has completed. Thanks for your particpation in this Research', icon: ICON.INFO });
				}
				this.changeDetectorRef.detectChanges();
			}
		});
	}

}
