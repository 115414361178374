import { CipherGCMTypes } from "crypto";

// Data packet format received in response when encryption is enabled
export interface EncryptedDataPacket {
	data: any
	resend?: boolean;
}

export interface EncryptedQueryParams {
	params: any,
	path: string
}

export interface EncryptedAuthHeader {
	iv: string,
	secretKey?: string,
	additionalAuthData: string,
}
export interface AuthHeader {
	publicKey: string
	encryptedAuthHeader: string
}

// https://nodejs.org/dist/latest-v14.x/docs/api/crypto.html#crypto_crypto_generatekeypairsync_type_options
const serverKeyAlgo: 'rsa' | 'dsa' | 'ec' | 'ed25519' | 'ed448' | 'x25519' | 'x448' | 'dh' = 'rsa';
// algorithm used for encryption -> ref - https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/encrypt#supported_algorithms
// Algo used to encrypt/decrypt AES keys and authentication data
export const KEY_ENCRYPTION_ALGORITHM = {
	name: "RSA-OAEP",
	serverKeyAlgo,
	// Consider using a 4096-bit key for systems that require long-term security
	modulusLength: 4096,
	publicExponent: new Uint8Array([1, 0, 1]),
	// used for web
	hash: "SHA-256",
	// used for node
	shortHashName: 'sha256',
	label: new Uint8Array([1, 0, 1])
};

// algo used to encrypt/decrypt data
const serverDataAlgo: CipherGCMTypes = 'aes-256-gcm';
export const DATA_ENCRYPTION_ALGORITHM = {
	name: "AES-GCM",
	// in bits
	length: 256,
	// in bytes
	get lenBytes() { return this.length / 8; },
	shortname: serverDataAlgo,
	tagLength: 128,
	get authTagLength() { return this.tagLength / 8; },
	ivLength: 16
};


// ref - https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/importKey#supported_formats
export const ENCRYPTION_KEY_FORMAT = 'spki';


// kind of users that will use the system, do not change
export enum USER_TYPE {
	TESTER = 'PARTICIPANT',
	MODERATOR = 'MODERATOR',
	OBSERVER = 'OBSERVER',
	NOTE_TAKER = 'NOTE_TAKER',
	TRANSLATOR = 'TRANSLATOR',
	ADMIN = 'ADMIN',
	SELF = 'SELF',
	SUBSCRIBER = 'SUBSCRIBER'
}

export interface PeerData {
	name: string;
	userId: string;
	streamType: StreamType;
	connectionId: string;
	oldConnectionId?: string;
	isChiefModerator: boolean;
	isMobile: boolean;
}

export interface UserMetaData extends PeerData {
	userType: USER_TYPE;
}

// data passed to node server, data property will be passed to all clients via media server
export interface ServerData {
	data: UserMetaData;
	kurentoOptions?: {
		videoMaxRecvBandwidth?: number;
		videoMinRecvBandwidth?: number;
		videoMaxSendBandwidth?: number;
		videoMinSendBandwidth?: number;
		allowedFilters?: string[];
	};
}

export enum Path {
	TOKEN = '/token',
	START_RECORDING = '/startRecording',
	STOP_RECORDING = '/stopRecording',
	STOP_SESSION = '/terminate',
	FETCH_RECORDING = '/fetchRecording',
	JOIN_SESSION = '/joinSession',
	FETCH_PENDING_REQUEST = '/fetchPendingRequest',
	ALLOW_JOIN = '/allowJoin',
	FORCE_DISCONNECTION = '/forceDisconnection',
	GET_JOIN_STATUS = '/getJoinStatus',
	SET_SESSION_MODE = '/setSessionMode',
	GET_SESSION_MODE = '/getSessionMode',
	SAVE_CHATS = '/saveChats',
	SAVE_NOTES = '/saveNotes',
	SAVE_JOIN_STATUS = '/saveJoinStatus',
	SAVE_BOOKMARK = '/saveBookmark',
	VERIFY_SESSION = '/verifySession',
	IS_CHIEF_MODERATOR_PRESENT = '/isChiefModeratorPresent',
	SAVE_PHOTO = '/savephoto',
	FETCH_RECORDING_STATUS = '/fetchRecordingStatus',
	LOG = '/log',
	FEEDBACK = '/feedback',
	SAVE_INSTRUCTIONS = '/saveInstructions',
	RECORDER_EVENT_LOG = '/recorderEventLog',
	ENCRYPTED = '/en',
	DELETE_NOTE = '/deleteNote',
	GET_SESSION_START_TIME = '/getSessionStartTime',
	GET_CONNECTIONS = '/getConnections',
	INVALIDATE_UNHEALTHY_CONNECTIONS = '/invalidateUnhealthyConnections'
}

export enum SessionMode {
	BRIEFING = 'BRIEFING',
	DEBRIEFING = 'DEBRIEFING',
	IN_SESSION = 'IN_SESSION',
}

export enum JoinRequestStatus {
	PENDING = 'PENDING',
	ALLOW = 'ALLOW',
	REJECT = 'REJECT',
	NONE = 'NONE',
	ROOM_FULL = 'ROOM_FULL'
}

export enum StreamType {
	AUDIO = 'AUDIO',
	CAMERA = 'CAMERA',
	SCREEN = 'SCREEN',
	SELF = 'SELF',
	PLACEHOLDER = 'PLACEHOLDER',
	AUDIO_RECEIVER = 'AUDIO_RECEIVER'
}

export enum MessageType {
	TEAM = 'TEAM',
	TESTER_MODERATOR = 'TESTER_MODERATOR',
	SESSION_MODE = 'SESSION_MODE',
	MUTE_EVENT = 'MUTE_EVENT',
	STOP_SCREEN_SHARING = 'STOP_SCREEN_SHARING',
	INSTRUCTIONS_CHANGE = 'INSTRUCTIONS_CHANGE',
	TESTER_NAME = 'TESTER_NAME',
	REQUEST_SESSION_MODE = 'REQUEST_SESSION_MODE'
}

export enum RecordingEventType {
	RECORDING_NOT_STARTED = 'RECORDING_NOT_STARTED',
	RECORDING_STARTED = 'RECORDING_STARTED',
	RECORDING_STOPPED = 'RECORDING_STOPPED',
	RECORDING_READY = 'RECORDING_READY',
	RECORDING_STARTED_SIGNAL = 'recordingStarted',
	RECORDING_STOPPED_SIGNAL = 'recordingStopped'
}

export const TERMINATED_FROM_YII = 'signal:terminatedFromYii';

// KEY for recorder user
export const TOKEN_KEY = 'token';
export const STREAMS_TO_CAPTURE = 'streamsToCapture';
export const USERS_TO_CAPTURE = 'usersToCapture';

// session if used for tech check service, this prefix is used in UI also, so change in both places
// DUMMY_TECH_CHECK_SESSION_PREFIX is ignored for recording
export const DUMMY_TECH_CHECK_SESSION_PREFIX = '_CHECK_';

export interface SavedNote {
	userRole: USER_TYPE;
	userId: string;
	userName: string;
	timestamp: number;
	comment: string;
	connectionID: string;
	id: number;
	seekable: boolean,
	modePlaceholder: boolean,
	mode: SessionMode
}

export interface GenericObject extends Object {
	[key: string]: any
};

export interface SavedChat {
	userName: string;
	userRole: USER_TYPE;
	userId: string;
	messageType: MessageType;
	timestamp: number;
	comment: string;
	connectionId: string;
}

export const KICKED_OUT_FALSE: string = '0';
export const KICKED_OUT_TRUE: string = '1';