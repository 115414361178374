import { environment } from 'src/environments/environment';
import { Component, ViewEncapsulation, Input, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { Message, MessageSentStatus, USER_TYPE, JoinStatus, ConnectionEventType, isJoinStatus } from 'src/constants';
import { CommonService } from 'src/app/services/common.service';

// library used to detect urls - https://github.com/validatorjs/validator.js
import Validator from 'validator';

// private interfaces, only for use in this component
enum TextType {
	LINK = 'LINK',
	TEXT = 'TEXT',
}

interface TextMessage {
	type: TextType;
	value: string;
	link?: string;
	status?: ConnectionEventType;
}

@Component({
	selector: 'app-message',
	templateUrl: './message.component.html',
	styleUrls: ['./message.component.styl'],
	encapsulation: ViewEncapsulation.ShadowDom
})

export class MessageComponent {

	@Output() resend: EventEmitter<{ time: number, text: string }> = new EventEmitter();

	// for use in html
	public TextType = TextType;
	public ConnectionEventType = ConnectionEventType;
	public MessageSentStatus = MessageSentStatus;

	// contains the text message in array form for formatting
	public content: Array<TextMessage> = [];
	// marks if message recieved is by self
	public self = false;
	// marks if data recieved is a joinStatus or not
	public joinStatus = false;
	// background color of the circle beside name as per user type
	public bgAlphaColor: string;
	// the data recieved if a message
	public msg: Message;
	// the text content of the message
	public text: string;

	@Input() set data(value: Message | JoinStatus) {
		if (value) {
			this.text = value?.msg;
			if (isJoinStatus(value)) {
				// process as join status
				this.content.push({ type: TextType.TEXT, value: value?.msg, status: value?.type });
				this.joinStatus = true;
			} else {
				// process as message
				this.msg = value;

				this.bgAlphaColor = this.getAlphaBackground(this.msg?.from?.color);
				if (this.msg?.from?.userType === USER_TYPE.SELF) {
					this.self = true;
				}

				if(!this.msg?.localTimeString)
					this.msg.localTimeString = this.commonService.formatTimeStamp(this.msg?.time);

				this.content = [];
				// splitting the whole messages into words to find segments of urls
				const words = value?.msg?.trim()?.split(' ');
				let textLine = '';
				words.forEach(word => {
					// if current word is a url, then push it in the array as link, and text collected till here as text
					if (Validator?.isURL(word)) {
						let link = word;
						// if url does not have a protocol to it, add http://
						if (!Validator.isURL(word, { require_protocol: true })) {
							link = 'http://' + word;
						}
						const textTillHere: TextMessage = { type: TextType.TEXT, value: textLine};
						const hyperLink: TextMessage = { type: TextType.LINK, value: word, link };
						this.content.push(textTillHere, hyperLink);
						textLine = ' ';
					} else {
						// join words to form sentences
						textLine += word + ' ';
					}
				});
				if (textLine !== '') {
					this.content.push({ type: TextType.TEXT, value: textLine });
				}
			}
			this.changeDetectorRef.detectChanges();
		}
	}

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		public commonService: CommonService) { }

	public retry() {
		this.resend.emit({ time: this.msg?.time, text: this.text });
	}

	public getAlphaBackground(hex) {
		if (!hex || (hex && hex.trim() == '')) {
			hex = '#ccbdb0';
		}

		const alpha = 0.25;

		let c;
		if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
			c = hex.substring(1).split('');
			if (c.length == 3){
				c = [c[0], c[0], c[1], c[1], c[2], c[2]];
			}
			c = '0x' + c.join('');
			return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + alpha + ')';
		}
		return hex;
	}
}
