import { Component, ViewEncapsulation, ChangeDetectorRef, ViewChild, ElementRef, AfterViewInit, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { SessionService } from 'src/app/services/session.service';
import { RequestService } from 'src/app/services/request.service';
import { SessionMode, SavedNote, ToggleEventType, RecordingEventType, SetIDEvent } from 'src/constants';
import { Subscription } from 'rxjs';
import { USER_TYPE } from '../../../../../../../commonConstants';

@Component({
	selector: 'app-notes',
	templateUrl: './notes.component.html',
	styleUrls: ['./notes.component.styl', '../../../../../assets/scrollbar.css'],
	encapsulation: ViewEncapsulation.None
})
export class NotesComponent implements AfterViewInit, OnDestroy {

	@ViewChild('textArea') textAreaElement: ElementRef<HTMLElement>;
	@ViewChild('container') container: ElementRef<HTMLElement>;

	@Output() noteAdded = new EventEmitter<SavedNote>();
	@Output() setID = new EventEmitter<SetIDEvent>();
	@Output() removeNote = new EventEmitter<SavedNote>();

	@Input() set data(note: SavedNote) {
		if (note) {
			this.setNote(note);
			// component has intialized -> detect change
			if (this.textAreaElement?.nativeElement)
				this.changeDetectorRef.detectChanges();
		}
	}
	public note: SavedNote = null;

	private subs: Subscription[] = [];
	public placeholderText = isMobile ? '' : '⇧ + ↵ add a new line, ↵ save\n⇧ + DEL delete note';

	// timestamp, one for sending to backend, one for showing on screen
	private notesTimeStamp: number;
	public formattedNotesTimeStamp: string;

	private saveNoteTimeout = null;

	constructor(
		private commonService: CommonService,
		private sessionService: SessionService,
		private requestService: RequestService,
		private changeDetectorRef: ChangeDetectorRef) {
	}

	private setNote(note: SavedNote) {
		this.note = note;
		this.setTime(note?.timestamp);
	}

	private setFocus() {
		let requestID;
		if (typeof this.textAreaElement?.nativeElement?.focus == 'function') {
			this.textAreaElement.nativeElement.focus();
			window.cancelAnimationFrame(requestID);
		} else {
			requestID = window.requestAnimationFrame(() => this.setFocus());
		}
	}

	ngAfterViewInit() {
		if (this.note == null) {
			const toggleSub = this.commonService.toggleEventListener.subscribe(res => {
				if (res && res == ToggleEventType.NOTES && this.commonService.notesInFocus) {
					this.setFocus();
				}
			});
			this.setFocus();
			this.subs.push(toggleSub);
		}
	}

	public keyHandler(event: KeyboardEvent) {
		if (event.key?.toLowerCase() === 'delete' && event.shiftKey && this.note?.id) {
			this.removeNote.emit(this.note)
			this.requestService.deleteNote(this.sessionService.sessionId, this.note);
		}
		// restricts input to put new line on enter and allows on shift + enter
		else if (event.key?.toLowerCase() === 'enter' && !event.shiftKey) {
			event.preventDefault();
		} else if(this.note){
			this.clearTimeout();
			this.saveNoteTimeout = setTimeout(() => this.takeNote(), 1000);
		}
	}

	private setTime(time = Date.now()) {
		this.notesTimeStamp = time;
		// if (this.sessionService.recordingStartTime)
		// 	this.formattedNotesTimeStamp = this.sessionService.getTimeDifference(time, this.sessionService.recordingStartTime);
		// else
		this.formattedNotesTimeStamp = this.commonService.formatTimeStamp(time);
	}

	public takeNote() {
		this.clearTimeout();
		const comment = this.textAreaElement?.nativeElement?.textContent;
		if (comment?.trim()?.length) {
			// do something with the note and timestamp and then close
			// see if it is a new note or an edit
			let note: SavedNote = JSON.parse(JSON.stringify(this.note));
			if (note) {
				note.comment = comment;
			} else {
				// set timestamp
				this.setTime();
				let userId: string = this.sessionService.userType;
				if(!this.sessionService.isChiefModerator && this.sessionService.userType == USER_TYPE.MODERATOR) {
					userId = 'Co-Facilitator';
				}
				note = {
					userName: this.sessionService.name,
					userRole: this.sessionService.userType,
					userId: userId,
					timestamp: this.notesTimeStamp,
					comment,
					connectionID: this.sessionService?.connectionId,
					id: -this.notesTimeStamp,
					// seekable only for period when recoding is running
					seekable: !(this.sessionService.recordingStatus === RecordingEventType.RECORDING_STOPPED),
					modePlaceholder: false,
					// if recording has stopped that means in either in-session or debriefing
					mode: this.sessionService.sessionMode === SessionMode.IN_SESSION
						? 
						SessionMode.IN_SESSION
						:
						this.sessionService.recordingStatus === RecordingEventType.RECORDING_STOPPED
							?
							SessionMode.DEBRIEFING
							:
							SessionMode.BRIEFING
				};

				// send new note to parent
				this.noteAdded.emit(note);

				// reset
				this.textAreaElement.nativeElement.textContent = null;
				this.setTime(null);

				// steal focus
				this.setFocus();
			}
			// save at backend
			this.requestService.saveNotes(this.sessionService.sessionId, note)
				.then(newID => {
					if (newID > 0) {
						this.setID.emit({ oldID: note.id, newID });
					}
				})
				.catch(() => { })

			this.changeDetectorRef.detectChanges();
		}
	}

	private blink() {
		const el = this.container?.nativeElement;
		if (el) {
			el.style.opacity = '0.5';
			setTimeout(() => {
				el.style.opacity = '1';
			}, 200);
		}
	}

	private clearTimeout() {
		if (this.saveNoteTimeout) {
			clearTimeout(this.saveNoteTimeout);
			this.saveNoteTimeout = null;
		}
	}

	ngOnDestroy() {
		this.subs?.forEach(sub => sub?.unsubscribe());
	}
}
