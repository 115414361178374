import { Component, ViewEncapsulation, ChangeDetectorRef, AfterViewInit, OnDestroy, ElementRef, ViewChildren, QueryList, ViewChild } from '@angular/core';
import { StreamEventMessage, StreamEventType, StreamType, MessageType, Message, USER_TYPE, SessionMode, IOS_FOREGROUND } from 'src/constants';
import { Subscription } from 'rxjs';
import { SessionService } from 'src/app/services/session.service';
import { ConnectionService } from 'src/app/services/connection.service';
import { CommonService } from 'src/app/services/common.service';
import { RecordingEventType } from '../../../../../../commonConstants';

// capacitor imports
import { Plugins } from '@capacitor/core';
const { ScreenSharing } = Plugins;
import 'screen-sharing';

const resetVideoTimeout = 10; // ms

@Component({
	selector: 'app-video-player',
	templateUrl: './video-player.component.html',
	styleUrls: ['./video-player.component.styl', '../../../../assets/scrollbar.css'],
	encapsulation: ViewEncapsulation.ShadowDom
})
export class VideoPlayerComponent implements AfterViewInit, OnDestroy {
	@ViewChildren('player') playerElements: QueryList<ElementRef<HTMLMediaElement>>;

	public RecordingEventType = RecordingEventType;

	private subs: Subscription[] = [];
	// self cam stream
	public selfCamStream: StreamEventMessage;

	// video streams
	public videoStreams: Array<StreamEventMessage> = [];
	public placeholderStreams: Array<StreamEventMessage> = [];

	public StreamType = StreamType;
	private setVideoTimeout = null;

	public fullScreen = false;
	public tooltipX: string = '';
	private pendingEvents: Message[] = [];
	public isSelfCamOpened: boolean = true;
	public isMobileVideoLandscape: boolean = false;

	// use in html
	public USER_TYPE = USER_TYPE;
	public SessionMode = SessionMode;

	public mobilePlaceholder: StreamEventMessage = null;
	public participantCamera: StreamEventMessage = null;
	public participantScreen: StreamEventMessage = null;
	public moderatorScreen: StreamEventMessage = null;

	// hold camera and screen of participant from mobile for special handling
	public mobileParticipantStreams: StreamEventMessage[] = [];
	public mobileParticipantStreamDetail: StreamEventMessage;

	public fetchResource = fetchResource;

	public showPlaceholder = false;
	public toShowNoVideo = false;

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		public sessionService: SessionService,
		private connectionService: ConnectionService,
		public commonService: CommonService) {
		// ios pauses videos when app goes to background, this event is fired from plugin when app is coming to foreground, video is replayed in case they have paused
		if (isIOS) {
			ScreenSharing.addListener(IOS_FOREGROUND, () => {
				this.replayVideo();
			});
		}
	}

	ngAfterViewInit() {
		const streamsSub = this.sessionService.streamEventListener.subscribe((res: StreamEventMessage) => this.handleStreamEvents(res));

		// listen for mute events from peers
		const muteSub = this.sessionService.messageEventListener.subscribe((data) => {
			if (data && data.type === MessageType.MUTE_EVENT) {
				let memberFound = false;

				// find in all
				[this.commonService.videoStreams, this.commonService.placeholderStreams, this.commonService.mobileParticipantStreams].forEach(arr => {
					arr.forEach(x => {
						if (x.from.connectionId === data.from.connectionId) {
							// delibrate double equals, do not change
							x.muted = data.msg == 'true';
							memberFound = true;
						}
					});
				});

				// remove old events if same connection
				this.pendingEvents = this.pendingEvents.filter(d => d.from.connectionId !== data.from.connectionId);
				// if not found in any list then maybe video has not been added yet, will process event later
				!memberFound && this.pendingEvents.push(data);
			}
		});
		this.subs.push(streamsSub, muteSub);
	}

	private handleStreamEvents(res: StreamEventMessage) {
		if (res?.kind !== StreamType.AUDIO) {
			// video streams management
			if (res?.type === StreamEventType.STREAM_ADDED) {
				if (res.kind === StreamType.PLACEHOLDER) {
					this.commonService.placeholderStreams.push(res);
				} else if (res.kind === StreamType.SELF) {
					this.selfCamStream = res;
					// error handling if due to some reason cam shows disabled after turning on
					if (this.commonService.disableCamButton || !this.commonService.camEnabled) {
						this.commonService.disableCamButton = false;
						this.commonService.camEnabled = true;
					}
				} else {
					// finding the member from connection services members list
					let member = null;
					for (const [key, memberList] of this.connectionService.members) {
						member = memberList.find(l => l.connectionId === res.from.connectionId);
						if (member) { break; }
					}
					if (member) {
						res.from = member;
					}
					this.commonService.videoStreams.push(res);

					// to limit screen sharing to one
					if (res.kind === StreamType.SCREEN && this.commonService.screenSharingEnabled) {
						this.commonService.stopScreenSharing();
					}
				}

				// process pending events
				if (this.pendingEvents.length && res?.from?.connectionId) {
					const index = this.pendingEvents.findIndex(d => d?.from?.connectionId === res?.from?.connectionId);
					if (index !== -1) {
						// delibrate double equals, do not change
						res.muted = this.pendingEvents[index].msg == 'true';
						this.pendingEvents.splice(index, 1);
					}
				}
				// handle termination of streams
				this.handleStreamTermination(res);
			} else if (res?.type === StreamEventType.STREAM_REMOVED && res?.streamId) {
				if (res.kind === StreamType.PLACEHOLDER) {
					this.commonService.placeholderStreams = this.commonService.placeholderStreams.filter(l => l?.streamId !== res?.streamId);
					this.commonService.placeholderStreams = [...this.commonService.placeholderStreams];
				} else if (res.kind === StreamType.SELF) {
					this.selfCamStream = null;
				} else {
					this.commonService.videoStreams = this.commonService.videoStreams.filter(l => l?.streamId !== res?.streamId);
					this.commonService.videoStreams = [...this.commonService.videoStreams];

					this.commonService.mobileParticipantStreams = this.commonService.mobileParticipantStreams.filter(l => l?.streamId !== res?.streamId);
					this.commonService.mobileParticipantStreams = [...this.commonService.mobileParticipantStreams];
				}

				if (this.pendingEvents.length && res?.from?.connectionId) {
					const index = this.pendingEvents.findIndex(d => d?.from?.connectionId === res?.from?.connectionId);
					if (index !== -1) {
						this.pendingEvents.splice(index, 1);
					}
				}
			}

			// choose mobile streams of participant
			if (!isMobile) {
				const mobileParticipantStreams = this.commonService.videoStreams.filter(d => d?.from?.isMobile && d?.from?.userType === USER_TYPE.TESTER && (d?.kind == StreamType.CAMERA || d?.kind == StreamType.SCREEN));
				// push in stream
				this.commonService.mobileParticipantStreams.push(...mobileParticipantStreams);
				// keep only two
				if (this.commonService.mobileParticipantStreams.length > 2) {
					this.commonService.mobileParticipantStreams = this.commonService.mobileParticipantStreams.slice(0, 2);
				}

				if (this.commonService.mobileParticipantStreams.length > 1 && this.commonService.mobileParticipantStreams[0]?.kind !== StreamType.SCREEN) {
					const temp = this.commonService.mobileParticipantStreams[1];
					this.commonService.mobileParticipantStreams[1] = this.commonService.mobileParticipantStreams[0];
					this.commonService.mobileParticipantStreams[0] = temp;
				}

				// remove from original
				this.commonService.videoStreams = this.commonService.videoStreams.filter(d => d?.src?.id && !this.commonService.mobileParticipantStreams.some(k => k?.src?.id == d?.src?.id));
				this.commonService.videoStreams = [...this.commonService.videoStreams];

				// make one separate for easy reference in html
				if (this.commonService.mobileParticipantStreams.length) {
					this.mobileParticipantStreamDetail = this.commonService.mobileParticipantStreams[0];
					[...this.commonService.videoStreams, ...this.commonService.placeholderStreams].forEach(d => d.active = false);
				} else {
					this.mobileParticipantStreamDetail = null;
				}
			}

			this.commonService.placeholderStreams = [...this.commonService.placeholderStreams];
			this.commonService.videoStreams = [...this.commonService.videoStreams];
			this.commonService.mobileParticipantStreams = [...this.commonService.mobileParticipantStreams];
			this.setVideo();
			this.checkFocus();
			this.togglePlaceholder();
			this.checkIfCanExpand();
			this.fixMobileStreams();
			this.changeDetectorRef.detectChanges();
		}
	}

	public setFocus(item: StreamEventMessage) {
		this.toShowNoVideo = false;
		if (item.active || this.commonService.mobileParticipantStreams.length) {
			return;
		}
		this.showPlaceholder = false;
		let shouldFullScreenMobileModeAvailable: boolean = false;
		this.commonService.videoStreams.forEach(stream => {
			if (stream.streamId === item.streamId) {
				stream.active = true;
				shouldFullScreenMobileModeAvailable = true;
			} else {
				stream.active = false;
			}
		});
		if (!shouldFullScreenMobileModeAvailable) {
			this.commonService.fullScreenMobile = false;
			this.isMobileVideoLandscape = false;
		}
		this.commonService.placeholderStreams.forEach(stream => {
			if (stream.streamId === item.streamId) {
				stream.active = true;
			} else {
				stream.active = false;
			}
		});
		this.changeDetectorRef.detectChanges();
		this.setVideo();
	}

	private setVideo() {
		// clear any previous timeouts
		if (this.setVideoTimeout) {
			clearTimeout(this.setVideoTimeout);
			this.setVideoTimeout = null;
		}
		// collect all streams
		const videoStreams = this.commonService.videoStreams.filter(d => d.kind !== StreamType.PLACEHOLDER);
		// const videoStreams = isMobile ? this.commonService.videoStreams.filter(d => !d.active && d.kind !== StreamType.PLACEHOLDER) : this.commonService.videoStreams.filter(d => true);
		this.selfCamStream && videoStreams.push(this.selfCamStream);
		this.commonService.mobileParticipantStreams && this.commonService.mobileParticipantStreams.length && videoStreams.push(...this.commonService.mobileParticipantStreams);

		// press play on elements
		let playerElements = this.playerElements;

		if (playerElements.length === videoStreams.length) {
			for (const element of playerElements) {
				if (element && element.nativeElement) {
					const streamId = element.nativeElement.getAttribute('id');
					const stream = videoStreams.find(d => d.src.id === streamId);
					if (stream && stream.src) {
						const track = stream.src.getVideoTracks();
						if (track.length && track[0].readyState == 'ended') {
							stream.type = StreamEventType.STREAM_REMOVED;
							this.handleStreamEvents(stream);
							break;
						} else {
							if (element.nativeElement.paused || element.nativeElement.ended) {
								element.nativeElement.srcObject = stream.src;
								element.nativeElement.setAttribute('playsinline', 'true');
								element.nativeElement.addEventListener('canplaythrough', () => this.playVideo(element.nativeElement));
							}
						}
					} else {
						logger.log('Stream not found', stream, streamId);
						element.nativeElement.srcObject = null;
					}
				} else {
					logger.log('Element not loaded yet, trying after ', resetVideoTimeout, 'ms');
					this.setVideoTimeout = setTimeout(() => this.setVideo(), resetVideoTimeout);
				}
			}
		} else {
			logger.log('Video Elements do not match number of streams, trying after ', resetVideoTimeout, 'ms', videoStreams);
			this.setVideoTimeout = setTimeout(() => this.setVideo(), resetVideoTimeout);
		}
	}

	private replayVideo() {
		// press play on elements
		let playerElements = this.playerElements;

		logger.log('replayVideo playerElements.length', playerElements.length);
		if (playerElements.length) {
			for (const element of playerElements) {
				if (element && element.nativeElement && (element.nativeElement.paused || element.nativeElement.ended)) {
					logger.log('replayVideo element paused or ended');
					this.replayVideoElement(element.nativeElement).catch(() => {
						element.nativeElement.addEventListener('canplaythrough', () => this.replayVideoElement(element.nativeElement));
					});
				}
			}
		}
	}

	private checkFocus() {
		if (this.commonService.videoStreams.length) {
			if (this.commonService.mobileParticipantStreams.length) {
				const screenStreamItem = this.commonService.videoStreams.find(i => i.kind === StreamType.SCREEN && i.src !== null);
				if (screenStreamItem) {
					this.commonService.mobileParticipantStreams.forEach(d => this.commonService.videoStreams.push(d));
					this.commonService.mobileParticipantStreams = [];
					this.setFocus(screenStreamItem);
					return;
				}
			} else
				// if no current active or screen as active
				if (this.commonService.videoStreams.find(i => !i.active || (i.active && i.kind !== StreamType.SCREEN && i.src !== null))) {

					// for multiple streams, set screen as active if available, then cam
					const screenStreamItem = this.commonService.videoStreams.find(i => i.kind === StreamType.SCREEN && i.src !== null);
					if (screenStreamItem) {
						this.setFocus(screenStreamItem);
					} else {
						// find all cam streams
						const camStreamItems = this.commonService.videoStreams.filter(i => i.kind === StreamType.CAMERA && i.src != null);
						if(camStreamItems.length) {
							// find participant's cam
							let camStreamItem = camStreamItems.find(d => d?.from?.userType === USER_TYPE.TESTER);
							if (camStreamItem) {
								this.setFocus(camStreamItem);
							}
							else if(this.sessionService.userType !== USER_TYPE.TESTER) {
								logger.log("======> User is not participant");
								if(this.sessionService.sessionMode == SessionMode.IN_SESSION) {
									logger.log("======> User is not participant and call is in session mode");
									const placeholder = this.commonService.placeholderStreams.find(i => i.from.userType === USER_TYPE.TESTER);
									if(placeholder) {
										logger.log("======> User is not participant and call is in session mode, showing participant placeholder");
										this.setFocus(placeholder);
									}
									else {
										logger.log("======> User is not participant and call is in session mode, waiting for participant, showing waiting loader");
										this.showPlaceholder = true;
									}
								} else {
									logger.log("======> User is not participant and call is in briefing/debriefing mode");
									// In briefing/debriefing mode, show moderator cam / moderator placeholder
									const modPlace = this.commonService.placeholderStreams.find(i => i.from.userType === USER_TYPE.MODERATOR);
									const modCam = camStreamItems.find(x => x?.from?.userType === USER_TYPE.MODERATOR);
									if(modCam) {
										logger.log("======> User is not participant and call is in briefing/debriefing mode, showing moderator cam");
										this.setFocus(modCam);
									} else if(modPlace) {
										logger.log("======> User is not participant and call is in briefing/debriefing mode, showing moderator placeholder");
										this.setFocus(modPlace);
									} else {
										logger.log("======> User is not participant and call is in briefing/debriefing mode, moderator is not available in this call so showing first found camera stream of any user");
										this.setFocus(camStreamItems[0]);
									}
								}
							}
						    else {
								if (this.sessionService.userType == USER_TYPE.TESTER) {
									logger.log("======> User is participant");
									const modPlace = this.commonService.placeholderStreams.find(i => i.from.userType === USER_TYPE.MODERATOR);
									const modCam = camStreamItems.find(x => x?.from?.userType === USER_TYPE.MODERATOR);
									if(modCam) {
										logger.log("======> User is participant, showing moderator cam ", modCam);
										this.setFocus(modCam);
									} else if(modPlace) {
										logger.log("======> User is participant, showing moderator placeholder");
										this.setFocus(modPlace);
									}
								} else {
									logger.log("======> User is not participant, so setting first available camStreamItem as active");
									this.setFocus(camStreamItems[0]);
								}
							}
						}
					}

				}
		}
		else if (this.commonService.mobileParticipantStreams.length) {
			this.showPlaceholder = false;
		}
		// if no video stream available make placeholder as active
		else if (this.commonService.placeholderStreams.length && !this.commonService.placeholderStreams.find(i => i.active && (i.from.userType == USER_TYPE.TESTER || i.from.userType == USER_TYPE.MODERATOR))) {
			const activePlaceholder = this.commonService.placeholderStreams.find(d => d.from && d.from.userType == USER_TYPE.TESTER) || this.commonService.placeholderStreams.find(d => d.from && d.from.userType == USER_TYPE.MODERATOR) || this.commonService.placeholderStreams[0];
			this.setFocus(activePlaceholder);
		}
	}

	private togglePlaceholder() {
		this.commonService.placeholderStreams.forEach(placeholder => {
			const connectionId = placeholder.from.connectionId;
			const videoStreams = [...this.commonService.videoStreams, ...this.commonService.mobileParticipantStreams].filter(l => l && l.from && l.from.connectionId && l.from.connectionId === connectionId);
			if (videoStreams && videoStreams.length) {
				// fill empty info, streams can come before connection event has been fired
				videoStreams.forEach(stream => {
					if (stream.from.name === '' || !stream.from.color) {
						stream.from = placeholder.from;
					}
					stream.muted = placeholder.muted;
				});
			}
		});
		this.changeDetectorRef.detectChanges();
	}

	trackById(index: number, item: StreamEventMessage) {
		return item.streamId;
	}

	private checkIfCanExpand() {
		// changelogic based on hide/show of camera streams same as in web
		if (this.commonService.videoStreams.length === 1 && this.commonService.placeholderStreams.length === 1) {
			this.fullScreen = true;
		}
		else {
			this.fullScreen = false;
		}
	}

	public toggleTooltip($event: MouseEvent, item) {
		item.displayTooltip = !item.displayTooltip;

		let x = $event.pageX - 20;
		if (!isIOS) {
			const parent = $event.path.shift();
			if (parent) {
				const parentX = parent.getBoundingClientRect().x;
				x = parentX;
			}
		}
		const wW = screen.availWidth;
		if (x > wW / 2) {
			x = wW / 2;
		}

		this.tooltipX = x + 'px';
	}


	private handleStreamTermination(res: StreamEventMessage) {
		if (res.kind === StreamType.PLACEHOLDER || !res.src || !res.src.getTracks || !res.src.getTracks().length) {
			return;
		}
		const tracks = res.src.getTracks();
		tracks.forEach(track => {
			track.onended = () => {
				logger.info('Track Ended');
				res.type = StreamEventType.STREAM_REMOVED;
				this.handleStreamEvents(res);
				if (res.kind == StreamType.SELF) {
					this.commonService.stopCam();
				}
			};
		});
	}

	ngOnDestroy() {
		if (this.subs) {
			this.subs.map(sub => sub && sub.unsubscribe());
			this.subs = [];
		}
		if (this.setVideoTimeout) {
			clearTimeout(this.setVideoTimeout);
		}
	}

	public fixMobileStreams() {
		if (isMobile) {
			// placeholder stream to show mic and name in mobile, when no video
			if (this.commonService.placeholderStreams.length && !this.commonService.videoStreams.length) {
				// first find CM
				this.mobilePlaceholder = this.commonService.placeholderStreams.find(d => d?.from?.isChiefModerator);
				// if none choose moderator
				this.mobilePlaceholder = this.commonService.placeholderStreams.find(d => d?.from?.userType == USER_TYPE.MODERATOR);
				// if none choose first
				if (!this.mobilePlaceholder) {
					this.mobilePlaceholder = this.commonService.placeholderStreams[0];
				}
			} else {
				this.mobilePlaceholder = null;
			}
			this.participantCamera = this.commonService.videoStreams.find(d => d?.from?.userType == USER_TYPE.TESTER && d?.kind == StreamType.CAMERA);
			this.participantScreen = this.commonService.videoStreams.find(d => d?.from?.userType == USER_TYPE.TESTER && d?.kind == StreamType.SCREEN);
			this.moderatorScreen = this.commonService.videoStreams.find(d => d?.from?.userType == USER_TYPE.MODERATOR && d?.kind == StreamType.SCREEN);
		} else {
			this.mobilePlaceholder = null;
		}
		this.commonService.mobilePlaceholder = this.mobilePlaceholder;
	}

	private playVideo(player) {
		logger.log('----------Playing video------');
		const promise = player.play();
		if (promise && promise.then && promise.catch) {
			promise.then(() => {
				logger.log('----------Video playing------');
				this.changeDetectorRef.detectChanges();
			}).catch((err) => {
				logger.error('Error playing video: ', err);
				this.setVideoTimeout = setTimeout(() => this.setVideo(), resetVideoTimeout);
			});
		}
		player.removeEventListener('canplaythrough', () => this.playVideo(player));
	}

	private replayVideoElement(player) {
		return new Promise<void>((resolve, reject) => {
			logger.log('----------Playing video------');
			const promise = player.play();
			if (promise && promise.then && promise.catch) {
				promise.then(() => {
					logger.log('----------Video playing------');
					this.changeDetectorRef.detectChanges();
					resolve();
				}).catch((err) => {
					logger.error('Error playing video: ', err);
					reject();
				});
			}
			player.removeEventListener('canplaythrough', () => this.replayVideoElement(player));
		});
	}

	public getInactiveVideoCount() {
		return this.commonService.videoStreams.filter(item => !item.active).length;
	}
}
