<div class="check-mic" *ngIf="checkItem">
    <div class="check-mic-image">
        <app-svg name="checkMic" *ngIf="checkItem.status == TECH_CHECK_STATUS.PROGRESS"></app-svg>
        <div class="check-mic-image-volume" *ngIf="checkItem.status == TECH_CHECK_STATUS.PROGRESS">
            <svg>
                <circle opacity="0.8" cx="155.499" cy="78.4985" [attr.r]="volume*0.2" fill="#79E1D0"/>
                <circle opacity="0.6" cx="155.95" cy="78.9495" [attr.r]="volume*0.4" fill="#79E1D0"/>
                <ellipse opacity="0.4" cx="155.498" cy="78.4982" [attr.rx]="volume*0.6" [attr.ry]="volume*0.6" fill="#79E1D0"/>
            </svg>
        </div>
        <app-svg name="checkMicPass" *ngIf="checkItem.status == TECH_CHECK_STATUS.SUCCESS"></app-svg>
        <app-svg name="checkMicFail" *ngIf="checkItem.status == TECH_CHECK_STATUS.FAILED"></app-svg>
    </div>
    <div class="check-mic-message">
        <span *ngIf="checkItem.status == TECH_CHECK_STATUS.PROGRESS">Speak something into your Microphone</span>
        <span *ngIf="checkItem.status == TECH_CHECK_STATUS.SUCCESS">All is good with Microphone!</span>
        <span *ngIf="checkItem.status == TECH_CHECK_STATUS.FAILED">Microphone</span>
    </div>
    <div class="check-mic-instruction">
        <span *ngIf="checkItem.status == TECH_CHECK_STATUS.PROGRESS">Checking for Microphone and other System configuration</span>
        <span *ngIf="checkItem.status == TECH_CHECK_STATUS.SUCCESS"></span>
        <span class="check-mic-instruction-fail" *ngIf="checkItem.status == TECH_CHECK_STATUS.FAILED">{{checkItem.failReason}}</span>
        <span *ngIf="checkItem.status == TECH_CHECK_STATUS.PROGRESS">
            <div class="check-mic-instruction-progress">
                <div class="check-mic-instruction-progress-container" [ngStyle]="{'width': progressTimer + '%'}"></div>
            </div>
        </span>
    </div>
    <div class="check-mic-button">
        <button
            class="check-mic-button-btn"
            (click)="goToSettings()"
            *ngIf="checkItem.status == TECH_CHECK_STATUS.FAILED">
                {{ permissionDenied && !window.isAndroid ? 'Go to settings' : 'Retry' }}
        </button>
        <button class="check-mic-button-btn" (click)="proceed()" *ngIf="checkItem.status == TECH_CHECK_STATUS.SUCCESS">Proceed to check Camera</button>
    </div>
</div>