import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CUSTOM_LAYOUT_PATH, DATA_ENCRYPTION_ALGORITHM, EncryptedDataPacket, EncryptedQueryParams, ENCRYPTION_KEY_FORMAT, JoinRequestStatus, KEY_ENCRYPTION_ALGORITHM, Path, PeerData, RecordingEventType, ServerData, SessionInfo, SessionMode } from 'src/constants';
import { environment } from 'src/environments/environment';
import { AuthHeader, EncryptedAuthHeader, KICKED_OUT_FALSE, SavedChat, USER_TYPE } from '../../../../commonConstants';

// subtle crypto object
const SubtleCrypto = window?.crypto?.subtle;
const maxRetry = 2;

@Injectable({
	providedIn: 'root'
})

export class RequestService {

	private publickey: string = null;
	private privateKey: CryptoKey = null;
	private serverSecretKey: CryptoKey = null;
	private serverPublicKey: CryptoKey = null;

	constructor(private http: HttpClient) {

		/**CHECKING WHETER BROWSER SUPPORTS ENCRYPTION */
		window.isEncryptionSupported = true;
		if (typeof SubtleCrypto == 'undefined' ||
			typeof SubtleCrypto.generateKey != 'function' ||
			typeof SubtleCrypto.encrypt != 'function' ||
			typeof SubtleCrypto.decrypt != 'function' ||
			typeof SubtleCrypto.exportKey != 'function' ||
			typeof SubtleCrypto.importKey != 'function' ||
			typeof TextDecoder == 'undefined' ||
			typeof TextDecoder?.prototype?.decode != 'function' ||
			typeof TextEncoder == 'undefined' ||
			typeof TextEncoder?.prototype?.encode != 'function' ||
			typeof window?.atob != 'function' ||
			typeof ArrayBuffer == 'undefined' ||
			typeof Uint8Array == 'undefined' ||
			typeof String?.prototype?.charCodeAt != 'function' ||
			typeof window?.btoa != 'function'
		) {
			window.isEncryptionSupported = false;
		}
		logger.info('isEncryptionSupported', isEncryptionSupported);
	}

	private generateKeys(): Promise<void> {
		return new Promise((resolve, reject) => {
			if (this.privateKey && this.publickey) {
				resolve();
				return;
			}
			/**
			 * generating RSA(public, private key pair)
			 * Random key pair is generated each time the app is loaded
			 * In each request
			 * Data in request and response is encrypted by AES
			 * Each encrypted data uses new IV(initialization vector) and the same iv is to be used for decryption
			 * Request Auth header contains Our [Public Key, IV(initialization vector)]
			 * Response Auth header contains [AES Secret, IV(initialization vector)] encrypted with client's public rsa key
			 * Public Key is exported in spki format using this code - https://github.com/mdn/dom-examples/blob/master/web-crypto/export-key/spki.js
			*/
			try {
				SubtleCrypto.generateKey(KEY_ENCRYPTION_ALGORITHM, true, ['encrypt', 'decrypt'])
					.then((keyPair: CryptoKeyPair) => {
						if (keyPair && keyPair.privateKey && keyPair.publicKey) {
							this.privateKey = keyPair.privateKey;
							// set self key as server key as default
							this.serverPublicKey = keyPair.publicKey;
							// export our public key
							SubtleCrypto.exportKey(ENCRYPTION_KEY_FORMAT, keyPair.publicKey)
								.then(publicKey => {
									if (typeof publicKey == 'object') {
										this.publickey = JSON.stringify(publicKey);
									}
									if (publicKey instanceof ArrayBuffer) {
										this.publickey = publicKey.getString();
									}

									// for 1st time encryption because we don't have server keys
									SubtleCrypto.generateKey(DATA_ENCRYPTION_ALGORITHM, false, ['encrypt', 'decrypt']).then((key: CryptoKey) => {
										this.serverSecretKey = key;
										resolve();
									});
								});
						} else {
							reject();
						}
					});
			} catch (e) {
				logger.error('Error in generateKey', e, e.name);
				isEncryptionSupported = false;
				reject();
			}
		});
	}

	// encrypt data to a base64 string using client public key via asymmetric algo
	private async encryptKey(plainText: any): Promise<string> {
		if (plainText && isEncryptionSupported && this.serverPublicKey) {
			try {
				let data: any = plainText;

				// convert data to string, if not binary data
				if (typeof data != 'string' && typeof data?.prototype?.buffer == 'undefined') {
					data = JSON.stringify(data);
				}
				// convert data to binary, if not binary data
				if (typeof data == 'string') {
					data = new TextEncoder().encode(data);
				}
				// encrypt
				const encrypted = await SubtleCrypto.encrypt(KEY_ENCRYPTION_ALGORITHM, this.serverPublicKey, data);
				if (encrypted && encrypted.byteLength > 0) {
					// convert buffer to string
					const str = encrypted.getString();
					if (str && str.length > 0) {
						return str;
					}
				}
			} catch (e) {
				logger.error('Error in encryptKey', e, e.name);
			}
		}
		return plainText;
	}

	// encrypt data to a base64 string using symmetric algo
	private async encryptData(plainText: any, iv: Uint8Array, additionalAuthData: Uint8Array): Promise<string> {
		if (plainText && isEncryptionSupported && this.serverSecretKey) {
			let data: any = plainText;
			// convert data to string, if not binary data
			if (typeof data != 'string' && typeof data?.prototype?.buffer == 'undefined') {
				data = JSON.stringify(data);
			}
			// convert data to binary, if not binary data
			if (typeof data == 'string') {
				data = new TextEncoder().encode(data);
			}
			try {
				const encryptedData = await SubtleCrypto.encrypt({
					...DATA_ENCRYPTION_ALGORITHM,
					iv,
					tagLength: DATA_ENCRYPTION_ALGORITHM.tagLength,
					additionalData: additionalAuthData
				}, this.serverSecretKey, data);
				if (encryptedData && encryptedData.byteLength > 0) {
					// convert buffer to base64 endoded string
					const str = encryptedData.getString();
					if (str && str.length > 0) {
						return str;
					}
				}
			} catch (e) {
				logger.error('Error in encrypting data', e, e.name);
			}
		}
		return plainText;
	}

	// decrypt from base64 encoded string using asymmetric algo
	private async decryptKey(data: string): Promise<string | object> {
		if (data && isEncryptionSupported && this.privateKey) {
			try {
				// string to buffer
				const buf = data.getBuffer();
				// decrypt
				const decrypted = await SubtleCrypto.decrypt(KEY_ENCRYPTION_ALGORITHM, this.privateKey, buf);
				if (decrypted && decrypted.byteLength > 0) {
					// convert buffer to string
					const str = new TextDecoder('utf8').decode(decrypted);
					if (str && str.length > 0) {
						let parsedData = str;
						// try parsing
						try {
							parsedData = JSON.parse(str);
						} catch (e) { }
						return parsedData;
					}
				}
			} catch (e) {
				logger.error('Error in decrypting key', e, e.name);
			}
		}
		return data;
	}

	// decrypt from base64 encoded string using symmetric algo
	private async decryptData(data: string, iv: Uint8Array | string, additionalAuthData: Uint8Array | string): Promise<string | object> {
		if (data && isEncryptionSupported && this.serverSecretKey) {
			try {
				// string to buffer
				const buf = data.getBuffer();
				// string to buffer
				if (typeof iv == 'string') {
					iv = iv.getBuffer();
				}
				// string to buffer
				if (typeof additionalAuthData == 'string') {
					additionalAuthData = additionalAuthData.getBuffer();
				}

				const decrypted = await SubtleCrypto.decrypt({
					name: DATA_ENCRYPTION_ALGORITHM.name,
					iv,
					tagLength: DATA_ENCRYPTION_ALGORITHM.tagLength,
					additionalData: additionalAuthData
				}, this.serverSecretKey, buf);
				if (decrypted && decrypted.byteLength > 0) {
					// convert buffer to string
					const str = new TextDecoder('utf8').decode(decrypted);
					if (str && str.length > 0) {
						// try parsing
						let parsedData;
						try {
							parsedData = JSON.parse(str);
						} catch (e) {
							parsedData = str;
						}
						return parsedData;
					}
				}
			} catch (e) {
				logger.error('Error in decrypting data', e.name);
			}
		}
		return data;
	}

	// import crypto keys
	private importKey(key: any, algo: any, format: 'raw' | 'pkcs8' | 'spki' | 'jwk', uses: KeyUsage[]): Promise<CryptoKey> {
		return new Promise((resolve, reject) => {
			if (isEncryptionSupported) {
				try {
					if (typeof key == 'string') {
						key = key.getBuffer();
					}

					SubtleCrypto.importKey(
						format,
						key,
						algo,
						false,
						uses
					).then(resolve);
				} catch (e) {
					logger.error('Error in importing server public key', key, e, e.name);
					reject();
				}
			} else {
				reject();
			}
		});
	}

	private setServerSecretKey(key: any) {
		return this.importKey(key, DATA_ENCRYPTION_ALGORITHM, 'raw', ['encrypt', 'decrypt'])
			.then(importedKey => {
				this.serverSecretKey = importedKey;
			}).catch(() => {
				// if no key available already then disable encryption
				if (!this.serverSecretKey) {
					isEncryptionSupported = false;
				}
			});
	}

	private setServerPublicKey(key: any) {
		return this.importKey(key, KEY_ENCRYPTION_ALGORITHM, ENCRYPTION_KEY_FORMAT, ['encrypt'])
			.then(importedKey => {
				this.serverPublicKey = importedKey;
			}).catch(() => {
				// if no key available already then disable encryption
				if (!this.serverPublicKey) {
					isEncryptionSupported = false;
				}
			});
	}

	private sendRequest(path: Path,
		                   params: HttpParams | { [param: string]: string | string[]},
		                   method = 'GET',
		                   body: any = null,
		                   headers: HttpHeaders | { [header: string]: string | string[] } = null,
		                   beacon = false,
		                   ignoreError = false,
		                   encrypt = true, retry = 0): Promise<any> {
		return new Promise(async (resolve, reject) => {
			let request, original, responseType: any = 'json';
			retry++;

			// encrypt if possible
			if (encrypt && isEncryptionSupported) {
				// before encryption, deep cloning
				original = JSON.parse(JSON.stringify({ path, params, body, headers }));
				try {
					await this.generateKeys();

					const queryParams: EncryptedQueryParams = { params, path };

					// for symmetric encryptions
					const iv = window.crypto.getRandomValues(new Uint8Array(DATA_ENCRYPTION_ALGORITHM.ivLength));
					const additionalAuthData = window.crypto.getRandomValues(new Uint8Array(DATA_ENCRYPTION_ALGORITHM.ivLength));

					// encrypt query params
					const data = await this.encryptData(queryParams, iv, additionalAuthData);
					params = { data };

					// encrypt body
					body = await this.encryptData(body, iv, additionalAuthData);

					// encrypt header
					const encryptedAuthHeader: EncryptedAuthHeader = { iv: iv.getString(), additionalAuthData: additionalAuthData.getString() };

					// set auth header
					const authHeader: AuthHeader = {
						publicKey: this.publickey,
						encryptedAuthHeader: await this.encryptKey(encryptedAuthHeader)
					};
					// set headers
					headers = {
						...headers,
						// auth header is base64 encoded info of key and EncryptedAuthHeader
						Authorization: window.btoa(JSON.stringify(authHeader)),
					};

					responseType = 'text';

					// change path to let server know that the request is encrypted
					path = Path.ENCRYPTED;
				} catch (e) {
					logger.error('Error in encryption', e, e.name);
				}
			}

			let url = environment.url + path;

			// url encode all query params
			if (typeof window?.encodeURIComponent == 'function') {
				Object.keys(params).forEach(key => {
					params[key] = encodeURIComponent(params[key]);
				});
			}

			switch (method) {
				case 'GET':
					request = this.http.get(url, { params, headers, responseType, observe: 'response' });
					break;
				case 'POST':
					request = this.http.post(url, body, { params, headers, responseType, observe: 'response' });
					break;
				default:
					request = null;
					break;
			}
			if (request) {
				// resend request in case of failures
				const resend = () => {
					// if encrypted requests fails more than 2 times then fallback to normal
					if (retry >= maxRetry) {
						logger.error('Max resend hit, sending normal request');
						encrypt = false;
					}
					// send new request encrypted via new server public key if enabled
					const promise = this.sendRequest(original?.path, original?.params, method, original?.body, original?.headers, beacon, ignoreError, encrypt, retry);
					resolve(promise);
				};

				const send = () => {
					request.subscribe(
						(async (response: HttpResponse<string | object>) => {
							// get body
							let res = response?.body;
							// auth header is base64 encoded info of key and EncryptedAuthHeader
							const header = response?.headers?.get('authorization');
							// try decryption if encryption supported
							if (isEncryptionSupported &&
								typeof res == 'string' &&
								res?.trim()?.length &&
								header?.trim()?.length) {
								try {
									// decode base64 string
									const authHeader: AuthHeader = JSON.parse(window.atob(header));
									if (authHeader &&
										typeof authHeader.encryptedAuthHeader != 'undefined' &&
										typeof authHeader.publicKey != 'undefined') {

										// set server pub key
										await this.setServerPublicKey(authHeader.publicKey);

										// decrypt header
										const decryptedAuthHeader = await this.decryptKey(authHeader.encryptedAuthHeader) as EncryptedAuthHeader;

										if (decryptedAuthHeader &&
											typeof decryptedAuthHeader.iv != 'undefined' &&
											typeof decryptedAuthHeader.secretKey != 'undefined' &&
											typeof decryptedAuthHeader.additionalAuthData != 'undefined') {

											// set server secret key
											await this.setServerSecretKey(decryptedAuthHeader.secretKey);

											// decrypt data
											const decryptedData = await this.decryptData(res, decryptedAuthHeader.iv, decryptedAuthHeader.additionalAuthData) as EncryptedDataPacket;

											if (decryptedData &&
												typeof decryptedData.data != 'undefined' &&
												typeof decryptedData.resend != 'undefined') {
												// if previous server key was invalid then resend request using new key
												if (decryptedData?.resend) {
													resend();
												}
												// valid data
												else {
													resolve(decryptedData.data);
												}
											} else {
												logger.info('Empty buffer received aftr decryption, returning original data');
												resolve(res);
											}
										} else {
											logger.log('Unable to decrypt header, retrying');
											resend();
										}
									} else {
										logger.log('Unable to decrypt header, retrying');
										resend();
									}
								} catch (e) {
									// exception occured, use original
									resolve(res);
								}
							}
							// encryption not available or data packet different, use original
							else {
								if (typeof res == 'string') {
									try {
										res = JSON.parse(res);
									} catch (e) { }
								}
								resolve(res);
							}
						}), (err => {
							if (!ignoreError) {
								logger.error('HTTP Error in sending request: ', path, ' Error: ', err);
							}
							reject(err);
						})
					);
				};

				if (beacon && typeof navigator?.sendBeacon == 'function') {
					if (params) {
						const searchParams = new URLSearchParams(params as any).toString();
						if (searchParams?.trim()?.length) {
							url = url + '?' + searchParams;
						}
					}
					try {
						const payload = JSON.stringify(body, null, 2);
						const sent = navigator.sendBeacon(url, payload);
						if (!sent) {
							send();
						}
					} catch (e) {
						send();
					}
				} else {
					send();
				}
			} else {
				reject(`${method} method not supported`);
			}
		});
	}

	public fetchToken(userType, sessionId, extraData: PeerData = null): Promise<{ token: string, mode: SessionMode, chats: { [dynamic: string]: SavedChat[] }, testerName: string, iceServers: any, connectionId: string }> {
		return new Promise((resolve, reject) => {
			const reconnectTime = 1000; // 1second
			// data sent to server and joined peers
			const userOptions: ServerData = {
				data: {
					userType,
					...extraData
				},
				kurentoOptions: null
			};

			this.sendRequest(Path.TOKEN, { id: sessionId, userOptions: JSON.stringify(userOptions) }).then(res => {
				if (!res.error) {
					resolve(res);
				}
				else {
					reject(res.msg);
				}
			}).catch(err => {
				reject(err);
			});
		});
	}

	public startRecording(sessionId) {
		return this.sendRequest(Path.START_RECORDING, { id: sessionId, customLayoutPath: CUSTOM_LAYOUT_PATH });
	}

	public stopRecording(sessionId) {
		return this.sendRequest(Path.STOP_RECORDING, { id: sessionId });
	}

	public sendJoinSessionRequest(sessionId, name): Promise<{ status: JoinRequestStatus, msg?: string }> {
		return new Promise((resolve, reject) => {
			this.sendRequest(Path.JOIN_SESSION, { id: sessionId, name }).then(res => {
				if (res && res.status) {
					resolve(res);
				} else {
					reject();
				}
			}).catch(reject);
		});
	}

	public fetchPendingRequest(sessionId): Promise<{ status: JoinRequestStatus, name: string }> {
		return new Promise((resolve, reject) => {
			this.sendRequest(Path.FETCH_PENDING_REQUEST, { id: sessionId }).then(res => {
				resolve(res);
			}).catch(err => {
				reject();
			});
		});
	}

	public forceDisconnection(sessionId, connectionId): Promise<void> {
		return new Promise((resolve, reject) => {
			this.sendRequest(Path.FORCE_DISCONNECTION, { id: sessionId, connectionId }).then(res => {
				if (res.success) {
					resolve();
				}
				else {
					reject(res.msg);
				}
			}).catch(err => {
				reject(err);
			});
		});
	}

	public getJoinStatus(sessionId): Promise<{joinRequest: {status: JoinRequestStatus, requesteeName: string, sessionId: string }, terminated: boolean}> {
		return new Promise((resolve, reject) => {
			this.sendRequest(Path.GET_JOIN_STATUS, { id: sessionId }).then(res => {
				if (res.success) {
					resolve({joinRequest: res.joinRequest, terminated: res.terminated});
				}
				else {
					reject(res.msg);
				}
			}).catch(err => {
				reject(err);
			});
		});
	}

	public allowJoinRequest(sessionId, name): Promise<void> {
		return new Promise((resolve, reject) => {
			this.sendRequest(Path.ALLOW_JOIN, { id: sessionId, name }).then(res => {
				if (res.success) {
					resolve();
				}
				else {
					reject(res.msg);
				}
			}).catch(err => {
				reject(err);
			});
		});
	}

	private sessionModeTimeout = null;
	public setSessionMode(sessionName: string, sessionId: string, mode: SessionMode): Promise<void> {
		const time = 1000 * 5;
		if(this.sessionModeTimeout) {
			clearTimeout(this.sessionModeTimeout);
			this.sessionModeTimeout = null;
		}
		return new Promise((resolve, reject) => {
			this.sendRequest(Path.SET_SESSION_MODE, { id: sessionName, realSessionId: sessionId, mode }).then(res => {
				if (res.success) {
					resolve();
				}
				else {
					reject(res.msg);
				}
			}).catch(err => {
				this.sessionModeTimeout = setTimeout(() => this.setSessionMode(sessionName, sessionId, mode), time);
			});
		});
	}

	private sessionModeGetTimeout = null;
	public getSessionMode(sessionName: string, sessionId: string): Promise<any> {
		const time = 1000 * 5;
		if(this.sessionModeGetTimeout) {
			clearTimeout(this.sessionModeGetTimeout);
			this.sessionModeGetTimeout = null;
		}
		return new Promise((resolve, reject) => {
			this.sendRequest(Path.GET_SESSION_MODE, { id: sessionName, realSessionId: sessionId }).then(res => {
				if (res.success) {
					resolve(res);
				}
				else {
					reject(res.msg);
				}
			}).catch(err => {
				this.sessionModeGetTimeout = setTimeout(() => this.getSessionMode(sessionName, sessionId), time);
			});
		});
	}

	public saveChat(sessionName: string, data: object): Promise<void> {
		const time = 1000 * 5;
		return new Promise((resolve, reject) => {
			this.sendRequest(Path.SAVE_CHATS, { id: sessionName }, 'POST', data).then(res => {
				if (res.success) {
					resolve();
				}
				else {
					reject(res.msg);
				}
			}).catch(err => {
				setTimeout(() => this.saveChat(sessionName, data), time);
			});
		});
	}

	public saveNotes(sessionName: string, data: object): Promise<number> {
		const time = 1000 * 5;
		return new Promise((resolve, reject) => {
			this.sendRequest(Path.SAVE_NOTES, { id: sessionName }, 'POST', data).then(res => {
				if (res.success) {
					resolve(res.id);
				}
				else {
					reject(res.msg);
				}
			}).catch(err => {
				setTimeout(() => this.saveNotes(sessionName, data), time);
			});
		});
	}

	public verifySession(id: string): Promise<SessionInfo> {
		return new Promise((resolve, reject) => {
			this.sendRequest(Path.VERIFY_SESSION, { id }, 'GET').then(res => {
				if (res.success) {
					resolve(res.data);
				}
				else {
					reject(res);
				}
			}).catch(err => {
				logger.error('Error in api:', Path.VERIFY_SESSION, 'error:', err);
				reject('There seems to be some trouble at our server, please try again');
			});
		});
	}

	public isChiefModeratorPresent(sessionID: string, userType: USER_TYPE, isChiefModerator: boolean = false): Promise<{isChiefModeratorPresent: boolean, activeUserTypeConnections: any}> {
		return new Promise((resolve, reject) => {
			const t = isChiefModerator.toString();
			this.sendRequest(Path.IS_CHIEF_MODERATOR_PRESENT, { id: sessionID, userType, isChiefModerator: t }, 'GET').then(res => {
				if (res.success) {
					resolve(res.data);
				}
				else {
					reject(res.msg);
				}
			}).catch(err => {
				logger.error('Error in api:', Path.IS_CHIEF_MODERATOR_PRESENT, 'error:', err);
				reject('There seems to be some trouble at our server, please try again');
			});
		});
	}

	public saveParticipantPhoto(sessionId: string, data: string | ArrayBuffer): Promise<void> {
		return new Promise((resolve, reject) => {
			this.sendRequest(Path.SAVE_PHOTO, { id: sessionId }, 'POST', data, { 'Content-Type': 'application/octet-stream' }, false, false, false).then(res => {
				if (res.success) {
					resolve();
				}
				else {
					reject(res.msg);
				}
			}).catch(err => {
				reject(err);
			});
		});
	}

	public endSesion(sessionId: string, retryTime = 0, kickedOut: string = KICKED_OUT_FALSE, terminatedFromYii: boolean = false): Promise<void> {
		// max retries incase api fails
		const maxRetries = 5;
		// time between each successive request
		const timeout = 1000 * 30;
		return new Promise((resolve, reject) => {
			retryTime++;
			if (retryTime > maxRetries) {
				reject();
				return;
			}
			const t = terminatedFromYii.toString();
			this.sendRequest(Path.STOP_SESSION, { id: sessionId, kickedOut, terminatedFromYii: t }, 'GET').then(res => {
				if (res.success) {
					resolve();
				}
				else {
					logger.error('Error in ending session:', res);
					setTimeout(() => this.endSesion(sessionId, retryTime), timeout);
				}
			}).catch(err => {
				logger.error('Error in ending session:', err);
				setTimeout(() => this.endSesion(sessionId, retryTime), timeout);
			});
		});
	}

	public fetchRecordingStatus(sessionId: string): Promise<{ recordingStatus: RecordingEventType, recordingStartTime?: number, timeLimit: number }> {
		// time between each successive request
		const timeout = 1000 * 10;
		return new Promise((resolve, reject) => {
			this.sendRequest(Path.FETCH_RECORDING_STATUS, { id: sessionId }, 'GET').then(res => {
				resolve(res);
			}).catch(err => {
				logger.error('Error in ending session:', err);
				setTimeout(() => this.fetchRecordingStatus(sessionId), timeout);
			});
		});
	}

	public fetchSessionStartTime(sessionId: string): Promise<{ connections: any }> {
		// time between each successive request
		const timeout = 1000 * 10;
		return new Promise((resolve, reject) => {
			this.sendRequest(Path.GET_SESSION_START_TIME, { id: sessionId }, 'GET').then(resolve).catch(err => {
				logger.error('Error in fetching session:', err);
				setTimeout(() => this.fetchSessionStartTime(sessionId), timeout);
			});
		});
	}

	public sendFeedback(sessionID: string, feedback: string, log = null) {
		this.sendRequest(Path.FEEDBACK, { sessionID, feedback }, 'POST', { sessionID, feedback, log }, null, true, false, false).catch(() => { });
	}

	public sendLog(sessionID: string, log: any) {
		this.sendRequest(Path.LOG, { sessionID }, 'POST', { sessionID, log }, null, true, false, false).catch(() => { });
	}

	public saveInstructions(sessionName: string, data: string): Promise<void> {
		const time = 1000 * 5;
		return new Promise((resolve, reject) => {
			this.sendRequest(Path.SAVE_INSTRUCTIONS, { id: sessionName }, 'POST', data).then(res => {
				if (res.success) {
					resolve();
				}
				else {
					reject(res.msg);
				}
			}).catch(err => {
				setTimeout(() => this.saveInstructions(sessionName, data), time);
			});
		});
	}

	public sendRecorderLog(data: any): Promise<void> {
		return new Promise((resolve, reject) => {
			logger.log("sendRecorderLog will send request: ", data);
			this.sendRequest(Path.RECORDER_EVENT_LOG, {path: 'RECORDER_EVENT_LOG'}, 'POST', data, null, false, false, false).then(res => {
				logger.log("Sending request: ", data);
				resolve();
			}).catch((error) => {
				logger.log("Error in sending request for data: ", data, " error: ", error);
			});
		});
	}

	public saveBookmark(sessionName: string, data: object): Promise<void> {
		const time = 1000 * 5;
		return new Promise((resolve, reject) => {
			this.sendRequest(Path.SAVE_BOOKMARK, { id: sessionName }, 'POST', data).then(res => {
				if (res.success) {
					resolve();
				}
				else {
					reject(res.msg);
				}
			}).catch(err => {
				setTimeout(() => this.saveBookmark(sessionName, data), time);
			});
		});
	}

	public saveJoinStatus(sessionName: string, data: object): Promise<void> {
		const time = 1000 * 5;
		return new Promise((resolve, reject) => {
			this.sendRequest(Path.SAVE_JOIN_STATUS, { id: sessionName }, 'POST', data).then(res => {
				if (res.success) {
					resolve();
				}
				else {
					reject(res.msg);
				}
			}).catch(err => {
				setTimeout(() => this.saveJoinStatus(sessionName, data), time);
			});
		});
	}

	public deleteNote(sessionName: string, data: object): Promise<void> {
		const time = 1000 * 5;
		return new Promise((resolve, reject) => {
			this.sendRequest(Path.DELETE_NOTE, { id: sessionName }, 'POST', data).then(res => {
				if (res.success) {
					resolve();
				}
				else {
					reject(res.msg);
				}
			}).catch(err => {
				setTimeout(() => this.deleteNote(sessionName, data), time);
			});
		});
	}
}
