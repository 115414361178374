import { environment } from 'src/environments/environment';
import { Component, ViewEncapsulation, HostListener } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { SessionService } from 'src/app/services/session.service';
import { SessionStatus, RecordingEventType, USER_TYPE, SessionMode, INSTRUCTIONS_ACTIVE, CHAT_ACTIVE, VIDEOS_ACTIVE } from 'src/constants';
import { ConnectionService } from 'src/app/services/connection.service';
import { TranslationService } from '../../../services/translation.service';

@Component({
	selector: 'app-main',
	templateUrl: './main.component.html',
	styleUrls: ['./main.component.styl'],
	encapsulation: ViewEncapsulation.ShadowDom
})
export class MainComponent {
	public guideShown: boolean;

	constructor(
		public commonService: CommonService,
		public sessionService: SessionService,
		public connectionService: ConnectionService,
		public translationService: TranslationService) {
		// disable video panel for tester
		if (isMobile && this.sessionService.userType != USER_TYPE.TESTER) {
			this.commonService.showVideos = true;
		}
	}

	// for use in html
	public objectKeys = Object.keys;
	public fetchResource = fetchResource;

	// static content used in html
	public preloadText: string = '';
	public tests: Array<string> = [];
	public SessionStatus = SessionStatus;
	public RecordingEventType = RecordingEventType;
	public USER_TYPE = USER_TYPE;
	public SessionMode = SessionMode;
	public showInstructions: boolean = true;

	public INSTRUCTIONS_ACTIVE = INSTRUCTIONS_ACTIVE;
	public CHAT_ACTIVE = CHAT_ACTIVE;
	public VIDEOS_ACTIVE = VIDEOS_ACTIVE;
	public activeToggleItem: number = this.sessionService.userType == USER_TYPE.TESTER ? INSTRUCTIONS_ACTIVE : VIDEOS_ACTIVE;
	public hamburgerActive: boolean = false;

	public getShortcutKey(ctrlKey, char) {
		const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
		if(isMac && ctrlKey.toLowerCase() == 'alt')
			ctrlKey = '⌥';
		return `(${ctrlKey} + ${char})`;
	}

	@HostListener('document:keydown', ['$event']) onKeyPressHandler(event: KeyboardEvent) {
		if (this.sessionService.sessionStatus === SessionStatus.SESSION_STARTED && event) {
			if (event.altKey) {
				// notes
				if ((event?.key?.toLowerCase() == 'n' ||
					event?.keyCode == 78 ||
					event?.code?.toLowerCase() == 'keyn') &&
					this.sessionService.sessionMode === SessionMode.IN_SESSION &&
					this.commonService.callOptions.notes) {
					this.commonService.toggleNotes();
				}
				// chat
				else if (event?.key?.toLowerCase() == 'c' ||
					event?.keyCode == 67 ||
					event?.code?.toLowerCase() == 'keyc') {
					this.commonService.toggleChat();
				}
				// instructions
				else if (event?.key?.toLowerCase() == 'i' ||
					event?.keyCode == 73 ||
					event?.code?.toLowerCase() == 'keyi') {
					this.commonService.toggleInstructions();
				}
				// bookmark
				else if (event?.key?.toLowerCase() == 'b' ||
					event?.keyCode == 66 ||
					event?.code?.toLowerCase() == 'keyb') {
					this.commonService.saveBookMark();
				}
				// video panel
				else if (event?.key?.toLowerCase() == 'v' ||
					event?.keyCode == 86 ||
					event?.code?.toLowerCase() == 'keyv') {
					this.commonService.toggleVideoPanel();
				}
				// translation menu panel
				else if (event?.key?.toLowerCase() == 't' ||
					event?.keyCode == 84 ||
					event?.code?.toLowerCase() == 'keyt') {
					this.commonService.toggleTranslationMenu();
				}
				// invite
				else if(event?.key?.toLowerCase() == 'l' ||
					event?.keyCode == 76 ||
					event?.code?.toLowerCase() == 'keyl') {
					this.commonService.toggleCopyLinks();
				}
				// toggle mic
				else if(event?.key?.toLowerCase() == 'm' ||
					event?.keyCode == 77 ||
					event?.code?.toLowerCase() == 'keym') {
					this.commonService.toggleMic();
				}
				// toggle camera
				else if(event?.key?.toLowerCase() == 'r' ||
					event?.keyCode == 82 ||
					event?.code?.toLowerCase() == 'keyr') {
					this.commonService.toggleCam();
				}
				// toggle screen share
				else if(event?.key?.toLowerCase() == 's' ||
					event?.keyCode == 83 ||
					event?.code?.toLowerCase() == 'keys') {
					this.commonService.toggleScreenSharing();
				}
				// quit call
				else if(event?.key?.toLowerCase() == 'q' ||
					event?.keyCode == 81 ||
					event?.code?.toLowerCase() == 'keyq') {
						(this.sessionService.isChiefModerator && this.commonService.callOptions.endSession && this.sessionService.sessionMode === SessionMode.IN_SESSION) ? this.commonService.endSession() : this.commonService.endCall()
				}
			}
			// dismiss all on escape
			else if (event?.key?.toLowerCase() == 'escape' ||
				event?.keyCode == 27 ||
				event?.code?.toLowerCase() == 'escape') {
				this.commonService.chatInFocus && this.commonService.toggleChat(false);
				this.commonService.notesInFocus && this.commonService.toggleNotes(false);
				this.commonService.instructionsInFocus && this.commonService.toggleInstructions(false);
				this.commonService.translationMenuInFocus && this.commonService.toggleTranslationMenu(false);
				this.commonService.videoPanelInFocus && this.commonService.toggleVideoPanel(false);
				this.commonService.copyLinksInFocus && this.commonService.toggleCopyLinks(false);
			}

		}
	}
}
