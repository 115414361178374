import { environment } from 'src/environments/environment';
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { SessionService } from 'src/app/services/session.service';
import { TechCheckService } from 'src/app/services/tech-check.service';
import { TechCheckItem, TECH_CHECK_STATUS, TECH_CHECK_TYPES, USER_TYPE } from 'src/constants';
// library used to perform technical checks
const DetectRTC = require('detectrtc');

@Component({
	selector: 'app-tech-check',
	templateUrl: './tech-check.component.html',
	styleUrls: ['./tech-check.component.styl'],
	encapsulation: ViewEncapsulation.ShadowDom
})

export class TechCheckComponent implements AfterViewInit, OnDestroy {
	public checkdone = false;
	public checkfail = false;
	public errors = false;
	public errorSub = '';

	public enterSession: boolean = false;
	public TECH_CHECK_STATUS = TECH_CHECK_STATUS;
	public TECH_CHECK_TYPES = TECH_CHECK_TYPES;
	public USER_TYPE = USER_TYPE;
	public fetchResource = fetchResource;

	public checks: TechCheckItem[] = [
		{
			key: TECH_CHECK_TYPES.BROWSER,
			name: 'Browser Compatibility',
			status: TECH_CHECK_STATUS.PROGRESS,
			failReason: ''
		},
		{
			key: TECH_CHECK_TYPES.MIC,
			name: 'Microphone',
			status: TECH_CHECK_STATUS.PROGRESS,
			failReason: ''
		},
		{
			key: TECH_CHECK_TYPES.CAM,
			name: 'Camera',
			status: TECH_CHECK_STATUS.PROGRESS,
			failReason: ''
		},
		{
			key: TECH_CHECK_TYPES.SCREEN_SHARING,
			name: 'Screen Sharing',
			status: TECH_CHECK_STATUS.PROGRESS,
			failReason: ''
		}
	];

	public findCheckSpec(key) {
		const check = this.checks.find(x => x.key == key);
		if (check) {
			return check;
		}
		return {};
	}

	constructor(
		public commonService: CommonService,
		private changeDetectorRef: ChangeDetectorRef,
		public sessionService: SessionService,
		public techCheckService: TechCheckService) {
	}

	ngAfterViewInit() {
		// For participants, the system checks will be handled by individual components
		if (this.sessionService.userType != USER_TYPE.TESTER) {
			this.checkSystem();
		}

		// Make body scrollable, content may overflow
		document.body.style.overflow = 'auto';
	}

	private checkSystem() {
		this.checkBrowserCapabilities().then(() => {
			this.checkdone = true;
			this.changeDetectorRef.detectChanges();
		}).catch(err => {
			alert({ text: err, icon: ICON.ERROR });
			this.checkdone = true;
			this.checkfail = true;
			this.changeDetectorRef.detectChanges();
		});
	}

	public checkBrowserCapabilities(): Promise<void> {
		return new Promise((resolve, reject) => {
			DetectRTC.load(() => {
				// DetectRTC.checkWebSocketsSupport(() => {
				logger.log(DetectRTC);

				const staticMessage = 'Please use latest version of Google Chrome or Mozilla Firefox';
				// basic checks for all users
				const browserChecks = [
					{
						condition: 'isWebRTCSupported',
						reason: 'This browser does not support WEBRTC protocols, ' + staticMessage,
					},
					{
						condition: 'isWebSocketsSupported',
						reason: 'This browser does not support Web Socket protocols, ' + staticMessage,
					},
					{
						condition: 'isPromisesSupported',
						reason: 'This Browser is old, ' + staticMessage,
					},
					{
						condition: 'isWebSocketsBlocked',
						reason: 'This browser has blocked WebSockets protocols, please enable them and refresh the page',
						negate: true,
					},
					{
						condition: 'hasSpeakers',
						reason: 'Your speakers might not be working, please check and refresh the page',
						optional: true
					},
				];

				let mediaCheck: any = {
					condition: 'isGetUserMediaSupported',
					reason: 'Your browser is old and does not support access to microphone and camera, ' + staticMessage,
					optional: false,
				};

				let micCheck: any = {
					condition: 'hasMicrophone',
					reason: 'Your microphone may not be working or not be present, please check and refresh the page after fixing it',
					optional: false,
					key: TECH_CHECK_TYPES.MIC
				};

				let camCheck: any = {
					condition: 'hasWebcam',
					reason: 'Your webcam may not be working or not be present, please check and refresh the page after fixing it',
					optional: false,
					key: TECH_CHECK_TYPES.CAM
				};

				if (isIOS) {
					mediaCheck = {
						optional: true
					};
					micCheck = {
						optional: true
					};
					camCheck = {
						optional: true
					};
				}

				const screenSharingCheck = {
					condition: 'isScreenCapturingSupported',
					reason: 'This system does not support sharing screen, ' + staticMessage,
					optional: false,
					key: TECH_CHECK_TYPES.SCREEN_SHARING
				};

				// user specific checks
				switch (this.sessionService.userType) {
					case USER_TYPE.TESTER:
						camCheck.optional = true;
						browserChecks.push({
							condition: 'isTakinPhotoSupported',
							reason: 'This Browser is old, ' + staticMessage,
							optional: false
						});
						let isTakinPhotoSupported = false;
						if (typeof document !== 'undefined' &&
							typeof document.createElement == 'function') {
							const canvas = document.createElement('canvas');
							if (typeof canvas !== 'undefined' &&
								typeof CanvasRenderingContext2D !== 'undefined' &&
								typeof canvas.getContext !== 'undefined') {
								const ctx = canvas.getContext('2d');
								if (typeof ctx !== 'undefined' &&
									ctx instanceof CanvasRenderingContext2D &&
									typeof ctx.drawImage == 'function' &&
									typeof canvas.toBlob == 'function' &&
									typeof FileReader == 'function' &&
									typeof FileReader.prototype.readAsArrayBuffer == 'function') {
									isTakinPhotoSupported = true;
								}
							}
						}
						DetectRTC.isTakinPhotoSupported = isTakinPhotoSupported;
						break;
					case USER_TYPE.MODERATOR:
						camCheck.optional = true;
						screenSharingCheck.optional = true;
						mediaCheck.optional = true;
						break;
					default:
						micCheck.optional = true;
						camCheck.optional = true;
						screenSharingCheck.optional = true;
						mediaCheck.optional = true;
						break;
				}

				const checks: any = [];
				for (const check of checks) {
					if (check && check.condition && check.negate ? DetectRTC[check.condition] : !DetectRTC[check.condition]) {
						const key = check.key || TECH_CHECK_TYPES.BROWSER;
						const failedCheck = this.checks.find(d => d.key == key);
						failedCheck.status = TECH_CHECK_STATUS.FAILED;
						failedCheck.failReason = check.reason;
						this.errors = true;
						if (!check.optional) {
							this.markAll(TECH_CHECK_STATUS.NONE);
							reject(check.reason);
							return;
						}

						if (check.condition == 'isScreenCapturingSupported') {
							this.commonService.disableScreenSharingButton = true;
						}
					}
				}

				if (!DetectRTC.isGetUserMediaSupported && !isIOS) {
					const arr = [TECH_CHECK_TYPES.MIC];
					if (!isMobile) {
						arr.push(TECH_CHECK_TYPES.CAM);
					}
					arr.forEach(key => {
						const failedCheck = this.checks.find(d => d.key == key);
						failedCheck.status = TECH_CHECK_STATUS.FAILED;
						failedCheck.failReason = `Your ${failedCheck.name} will remain disabled through out call`;
						if (key == TECH_CHECK_TYPES.CAM) {
							this.commonService.disableCamButton = true;
						}
						if (key == TECH_CHECK_TYPES.MIC) {
							this.commonService.disableMicButton = true;
						}

					});
				}

				this.commonService.disablePowerSaverMode().then(() => {
					this.markAll(TECH_CHECK_STATUS.SUCCESS);
					resolve();
				});
				// });
			});
		});
	}

	private markAll(status: TECH_CHECK_STATUS) {
		this.checks.forEach(d => {
			if (d.status == TECH_CHECK_STATUS.PROGRESS) {
				d.status = status;
			}
		});
	}

	ngOnDestroy() {
		// Hide scroll
		document.body.style.overflow = 'hidden';
	}
}
