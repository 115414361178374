import { Component, ViewEncapsulation, HostListener, OnDestroy } from '@angular/core';
import { SessionService } from './services/session.service';
import { SessionStatus } from 'src/constants';
import { DomSanitizer } from '@angular/platform-browser';
import { Platform } from '@ionic/angular';
import { Plugins } from '@capacitor/core';
import { version } from '../version';

const { SplashScreen } = Plugins;

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.styl'],
	encapsulation: ViewEncapsulation.ShadowDom
})
export class AppComponent implements OnDestroy {

	// stopping refresh -> unable to put custom msg, refer https://bugs.chromium.org/p/chromium/issues/detail?id=587940
	@HostListener('window:beforeunload', ['$event']) beforeUnloadHander(event: any) {
		if (this.sessionService.sessionStatus === SessionStatus.SESSION_STARTED) {
			return false;
		}
		return true;
	}

	@HostListener('window:onunload', ['$event']) onUnloadHander(event: any) {
		if (this.sessionService.sessionStatus === SessionStatus.SESSION_STARTED) {
			this.sessionService.disconnect();
		}
	}

	constructor(
		private sessionService: SessionService,
		private sanitizer: DomSanitizer,
		private platform: Platform) {
		// fetches resource from remote url and saves them in memory to save bandwidth
		window.fetchResource = (path, sanitize = true) => this.fetchResource(path, sanitize);

		// initializeApp
		this.platform.ready().then(() => {
			SplashScreen.hide();
		});

		// update version in storage for reference from dev tools
		if (window && window.localStorage) {
			window.localStorage.setItem('version', version);
		}
	}

	public fetchResource(path: string, sanitize = true): string {
		if (!iconURLS[path]) {
			iconURLS[path] = { path: null };
			this.loadAsset(path)
				.then((url: any) => {
					if (sanitize) {
						url = this.sanitizer.bypassSecurityTrustUrl(url);
					}
					iconURLS[path].path = url;
				})
				.catch(() => {});
		}
		return iconURLS[path].path || path;
	}

	public loadAsset(path: string): Promise<string> {
		return new Promise((resolve, reject) => {
			// fetch url and create a data url
			if (typeof window.fetch == 'function') {
				fetch(path, {
					method: 'GET',
					mode: 'same-origin',
					cache: 'no-cache',
				}).then(response => {
					if (response && response.body && typeof response.blob == 'function') {
						response.blob().then(blob => {
							if (blob && blob instanceof Blob && FileReader && typeof FileReader.prototype.readAsDataURL == 'function') {
								try {
									const fileReader = new FileReader();
									fileReader.onload = () => {
										const url = fileReader.result;
										if (url && typeof url == 'string') {
											resolve(url);
										} else {
											reject();
										}
									};
									fileReader.readAsDataURL(blob);

								} catch (e) {
									reject();
								}
							} else {
								reject();
							}
						}).catch(reject);
					} else {
						reject();
					}
				}).catch(reject);
			} else {
				reject();
			}
		});
	}

	ngOnDestroy() {
		this.sessionService.disconnect();
	}
}
