import { Component, ViewEncapsulation, ChangeDetectorRef, ElementRef, AfterViewInit, OnDestroy, ViewChildren, QueryList } from '@angular/core';
import { Subscription } from 'rxjs';
import { StreamEventMessage, StreamType, StreamEventType } from 'src/constants';
import { SessionService } from 'src/app/services/session.service';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
	selector: 'app-audio-player',
	templateUrl: './audio-player.component.html',
	styleUrls: ['./audio-player.component.styl'],
	encapsulation: ViewEncapsulation.ShadowDom
})
export class AudioPlayerComponent implements AfterViewInit, OnDestroy {
	@ViewChildren('audio') audioElements: QueryList<ElementRef<HTMLMediaElement>>;

	private streamsSub: Subscription;
	private volumeSub: Subscription;
	public streams: Array<StreamEventMessage> = [];

	private setAudioTimeout;

	ngAfterViewInit() {
		if (isIOS) {
			return;
		}

		const setAudio = () => {
			logger.log("================ audioPlayer - SetAudio");
			// clear any previous timeouts
			if (this.setAudioTimeout) {
				clearTimeout(this.setAudioTimeout);
				this.setAudioTimeout = null;
			}

			if (this.audioElements && this.audioElements.length) {
				logger.log("================ audioPlayer - SetAudio AudioElements Found: ", this.audioElements.length);
				this.audioElements.forEach((element: ElementRef) => {
					if (element.nativeElement) {
						const id = element.nativeElement.getAttribute('id');
						const stream = this.streams.find(d => d?.src?.id === id);
						if (stream) {
							logger.log("================ audioPlayer - SetAudio Stream Found: ", JSON.stringify(stream), " With Volume: ", (stream?.volume ?? 1));
							const func = () => {
								const promise = element.nativeElement.play();
								logger.log("================ audioPlayer - SetAudio Func Called: ", " promise instanceof Promise: ", promise instanceof Promise, "(promise && 'then' in promise && typeof promise.then === 'function'): ", (promise && 'then' in promise && typeof promise.then === 'function'));
								if ((promise && 'then' in promise && typeof promise.then === 'function') || (promise && promise instanceof Promise)) {
									promise.then(() => {
										logger.log("================ audioPlayer - SetAudio Playing Started");
									}).catch((err) => {
										logger.error("================ audioPlayer - SetAudio Error Playing Audio: ", err, "Calling SetAudio Again.");
										this.setAudioTimeout = setTimeout(() => setAudio(), 500);
									});
								}
								this.changeDetectorRef.detectChanges();
								element.nativeElement.removeEventListener('canplaythrough', func);
							};
							element.nativeElement.srcObject = stream.src;
							element.nativeElement.volume = stream?.volume ?? 1;
							element.nativeElement.addEventListener('canplaythrough', func);
						}
					}
				});
				this.changeDetectorRef.detectChanges();
			}
			else {
				logger.log("================ audioPlayer - SetAudio AudioElements Not Found. Calling SetAudio Again.");
				this.setAudioTimeout = setTimeout(() => setAudio(), 500);
			}
		};

		this.streamsSub = this.sessionService.streamEventListener.subscribe((res: StreamEventMessage) => {
			logger.log("================ audioPlayer - Stream Received: ", JSON.stringify(res));
			if (res && res.kind === StreamType.AUDIO) {
				logger.log("================ audioPlayer - Stream Is Audio With StreamEventType: ", res.type);
				if (res.type === StreamEventType.STREAM_ADDED) {
					logger.log("================ audioPlayer - Audio Stream Added From: ", res?.from?.userType, res?.from?.name);
					this.streams.push(res);
				} else {
					logger.log("================ audioPlayer - Audio Stream Removed");
					this.streams.filter(l => l.streamId === res.streamId);
				}
				this.changeDetectorRef.detectChanges();
				setAudio();
			}
		});

		this.volumeSub = this.translationService.volumeChangeEventListener.subscribe(affectedStreams => {
			logger.log("================ audioPlayer - VolumeChangeEventListener From TranslationService, affectedStreams: ", JSON.stringify(affectedStreams));
			affectedStreams?.forEach(affectedStreams => {
				const element = this.audioElements.find(element => element?.nativeElement?.getAttribute('id') === affectedStreams?.src?.id);
				if(element?.nativeElement) {
					element.nativeElement.volume = affectedStreams?.volume ?? 1;
				}
			});
		});

		this.translationService.translatorAvailableEventListener.subscribe(isTranslatorAvailable => {
			logger.log("================ audioPlayer - translatorAvailableEventListener, isTranslatorAvailable: ", isTranslatorAvailable);
			if(!isTranslatorAvailable) {
				logger.log("================ audioPlayer - translatorAvailableEventListener, translator not available in session, resetting all volumes to 1 ");
				this.audioElements.forEach(el => {
					el.nativeElement.volume = 1;
				});
			}
		});
	}

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private sessionService: SessionService,
		private translationService: TranslationService) {
	}

	trackById(index: number, item: StreamEventMessage) {
		return item.streamId;
	}

	ngOnDestroy() {
		logger.log("================ audioPlayer - ngOnDestroy");
		this.streamsSub?.unsubscribe();
		this.volumeSub?.unsubscribe();
		this.streamsSub = null;
		this.volumeSub = null;
	}
}

