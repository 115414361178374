import { Injectable, OnDestroy } from '@angular/core';
import { USER_TYPE, StreamType, StreamEventType, ConnectionEventMessage, ConnectionEventType, StreamChannel, SessionStatus } from 'src/constants';
import { SessionService } from './session.service';
import { StreamEventMessage } from '../../constants';
import { BehaviorSubject, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

// capacitor imports
import { Plugins } from '@capacitor/core';
const { ScreenSharing } = Plugins;
import 'screen-sharing';

const RANGE_SLIDER_LOWER_COLOR = '#112260';
const RANGE_SLIDER_UPPER_COLOR = '#11226033';

const MOBILE_RANGE_SLIDER_LOWER_COLOR = '#333333';
const MOBILE_RANGE_SLIDER_UPPER_COLOR = '#33333333';

@Injectable({
	providedIn: 'root'
})
export class TranslationService implements OnDestroy {

	public streamChannels: StreamChannel[] = [
		{
			id: 1,
			name: 'Participant audio',
			userTypes: [USER_TYPE.TESTER],
			streamTypes: [StreamType.AUDIO],
			isChiefModerator: false,
			volume: 1,
			hidden: true,
			background: null
		},
		{
			id: 2,
			name: 'Moderator audio',
			userTypes: [USER_TYPE.MODERATOR],
			streamTypes: [StreamType.AUDIO],
			isChiefModerator: true,
			volume: 1,
			hidden: true,
			background: null
		},
		{
			id: 3,
			name: 'Co-Facilitator audio',
			userTypes: [USER_TYPE.MODERATOR],
			streamTypes: [StreamType.AUDIO],
			isChiefModerator: false,
			volume: 1,
			hidden: true,
			background: null
		},
		{
			id: 4,
			name: 'Translator audio',
			userTypes: [USER_TYPE.TRANSLATOR],
			streamTypes: [StreamType.AUDIO],
			isChiefModerator: false,
			volume: 1,
			hidden: true,
			background: null
		}
	];

	// keeps only affected streams
	private streams: StreamEventMessage[] = [];

	// subscription log
	private streamSub: Subscription;
	private subs: Subscription[] = [];

	public isTranslatorPresent = false;

	// sends volumeChange events
	private volumeChangeEventSubject: BehaviorSubject<StreamEventMessage[]> = new BehaviorSubject<StreamEventMessage[]>(null);
	public volumeChangeEventListener = this.volumeChangeEventSubject.asObservable();

	// translator availability event
	private translatorAvailableEventSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public translatorAvailableEventListener = this.translatorAvailableEventSubject.asObservable();

	// watch stream instructions
	private watchStreamsArray = [];
	private watchStreamsSubject: BehaviorSubject<StreamEventMessage[]> = new BehaviorSubject<StreamEventMessage[]>(null);
	private watchStreamListener = this.watchStreamsSubject.asObservable();

	constructor(private sessionService: SessionService) {
		// enable service fully when session starts and when current user needs translator
		logger.log("---- translationService");
		const sub = this.sessionService.sessionStatusEventListener.subscribe(status => {
			if (status === SessionStatus.SESSION_STARTED) {
				logger.log("---- translationService session started");
				// hide channel that has self in it
				const selfChannel = this.streamChannels.find(d => d.userTypes.includes(this.sessionService.userType) && d.isChiefModerator === this.sessionService.isChiefModerator);
				if (selfChannel) {
					selfChannel.hidden = true;
				}

				this.watchMembers();
			} else if (status === SessionStatus.SESSION_ENDED) {
				logger.log("---- translationService session ended");
				this.ngOnDestroy();
			}
		});
		this.subs.push(sub);

		//set background for all
		this.streamChannels.forEach(d => this.setBackground(d));
	}

	// by default only audio streams will be manipulated
	public toggleChannel(id: number, volume: number | string) {
		logger.log("---- translationService toggleChannel -- id: ", id, " volume: ", volume);
		// find
		const channel = this.streamChannels.find(d => !d.hidden && d.id === id);
		// toggle
		if (channel) {
			channel.volume = parseFloat(volume.toString());
			this.setBackground(channel);
			// apply
			const affectedStreams = [];
			logger.log("---- translationService toggleChannel -- streams: ", JSON.stringify(this.streams));
			this.streams.forEach(d => {
				if (channel.userTypes.includes(d?.from?.userType) && channel.streamTypes.includes(d?.kind) && channel.isChiefModerator === d?.from?.isChiefModerator) {
					this.toggleStream(d, channel.volume);
					affectedStreams.push(d);
				}
			});
			logger.log("---- translationService toggleChannel -- affectedStreams: ", JSON.stringify(affectedStreams));

			// send change event
			this.volumeChangeEventSubject.next(affectedStreams);
		}
	}

	// watches joining/leaving of members
	private watchMembers() {
		if(!isIOS) {
			logger.log("---- translationService watchMembers");
			// resetting watch stream instructions
			this.watchStreamsArray = [];
			this.watchStreamsSubject.next(this.watchStreamsArray);

			// remove previous if any
			this.streamSub?.unsubscribe();

			// listen for new streams
			this.streamSub = this.sessionService.streamEventListener.subscribe(event => {
				if(event?.kind && event?.kind == StreamType.AUDIO) {
					logger.log("---- translationService audio stream received", JSON.stringify(event));
					this.watchStreamsArray.push(event);
					this.watchStreamsSubject.next(this.watchStreamsArray);
				}
			});

			this.subs.push(this.streamSub);

			logger.log("---- translationService watchStreamArray", JSON.stringify(this.watchStreamsArray));
			const sub = this.sessionService.connectionEventListener.subscribe((msg: ConnectionEventMessage) => {
				if(msg) {
					logger.log("---- translationService connectionEventListener", JSON.stringify(msg));

					let watchStreamProceedIndex = -1;

					// set visiblity of channels upon joining/leaving of members
					// sending connection id in case of leaving, as to not count him if data is not in sync
					this.setVisibility(msg?.data?.userType, msg?.data?.isChiefModerator, msg?.type === ConnectionEventType.CONNECTION_DESTROYED ? msg?.data?.connectionId : null);
					
					// enable channels on join
					if (msg?.type === ConnectionEventType.CONNECTION_CREATED) {
						if(!this.isTranslatorPresent && msg?.data?.userType === USER_TYPE.TRANSLATOR) {
							logger.log("---- translationService translator present in session");
							this.isTranslatorPresent = true;
							this.translatorAvailableEventSubject.next(true);

							// set all streams to their previous configuration
							this.resetAll();
						}
						
						if(this.isTranslatorPresent) {
							this.watchStreamListener.subscribe((streamMsg) => {
								logger.log("---- translationService watchStreamListener subscription", JSON.stringify(streamMsg));
								if(streamMsg.length) {
									logger.log("---- translationService streamMsg -- count: ", streamMsg.length, " & watchStreamProceedIndex: ", watchStreamProceedIndex, " & data: ", JSON.stringify(streamMsg));
									for(let index = watchStreamProceedIndex + 1; index < streamMsg.length; index++) {
										const event = streamMsg[index];
										logger.log("---- translationService proceedStream -- index: ", index, " & data: ", JSON.stringify(event));
										if (event?.type === StreamEventType.STREAM_ADDED &&
											event?.kind &&
											event?.from?.userType &&
											event?.src) {
											// find channel
											logger.log("---- translationService proceedStream -- streamChannels: ", JSON.stringify(this.streamChannels));
											const channels = this.streamChannels.filter(d => !d.hidden && d.userTypes.includes(event.from.userType) && d.streamTypes.includes(event.kind) && d.isChiefModerator === event.from.isChiefModerator);
											logger.log("---- translationService proceedStream -- matched channels: ", JSON.stringify(channels));
											if(channels) {
												channels.forEach(channel => {
													logger.log("---- translationService proceedStream -- channel: ", JSON.stringify(channel));
													// apply
													if (channel) {
														this.toggleStream(event, channel.volume);
														const streamAlreadyExists = this.streams.findIndex(d => channel.userTypes.includes(d?.from?.userType) && channel.isChiefModerator === d?.from?.isChiefModerator);
														if(streamAlreadyExists != -1) {
															logger.log("---- translationService proceedStream -- stream for same role is already available in streams array, so removing it first before appending newly added stream into streams array");
															this.streams.splice(streamAlreadyExists, 1);
														}
														this.streams.push(event);
														logger.log("---- translationService proceedStream -- appending stream to streams array -- streams: ", JSON.stringify(this.streams));
													}
												});
											}
										} else if (event?.type === StreamEventType.STREAM_REMOVED) {
											// remove affected stream
											const index = this.streams.findIndex(d => d?.streamId == event?.streamId);
											if (index != -1) {
												this.streams.splice(index, 1);
												logger.log("---- translationService proceedStream -- removing stream from streams array -- streams: ", JSON.stringify(this.streams));
											}
										}
									}
									watchStreamProceedIndex = streamMsg.length - 1;
								}
							});
						}
					}
					// remove channels on leave
					else if (this.isTranslatorPresent && msg?.type === ConnectionEventType.CONNECTION_DESTROYED && msg?.data?.userType === USER_TYPE.TRANSLATOR) {
						logger.log("---- translationService translator left the session");
						this.isTranslatorPresent = false;
						this.translatorAvailableEventSubject.next(false);
						this.enableAll();
					}
				}
			});
			this.subs.push(sub);
		} else {
			// for iOS, the subscription is native so only calling setVisibility, not touching the audio streams at all
			const sub = this.sessionService.connectionEventListener.subscribe((msg: ConnectionEventMessage) => {
				// set visiblity of channels upon joining/leaving of members
				// sending connection id in case of leaving, as to not count him if data is not in sync
				this.setVisibility(msg?.data?.userType, msg?.data?.isChiefModerator, msg?.type === ConnectionEventType.CONNECTION_DESTROYED ? msg?.data?.connectionId : null);
				
				// enable channels on join
				if (!this.isTranslatorPresent && msg?.type === ConnectionEventType.CONNECTION_CREATED && msg?.data?.userType === USER_TYPE.TRANSLATOR) {
					logger.log("---- translationService translator present in session");
					this.isTranslatorPresent = true;
					this.translatorAvailableEventSubject.next(true);
				}
				// remove channels on leave
				else if (this.isTranslatorPresent && msg?.type === ConnectionEventType.CONNECTION_DESTROYED && msg?.data?.userType === USER_TYPE.TRANSLATOR) {
					this.isTranslatorPresent = false;
					this.translatorAvailableEventSubject.next(false);
				}
			});
			this.subs.push(sub);
		}
	}

	// enables all streams, does not modify the channels they remain as they were
	private enableAll() {
		this.streams.forEach(d => this.toggleStream(d, 1));
		logger.log("---- translationService enabling all streams", JSON.stringify(this.streams));
	}

	// applies the effect on stream
	private toggleStream(stream: StreamEventMessage, volume: number) {
		stream.volume = volume;
	}

	// applies the channels on all previously present streams
	private resetAll() {
		this.streams.forEach(stream => {
			const channels = this.streamChannels.filter(d => !d.hidden && d.userTypes.includes(stream?.from?.userType) && d.streamTypes.includes(stream?.kind) && d.isChiefModerator === stream?.from?.isChiefModerator);
			if(channels) {
				channels.forEach(channel => {
					if (channel) {
						this.toggleStream(stream, channel.volume);
					}
				});
			}
		});
	}

	// sets the background color of the slider for a particular channel
	public setBackground(item: StreamChannel) {
		if(!environment.mobile)
			item.background = `linear-gradient(to right, ${RANGE_SLIDER_LOWER_COLOR} 0%, ${RANGE_SLIDER_LOWER_COLOR} ${item.volume * 100}%, ${RANGE_SLIDER_UPPER_COLOR} ${item.volume * 100}%, ${RANGE_SLIDER_UPPER_COLOR} 100%)`;
		else
			item.background = `linear-gradient(to right, ${MOBILE_RANGE_SLIDER_LOWER_COLOR} 0%, ${MOBILE_RANGE_SLIDER_LOWER_COLOR} ${item.volume * 100}%, ${MOBILE_RANGE_SLIDER_UPPER_COLOR} ${item.volume * 100}%, ${MOBILE_RANGE_SLIDER_UPPER_COLOR} 100%)`;
	}

	private setVisibility(userType: USER_TYPE, isChiefModerator: boolean , connectionId: string = null) {
		logger.log("---- translationService setVisibility -- ", userType, isChiefModerator, connectionId);
		const channels = this.streamChannels.filter(d => d.userTypes.includes(userType) && d.isChiefModerator === isChiefModerator);
		logger.log("---- translationService setVisibility -- channels: ", JSON.stringify(channels));
		if(channels) {
			channels.forEach(channel => {
				if (channel) {
					let count = 0;
					this.sessionService.connections.forEach(({ userMetaData }) => {
						if (userMetaData?.userType === userType && userMetaData?.isChiefModerator === isChiefModerator && (!connectionId || userMetaData?.connectionId !== connectionId))
							count++;
					});
					// show channel
					if (count > 0) {
						channel.hidden = false;
					} else
						channel.hidden = true;
				}
			});
		}
	}

	// handling stream volumes for iOS, as audio subscriptions are native there
	public toggleIOSChannel(id: number, volume: number | string) {
		logger.log("---- translationService toggleIOSChannel -- id: ", id, " volume: ", volume);
		// find
		const channel = this.streamChannels.find(d => !d.hidden && d.id === id);
		// toggle
		if (channel) {
			channel.volume = parseFloat(volume.toString());
			logger.log("---- translationService toggleIOSChannel -- channel: ", channel);
			this.setBackground(channel);
			// apply
			ScreenSharing.toggleIOSChannel({...channel});
		}
	}

	// remove subscriptions
	ngOnDestroy() {
		logger.log("---- translationService ngOnDestroy");
		this.subs.forEach(d => d?.unsubscribe());
		this.subs = [];
		this.streamSub = null;
		this.streams = [];
		this.isTranslatorPresent = false;
		this.translatorAvailableEventSubject.next(false);
		this.watchStreamsArray = [];
	}
}