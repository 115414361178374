import { Component, ViewEncapsulation, Input } from '@angular/core';

@Component({
	selector: 'app-loader',
	templateUrl: 'loader.component.html',
	styleUrls: ['loader.component.styl'],
	encapsulation: ViewEncapsulation.None
})
export class LoaderComponent {
	@Input() name;

	public fetchResource = fetchResource;
	constructor() { }

}
