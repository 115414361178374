import { Injectable, ApplicationRef, OnDestroy } from '@angular/core';
import { StreamService } from './stream.service';
import { SessionService } from './session.service';
import { MessageType, Message, USER_TYPE, StreamType, StreamEventType, CallOptions, OutgoingMessage, SessionMode, JoinRequestStatus, SessionDetails, SessionStatus, ToggleEventType, HOME_BUTTON, RecordingEventType, guideShownKey } from 'src/constants';
import { RequestService } from './request.service';
import { BehaviorSubject, Subscription, Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { TechCheckService } from './tech-check.service';
import { Stream } from 'openvidu-browser';

// capacitor imports
import { Plugins } from '@capacitor/core';
const { ScreenSharing, App, Browser } = Plugins;
import 'screen-sharing';
ScreenSharing.echo({ value: 'init' });

@Injectable({
	providedIn: 'root'
})
export class CommonService implements OnDestroy {

	// lower bar options toggle switches
	public micEnabled = false;
	public isMobileMicEnabled = false;
	public camEnabled = false;
	public disableMicButton = false;
	public disableCamButton = false;
	public disableScreenSharingButton = false;
	public screenSharingEnabled = false;

	// to prevent super frequent toggling
	private chatDisabled = false;
	private notesDisabled = false;
	private instructionsDisabled = false;
	private videoPanelDisabled = false;
	private bookmarkDisabled = false;
	private translationMenuDisabled = false;

	// chat and notes window toggles
	public chatInFocus: boolean = false;
	public notesInFocus: boolean = false;
	public instructionsInFocus: boolean = false;
	public videoPanelInFocus: boolean = true;
	public isRightPanelOpened: boolean = true;
	public bookmarkInFocus: boolean = false;
	public moreMenuInFocus: boolean = false;
	public translationMenuInFocus: boolean = false;
	public copyLinksInFocus: boolean = false;

	// mic, camera, screensharing buttons shown on screen
	public callOptions: CallOptions = {
		mic: false,
		cam: false,
		screen: false,
		endSession: false,
		notes: false
	};

	private audio: HTMLAudioElement = new Audio('assets/alerts/altair.ogg');

	public joiningKeyChangeSubject: Subject<string> = new Subject<string>();
	public joiningKeyChangeListener = this.joiningKeyChangeSubject.asObservable();

	private messageSub: Subscription;
	private invalidateUnhealthyConnectionsSub: Subscription;

	private toggleEventSubject: BehaviorSubject<ToggleEventType> = new BehaviorSubject<ToggleEventType>(null);
	public toggleEventListener = this.toggleEventSubject.asObservable();

	public instructions = '';
	public bookmarkCount = 0;
	public fullScreenMobile: boolean = false;	// for making video full screen (in mobile)
	public showVideos = false;

	public sessionStartTime: number;
	public sessionInWaiting = false;

	public joiningKey = null;

	public guideShown = false;

	public isFeedbackPopupOpen = false;

	public mobilePlaceholder = null;
	public placeholderStreams = [];
	public videoStreams = [];
	public mobileParticipantStreams = [];

	public teamLinksSubject = new BehaviorSubject<any>(null);
	public teamLinksListener = this.teamLinksSubject.asObservable();

	constructor(
		private streamService: StreamService,
		private sessionService: SessionService,
		private requestService: RequestService,
		private techCheckService: TechCheckService,
		private applicationRef: ApplicationRef) {

		// attach listener to listen to screen sharing event
		this.messageSub = this.sessionService.messageEventListener.subscribe((res) => {
			if (res?.type === MessageType.STOP_SCREEN_SHARING && this.screenSharingEnabled) {
				this.stopScreenSharing();
			}
			if (res?.type === MessageType.TESTER_NAME) {
				this.sessionService.testerName = res.msg;
				this.applicationRef.tick();
			}
		});

		this.invalidateUnhealthyConnectionsSub = this.sessionService.invalidateUnhealthyConnectionsEventListener.subscribe((res) => {
			switch(res) {
				case StreamType.AUDIO:
					this.stopMic();
					break;
				case StreamType.CAMERA:
					this.stopCam();
					break;
				case StreamType.SCREEN:
					this.stopScreenSharing();
					break;
				default:
					break;
			}
		});

		this.handleMobileStreamTermination();
	}

	public stopMic() {
		const type = StreamType.AUDIO;
		this.micEnabled = false;
		if (this.streamService.streams.has(type)) {
			const stream: MediaStream = this.streamService.streams.get(type);
			if (stream && stream.id) {
				// send remove stream message
				this.sessionService.streamEventSubject.next({
					type: StreamEventType.STREAM_REMOVED,
					streamId: stream.id,
					kind: StreamType.SELF
				});
			}
		}
		this.removeStream(type);
		this.disableMicButton = false;
		this.isMobileMicEnabled = false;
		this.applicationRef.tick();
	}

	public playNotification() {
		// keep it extremely low to be subtle and not distracting
		this.audio.volume = 0.05;
		this.audio.play();
		setFavicoIcon();
	}

	public fetchSessionDetails(joiningKey: string): Promise<SessionDetails> {
		return new Promise((resolve, reject) => {
			// fetch session details via api, it will return user type and session id
			this.requestService.verifySession(joiningKey).then(sessionInfo => {
				if (sessionInfo?.userType && Object.values(USER_TYPE).includes(sessionInfo.userType) && sessionInfo?.mediaSessionKey?.trim() != '' && typeof sessionInfo.chiefModerator !== 'undefined') {
					if (isMobile && sessionInfo.userType != USER_TYPE.OBSERVER && sessionInfo.userType != USER_TYPE.TESTER) {
						reject('Sorry you cannot join as a ' + sessionInfo.userType.capitalizeFirstLetter() + ' from a mobile device, please use the desktop link');
						return;
					}
					// save good invite key
					this.joiningKey = joiningKey;

					// set chief
					this.sessionService.isChiefModerator = sessionInfo.chiefModerator;

					// set instructions
					if (sessionInfo.instructions) {
						this.instructions = sessionInfo.instructions;
					}

					// set session start time if not now
					this.sessionStartTime = sessionInfo.sessionStartTime || 0;

					this.bookmarkCount = typeof sessionInfo.bookmarksCount !== 'undefined' ? sessionInfo.bookmarksCount : 0;

					this.teamLinksSubject.next(sessionInfo.links);

					// return user and session details
					resolve({
						userId: sessionInfo.userType,
						userType: sessionInfo.userType,
						sessionId: sessionInfo.mediaSessionKey,
						chiefModerator: sessionInfo.chiefModerator,
						links: sessionInfo.links
					});
				} else { reject('There seems to be some trouble at our server, please try again'); }
			}).catch(reject);
		});
	}

	public sendMessage(textMessage: Message, type: MessageType): Promise<any> {
		const data: OutgoingMessage = {
			msg: textMessage.msg,
			time: textMessage.time
		};
		return this.sessionService.sendMessage(data, type);
	}

	private addStream(type: StreamType): Promise<Stream> {
		return new Promise((resolve, reject) => {
			if (isMobile) {
				const fetch = () => {
					this.sessionService.fetchToken(type).then(({ token, iceServers }) => {
						logger.log('Fetching token');
						this.sessionService.processToken(token, iceServers).then(res => {
							if (type === StreamType.SCREEN && isIOS) {
								setTimeout(() => {
									logger.log('disableScreenSharingButton');
									this.disableScreenSharingButton = false;
								}, 5 * 1000);
							}
							ScreenSharing.startStream({ streamType: type, ...res }).then(() => {
								// send message to others to stop screen sharing
								type === StreamType.SCREEN && this.sessionService.sendMessage({ msg: MessageType.STOP_SCREEN_SHARING, time: Date.now() }, MessageType.STOP_SCREEN_SHARING);
								resolve(null);
							}).catch((err: Error) => {
								// only show error if call.reject has some message
								// in some cases, we don't want to display the alert on UI so will be sending blank string in message
								if (err.message.trim() != '') {
									alert(err.message);
								}
								reject(err);
							});
						}).catch(reject);
					}).catch(reject);
				};
				if (isIOS || type != StreamType.AUDIO) {
					fetch();
					return;
				}
			}
			this.streamService.addStream(type).then(stream => {
				// send message to others to stop screen sharing
				type === StreamType.SCREEN && this.sessionService.sendMessage({ msg: MessageType.STOP_SCREEN_SHARING, time: Date.now() }, MessageType.STOP_SCREEN_SHARING);
				// add to session
				// on ended event
				this.sessionService.addStream(stream, type).then((publishedStream) => {
					this.streamService.streams.set(type, publishedStream.getMediaStream());
					if (!this.streamService.allCapturedStreams.has(type)) {
						this.streamService.allCapturedStreams.set(type, []);
					}
					this.streamService.allCapturedStreams.get(type).push(publishedStream.getMediaStream());
					this.handleStreamTermination(type, publishedStream);
					resolve(publishedStream);
				}).catch((err) => { logger.log(err); reject(err); });
			}).catch(err => { logger.log(err); reject(err); });
		});
	}

	private removeStream(type: StreamType): void | Promise<void> {
		let result = null;
		logger.info('Removing stream of type: ', type);
		if (isMobile) {
			if (isIOS || type != StreamType.AUDIO) {
				result = ScreenSharing.stopStream({ streamType: type });
			}
		}
		this.sessionService.removeStream(type);
		this.streamService.removeStream(type);
		return result;
	}

	private handleStreamTermination(type: StreamType, stream: Stream) {
		const mediaStream = stream.getMediaStream();
		const tracks = mediaStream.getTracks();
		tracks.forEach(track => {
			track.onended = () => {
				logger.info('Track Ended of type: ', type);
				this.removeStream(type);
				switch (type) {
					case StreamType.AUDIO:
						this.micEnabled = false;
						this.isMobileMicEnabled = false;
						break;
					case StreamType.CAMERA:
						this.camEnabled = false;
						// send remove stream message
						this.sessionService.streamEventSubject.next({
							type: StreamEventType.STREAM_REMOVED,
							streamId: mediaStream.id,
							kind: StreamType.SELF,
						});
						break;
					case StreamType.SCREEN:
						this.screenSharingEnabled = false;
						break;
				}
				this.applicationRef.tick();
			};
		});
	}

	public endSession() {
		confirmBox({
			title: 'Attention',
			text: 'Completed the User interview?<br> Select <b>“Enter Debriefing”</b> for switching to internal meeting.<br><br> Select <b>"Quit Call"</b> instead, if you need to re-join the user interview.',
			icon: ICON.EXCLAMATION,
			cancelButtonColor: '#FF0000',
			confirmButtonColor: '#4685FF',
			cancelButtonText: 'Enter Debriefing',
			confirmButtonText: 'Quit Call',
			showCloseButton: true,
			reverseButtons: false,
			containerClass: 'endSessionBox'
		}).then(result => {
			if (result.isConfirmed) {
				this.endCall();
			} else if (result.isDismissed && result.dismiss.toString() == 'cancel'){
				this.requestService.endSesion(this.sessionService.sessionId)
					.then(() => this.sessionService.setSessionMode(SessionMode.DEBRIEFING))
					.catch(e => alert({ text: 'Error in terminating session:' + e, icon: ICON.ERROR }));
			}
		});
	}

	public endCall(dontChangeStatus = false) {
		this.sessionService.disconnect(dontChangeStatus);
		this.stopAllStreams();
	}

	public stopAllStreams() {
		this.sessionService.removeAllStreams();
		this.micEnabled = false;
		this.isMobileMicEnabled = false;
		this.camEnabled = false;
		this.screenSharingEnabled = false;
		isMobile && ScreenSharing.stopAllStreams();
	}

	public formatTimeStamp(timeStamp: number) {
		const format: any = { hour: "2-digit", minute: "2-digit", second: undefined, hourCycle: "h12" };
		return typeof timeStamp ===  'number' ? (new Date(timeStamp)?.toLocaleTimeString(navigator?.language, format)) : null;
	}

	// for tester only
	public sendJoinSessionRequest(): Promise<void> {
		const time = 1000 * 10;
		return new Promise((resolve, reject) => {
			if (this.sessionService.sessionStatus !== SessionStatus.SESSION_NOT_STARTED) {
				reject();
				return;
			}
			this.requestService.sendJoinSessionRequest(this.sessionService.sessionId, this.sessionService.name).then((res) => {
				switch (res.status) {
					case JoinRequestStatus.ALLOW:
						resolve();
						break;
					case JoinRequestStatus.REJECT:
						alert({ text: res?.msg ?? 'Sorry seems like you have the wrong link', icon: ICON.INFO });
						reject();
						break;
					case JoinRequestStatus.ROOM_FULL:
						alert(res.msg);
						reject();
					default:
					case JoinRequestStatus.PENDING:
						setTimeout(() => resolve(this.sendJoinSessionRequest()), time);
						break;
				}
			}).catch(err => {
				alert({ text: 'There seems to be some trouble at our server, please try again' });
				reject();
			});
		});
	}

	public fetchPendingRequest(): Promise<void> {
		const time = 1000 * 10;
		return new Promise((resolve, reject) => {
			if (this.sessionService.sessionStatus !== SessionStatus.SESSION_STARTED || !this.sessionService.sessionId?.trim()?.length) {
				reject();
				return;
			}
			this.requestService.fetchPendingRequest(this.sessionService.sessionId).then((res) => {
				if (res && res.name && res.status) {
					if (res.status === JoinRequestStatus.NONE) {
						setTimeout(() => resolve(this.fetchPendingRequest()), time);
					}
					else if (res.status === JoinRequestStatus.PENDING && res.name.trim() !== '') {
						this.sessionService.testerName = res.name.trim().capitalizeFirstLetter();
						this.applicationRef.tick();
						resolve();
					} else {
						reject();
					}
				} else {
					setTimeout(() => resolve(this.fetchPendingRequest()), time);
				}
			}).catch(() => reject());
		});
	}

	public allowJoinRequest(): Promise<void> {
		return new Promise((resolve, reject) => {
			this.requestService.allowJoinRequest(this.sessionService.sessionId, this.sessionService.testerName).then(() => {
				logger.log(">>>>>>>> allowJoinRequest setSessionMode: ", SessionMode.IN_SESSION);
				this.sessionService.setSessionMode(SessionMode.IN_SESSION);
				// update participant name
				this.sessionService.sendMessage({
					msg: this.sessionService.testerName,
					time: Date.now()
				}, MessageType.TESTER_NAME);
				resolve();
			}).catch(() => {
				alert({ text: this.sessionService.testerName + ' has left', icon: ICON.ERROR });
				this.sessionService.testerName = null;
				this.applicationRef.tick();
				reject();
			});
		});
	}

	public getInitialsFromName(name, delimeter = ' ') {
		if (name) {
			const array = name.split(delimeter);
			const intials = array[0].charAt(0).toUpperCase();
			if (array.length === 1) {
				return intials;
			}
			else {
				return intials + array[array.length - 1].charAt(0).toUpperCase();
			}
		}
		return false;
	}

	public downloadLogFile() {
		const blob = new Blob([JSON.stringify(logFile, null, 2)], { type: 'application/json' });
		const fileName = 'Moderated_log_' + this.sessionService.sessionId + '_' + this.sessionService.name + '_' + Date.now();
		const a = document.createElement('a');
		a.href = URL.createObjectURL(blob);
		a.download = fileName;
		a.click();
		a.remove();
	}


	public startRecording() {
		confirmBox({
			title: 'Start Recording?',
			text: 'Do you wish to start recording?',
			icon: ICON.START_RECORDING
		}).then(result => {
			if (result.isConfirmed) {
				this.sessionService.disableRecordingBtn = true;
				const showError = () => {
					alert('Sorry, Recording could not start, please try again');
					this.sessionService.disableRecordingBtn = false;
				};
				this.requestService.startRecording(this.sessionService.sessionId).then(res => {
					if (res.error) {
						logger.error('Error in starting recording:', res.msg);
						showError();
					} else {
						// if recording does not start in 1 minute -> show error and let the user try again
						setTimeout(() => {
							if (this.sessionService.recordingStatus == RecordingEventType.RECORDING_NOT_STARTED) {
								showError();
							}
						}, 1000 * 60);
					}
				}).catch(err => {
					logger.error('Error in starting recording:', err);
					showError();
				});
				this.applicationRef.tick();
			}
		});
	}

	public stopRecording() {
		confirmBox({
			title: 'Stop Recording?',
			text: 'You will not be able to restart the <br>recording in this Session.<br> Do you want to stop the recording?',
			icon: ICON.STOP_RECORDING,
			confirmButtonColor: '#FF0000'
		}).then(result => {
			if (result.isConfirmed) {
				this.sessionService.disableRecordingBtn = true;
				this.requestService.stopRecording(this.sessionService.sessionId)
					.catch(err => {
						logger.error('Error in stopping recording:', err);
						alert('Sorry, Recording could not start, please try again after few minutes');
						this.sessionService.disableRecordingBtn = false;
					});
				this.applicationRef.tick();
			}
		});
	}

	public stopScreenSharing() {
		if (isIOS) {
			const result = this.removeStream(StreamType.SCREEN);
			result instanceof Promise && result.then(() => {
				this.screenSharingEnabled = false;
			});
		} else {
			this.screenSharingEnabled = false;
			// send remove stream message
			this.removeStream(StreamType.SCREEN);
		}
	}

	public stopCam() {
		const type = StreamType.CAMERA;
		this.camEnabled = false;
		if (this.streamService.streams.has(type)) {
			const stream: MediaStream = this.streamService.streams.get(type);
			if (stream && stream.id) {
				// send remove stream message
				this.sessionService.streamEventSubject.next({
					type: StreamEventType.STREAM_REMOVED,
					streamId: stream.id,
					kind: StreamType.SELF
				});
			}
		}
		this.removeStream(type);
		this.disableCamButton = false;
		this.applicationRef.tick();
	}

	public handleBackButton() {
		if (isMobile) {
			if (isIOS) {
				ScreenSharing.addListener(HOME_BUTTON, (e) => {
					if (this.sessionService.sessionStatus != SessionStatus.SESSION_STARTED && !this.sessionInWaiting) {
						this.quitApp();
					}
				});
			} else {
				App.addListener('backButton', (e) => {
					if (this.sessionInWaiting || this.sessionService.sessionStatus == SessionStatus.SESSION_STARTED) {
						if (this.chatInFocus) {
							this.toggleChat();
						} else {
							this.sessionService.backgroundMode.moveToBackground();
							ScreenSharing.createNotification();
						}
					} else {
						this.quitApp();
					}
				});
			}
		}
	}

	private quitApp() {
		this.stopAllStreams();
		this.techCheckService.stopCheck();
		ScreenSharing.quitApp();
	}

	private handleMobileStreamTermination() {
		ScreenSharing.addListener(StreamEventType.STREAM_STOPPED, (data: { streamType: StreamType }) => {
			if (data && data.streamType) {
				switch (data.streamType) {
					case StreamType.AUDIO:
						this.micEnabled = false;
						this.isMobileMicEnabled = false;
						break;
					case StreamType.CAMERA:
						this.camEnabled = false;
						break;
					case StreamType.SCREEN:
						this.screenSharingEnabled = false;
						break;
				}
			}
		});
	}

	public openLink(link) {
		if (isMobile) {
			// Browser.open({ url: link, windowName: '_system', presentationStyle: 'fullscreen', toolbarColor: '#253645' });
			ScreenSharing.openInAppBrowser({url: link});
		}
		else {
			window.open(link, '_blank', 'noopener,noreferrer');
		}
	}

	public saveBookMark() {
		if (this.bookmarkDisabled || this.sessionService.disableBookmark) {
			return;
		}
		this.bookmarkDisabled = true;
		this.bookmarkCount++;
		this.bookmarkInFocus = true;
		// send timestamp to server
		this.requestService.saveBookmark(this.sessionService.sessionId, { timestamp: Date.now() }).catch(logger.error);
		// disable bookmark gif after
		setTimeout(() => {
			this.bookmarkInFocus = false;
			this.applicationRef.tick();
			this.bookmarkDisabled = false;
		}, 1500);
		this.applicationRef.tick();
	}

	public openFeedback() {
		this.isFeedbackPopupOpen = true;
		Swal.fire({
			input: 'textarea',
			inputPlaceholder: 'Please enter your message.....',
			showCancelButton: true,
			allowEscapeKey: true,
			allowEnterKey: true,
			allowOutsideClick: true,
			confirmButtonText: 'Submit',
			cancelButtonText: 'Close',
			customClass: {
				container: 'no-override'
			},
			showClass: {
				popup: '',
				icon: ''
			},
			hideClass: {
				popup: '',
			},
			heightAuto: false
		}).then((result: any) => {
			if (result.isConfirmed) {
				if (result.value && result.value.trim() != '') {
					this.requestService.sendFeedback(this.sessionService.sessionId, result.value, logFile);
					Swal.fire({
						text: 'Thank you for your feedback, this helps us in serving you better',
						customClass: {
							container: 'no-override reduced-height'
						},
						heightAuto: false
					});
				}
			} 
			this.isFeedbackPopupOpen = false;
		}).catch(() => { this.isFeedbackPopupOpen = false; });

	}

	public disablePowerSaverMode(): Promise<void> {
		return new Promise((resolve) => {
			try {
				if (isMobile) {
					let alertShown = false;
					const check = () => {
						ScreenSharing.isPowerSaveMode()
							.then(({ mode }) => {
								if (!mode) {
									dismissStickyPopup();
									resolve();
								} else {
									if (!alertShown) {
										stickyPopup('Please turn off battery saver mode in order to proceed');
										alertShown = true;
									}
									setTimeout(() => check(), 500);
								}
							}).catch(() => resolve());
					};
					check();
				} else {
					resolve();
				}
			} catch (e) {
				resolve();
			}
		});
	}

	public guideShownToUser() {
		localStorage.setItem(guideShownKey, 'true');
		this.guideShown = true;
	}


	/*** Menu and Stream Toggles ****/

	/** Mutes the mic without removing the stream from session, for easability and quick switch */
	public toggleMic() {
		if (this.disableMicButton) {
			return;
		}
		this.disableMicButton = true;
		const type = StreamType.AUDIO;
		if (this.isMobileMicEnabled || this.streamService.streams.has(type)) {
			this.micEnabled = !this.micEnabled;
			let breakChain = false;
			if (this.streamService.streams.has(type)) {
				const stream: MediaStream = this.streamService.streams.get(type);
				stream.getAudioTracks().forEach(track => {
					track.enabled = this.micEnabled;
					if (track.readyState == 'ended') {
						this.micEnabled = false;
						this.disableMicButton = false;
						this.removeStream(type);
						this.streamService.streams.delete(type);
						this.toggleMic();
						breakChain = true;
					}
				});
			}

			this.disableMicButton = false;

			if (breakChain) { return; }

			// TODO -> Mute functionality in iOS
			// this.micEnabled == true means mic is unmuted
			isIOS && ScreenSharing.setMute({ isMuted: !this.micEnabled });
			// send info of if mic is enabled to others, mute is opposite of micEnabled
			this.sessionService.sendMessage({ msg: (!this.micEnabled).toString(), time: Date.now() }, MessageType.MUTE_EVENT);
			this.applicationRef.tick();
		} else {
			this.micEnabled = true;
			this.isMobileMicEnabled = true;
			this.applicationRef.tick();
			this.addStream(type).then((stream: Stream) => {
				if (!this.micEnabled) {
					stream.getMediaStream().getAudioTracks().forEach(track => {
						track.enabled = false;
					});
				}
			}).catch(err => {
				logger.error(err);
				this.micEnabled = false;
				this.isMobileMicEnabled = false;
				this.applicationRef.tick();
				this.removeStream(type);
			}).finally(() => {
				this.disableMicButton = false;
				// send info of if mic is enabled to others, mute is opposite of micEnabled
				this.sessionService.sendMessage({ msg: (!this.micEnabled).toString(), time: Date.now() }, MessageType.MUTE_EVENT);
			});
		}
	}

	/**
	 * Adds/Removes the camera stream from session
	 */
	public toggleCam() {
		if (this.disableCamButton) {
			return;
		}
		this.disableCamButton = true;
		const type = StreamType.CAMERA;
		if (this.camEnabled) {
			this.stopCam();
		} else {
			this.camEnabled = true;
			this.applicationRef.tick();
			this.addStream(type).then((stream: Stream) => {
				const mediaStream = stream ? stream.getMediaStream() : null;
				if (this.camEnabled) {
					if (isMobile) {
						return;
					}

					if(mediaStream) {
						// send add stream message
						this.sessionService.streamEventSubject.next({
							type: StreamEventType.STREAM_ADDED,
							streamId: mediaStream.id,
							kind: StreamType.SELF,
							src: mediaStream
						});

						// if (this.sessionService.userType === USER_TYPE.TESTER) {
						// 	// take photo after 2 minutes
						// 	this.sessionService.takePhoto(mediaStream);
						// }
					}
				} else {
					if(mediaStream) {
						// button was pressed while stream was being created
						this.removeStream(type);
						// send remove stream message
						this.sessionService.streamEventSubject.next({
							type: StreamEventType.STREAM_REMOVED,
							streamId: mediaStream.id,
							kind: StreamType.SELF
						});
					}
				}
			}).catch(err => {
				logger.error(err);
				this.camEnabled = false;
				this.applicationRef.tick();
				this.removeStream(type);
			}).finally(() => {
				this.disableCamButton = false;
			});
		}
	}


	/**
	 * Adds/Removes the screen stream from session
	 */
	public toggleScreenSharing() {
		if (this.disableScreenSharingButton) {
			return;
		}
		this.disableScreenSharingButton = true;
		const type = StreamType.SCREEN;
		if (this.screenSharingEnabled) {
			this.stopScreenSharing();
			this.disableScreenSharingButton = false;
		} else {
			!isIOS && (this.screenSharingEnabled = true);
			this.applicationRef.tick();
			this.addStream(type).then(() => {
				isIOS && (this.screenSharingEnabled = true);
				if (!this.screenSharingEnabled) {
					// button was pressed while stream was being created
					this.removeStream(type);
				}
			}).catch(err => {
				logger.error('Error in sharing screen: ', err);
				this.screenSharingEnabled = false;
				this.applicationRef.tick();
				this.removeStream(type);
			}).finally(() => {
				this.disableScreenSharingButton = false;
			});
		}
	}

	public toggleChat(toShow: boolean = !this.chatInFocus) {
		if (toShow && this.chatDisabled) {
			return;
		}
		// disable usage untill action completed
		this.chatDisabled = true;

		// toggle self
		this.chatInFocus = toShow;
		this.videoPanelInFocus = false;
		// close translation menu
		if (this.chatInFocus && this.translationMenuInFocus)
			this.toggleTranslationMenu();
		// close copy links popup
		if (this.chatInFocus && this.copyLinksInFocus)
			this.toggleCopyLinks();

		// detect change
		this.applicationRef.tick();

		// open right panel if closed
		if (this.chatInFocus && !this.isRightPanelOpened) {
			this.toggleRightPanel(true);
		}

		// close right panel if opened
		if (!this.chatInFocus && this.isRightPanelOpened) {
			this.toggleRightPanel(false);
		}

		// notify listeners for focus event
		this.toggleEventSubject.next(ToggleEventType.CHAT);

		// enable usage after 500ms
		setTimeout(() => { this.chatDisabled = false; }, 500);
	}

	public toggleNotes(close = false) {
		// close and return
		if (close) {
			if (this.notesInFocus) {
				this.notesInFocus = false;
				this.applicationRef.tick();
			}
			return;
		}
		if (this.notesDisabled) {
			return;
		}
		// disable usage untill action completed
		this.notesDisabled = true;

		// toggle self
		this.notesInFocus = !this.notesInFocus;

		// close translation menu
		if (this.notesInFocus && this.translationMenuInFocus)
			this.toggleTranslationMenu();
		// close copy links popup
		if (this.notesInFocus && this.copyLinksInFocus)
			this.toggleCopyLinks();

		// detect change
		this.applicationRef.tick();

		// notify listeners for focus event
		this.toggleEventSubject.next(ToggleEventType.NOTES);

		// enable usage after 500ms
		setTimeout(() => { this.notesDisabled = false; }, 500);
	}

	public toggleInstructions(toShow: boolean = !this.instructionsInFocus) {
		if (toShow && this.instructionsDisabled) {
			return;
		}

		// disable usage untill action completed
		this.instructionsDisabled = true;

		// toggle self
		this.instructionsInFocus = toShow;

		// close translation menu
		if (this.instructionsInFocus && this.translationMenuInFocus)
			this.toggleTranslationMenu();
		// close copy links popup
		if (this.instructionsInFocus && this.copyLinksInFocus)
			this.toggleCopyLinks();

		// detect change
		this.applicationRef.tick();

		// notify listeners to reset html
		this.toggleEventSubject.next(ToggleEventType.INSTRUCTIONS);

		// enable usage after 500ms
		setTimeout(() => { this.instructionsDisabled = false; }, 500);
	}

	public toggleVideoPanel(toShow = !this.videoPanelInFocus) {
		if (toShow && this.videoPanelDisabled) {
			return;
		}

		// disable usage untill action completed
		this.videoPanelDisabled = true;

		// toggle self
		this.videoPanelInFocus = toShow;
		this.chatInFocus = false;

		// close translation menu
		if (this.videoPanelInFocus && this.translationMenuInFocus)
			this.toggleTranslationMenu();
		// close copy links popup
		if (this.videoPanelInFocus && this.copyLinksInFocus)
			this.toggleCopyLinks();

		// open right panel if closed
		if (this.videoPanelInFocus && !this.isRightPanelOpened) {
			this.toggleRightPanel(true, false);
		}

		// close right panel if opened
		if (!this.videoPanelInFocus && this.isRightPanelOpened) {
			this.toggleRightPanel(false);
		}

		// detect change
		this.applicationRef.tick();

		// enable usage after 500ms
		setTimeout(() => { this.videoPanelDisabled = false; }, 500);
	}

	public toggleMoreMenu(toShow = !this.moreMenuInFocus) {
		this.moreMenuInFocus = toShow;

		// close translation menu
		if (this.moreMenuInFocus && this.translationMenuInFocus)
			this.toggleTranslationMenu();
		// close copy links popup
		if (this.moreMenuInFocus && this.copyLinksInFocus)
			this.toggleCopyLinks();

		this.applicationRef.tick();
	}

	public toggleTranslationMenu(toShow = !this.translationMenuInFocus) {
		if (toShow && this.translationMenuDisabled) {
			return;
		}

		// disable usage untill action completed
		this.translationMenuDisabled = true;

		// toggle self
		this.translationMenuInFocus = toShow;

		// close copy links popup
		if (this.translationMenuInFocus && this.copyLinksInFocus)
			this.toggleCopyLinks();

		// detect change
		this.applicationRef.tick();

		// enable usage after 500ms
		setTimeout(() => { this.translationMenuDisabled = false; }, 500);
	}

	public toggleCopyLinks(toShow = !this.copyLinksInFocus) {
		// toggle self
		this.copyLinksInFocus = toShow;

		// close translation menu
		if (this.copyLinksInFocus && this.translationMenuInFocus)
			this.toggleTranslationMenu();

		// detect change
		this.applicationRef.tick();
	}

	public toggleRightPanel(toShow: boolean, toggleChat: boolean = true) {
		this.isRightPanelOpened = toShow;

		// close translation menu
		if (this.moreMenuInFocus && this.translationMenuInFocus)
			this.toggleTranslationMenu();
		// close copy links popup
		if (this.moreMenuInFocus && this.copyLinksInFocus)
			this.toggleCopyLinks();

		if (!isMobile && toggleChat) {
			this.toggleChat(toShow);
		} else
			this.applicationRef.tick();
	}

	ngOnDestroy() {
		this.messageSub?.unsubscribe();
		this.messageSub = null;
		this.invalidateUnhealthyConnectionsSub?.unsubscribe();
		this.invalidateUnhealthyConnectionsSub = null;
	}
}
