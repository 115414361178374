import { environment } from 'src/environments/environment';
import { Component, ViewEncapsulation } from '@angular/core';

@Component({
	selector: 'app-no-session',
	templateUrl: './no-session.component.html',
	styleUrls: ['./no-session.component.styl'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class NoSessionComponent {
	public fetchResource = fetchResource;
}
