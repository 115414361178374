import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GuideComponent } from './guide.component';

@NgModule({
    declarations: [
        GuideComponent
    ],
    imports: [
        CommonModule
    ],
    providers: [],
    exports: [
        GuideComponent
    ]
})

export class GuideModule { }
