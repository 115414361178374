<div class="chat-box">
    <div class="chat-box-header">
	</div>
    <div class="chat-box-content">
		<div class="chat-box-content-tab">
			<div class="chat-box-content-tab-item"
				*ngFor="let item of connectionService.activeChats; trackBy: trackByType; let i = index"
				(click)="toggle(i)"
				[ngClass]="activeChatIndex === i ? 'active' : 'inactive'"
				(appClickOutside)="item.isMenuOpen = false">
				<div class="chat-box-content-tab-item-heading">
					{{ item.title }}
					<!-- <span
						*ngIf="item.members.length"
						class="chat-box-content-tab-item-subHeading">
						({{item.members.length}})
					</span> -->
				</div>
				<div *ngIf="item.members.length"
					class="chat-box-content-tab-item-list">
					<div style="display: flex; align-items: center; gap: 0.2rem;" (mouseenter)="toggleMenu(item)" (mouseleave)="toggleMenu(item)">
						<app-svg name="person"></app-svg><span> {{item.members.length}}</span>
					</div>
					<div class="chat-box-content-tab-item-list-content"
						[class.hide]="!item.isMenuOpen">
						<div *ngFor="let member of item.members"
							class="chat-box-content-tab-item-list-content-item">
							<span class="chat-box-content-tab-item-list-content-item-thumbnail"
								[style.background]="member.color || '#988DE6'">
								{{ commonService.getInitialsFromName(member.alias) }}
							</span>
							<span class="chat-box-content-tab-item-list-content-item-name">{{ member.name }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="chat-box-content-body">
			<div *ngFor="let item of connectionService.activeChats; trackBy: trackByType; let i = index"
				class="chat-box-content-body-item"
				[ngStyle]="{'display':activeChatIndex === i ? 'block' : 'none' }">
				<div #messageBox class="chat-box-content-body-item-msg" [class.silent]="item.silent">
					<app-message 
						*ngFor="let msg of item.messages; trackBy: trackByTime;"
						[data]="msg"
						(resend)="resend(item, $event)">
					</app-message>
					<div class="chat-box-content-body-item-msg-placeholder"
						*ngIf="!item.messages.length">
						{{ item.silent ? 'No Activity!' : 'Start Conversation' }}
					</div>
				</div>
				<div class="chat-box-content-body-item-input" *ngIf="!item.silent" >
					<div class="chat-box-content-body-item-input-container">
						<textarea #textarea
							id="{{i}}"
							class="chat-box-content-body-item-input-input"
							[(ngModel)]="item.message"
							[attr.placeholder]="placeholderText"
							(keydown)="keyHandler($event, item)">
						</textarea>
						<app-svg class="chat-box-content-body-item-input-icon" name="send" (click)="sendMessage(item)" hideCircle=true>
						</app-svg>
					</div>
				</div>
			</div>
		</div>
    </div>
</div>