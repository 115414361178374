import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Component, ViewEncapsulation } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { resolve } from 'path';

@Component({
    selector: 'app-footer',
    template: `
        <div class="app-footer" *ngIf="!commonService.isFeedbackPopupOpen">
            <p>Have queries or faced trouble?
                <a (click)="commonService.openFeedback()">Write to us</a>
            </p>
            <p *ngIf="isMobile">v{{versionNumber}}</p>
        </div>
    `,
    styleUrls: ['./app-footer.component.styl'],
    encapsulation: ViewEncapsulation.ShadowDom
})

export class AppFooterComponent {
    public isMobile = isMobile;
    public versionNumber = '';

    constructor(public commonService: CommonService,
        private appVersion: AppVersion) { 
            this.getAppVersion();
        }

    public getAppVersion() {
        this.versionNumber = '';
        if (isMobile && this.versionNumber == '') {
            this.appVersion.getVersionNumber().then(v => {
                this.versionNumber = v;
            }).catch(e => {
                logger.log('Error in fetching version number');
            });
        }
    }
}

@NgModule({
    declarations: [
        AppFooterComponent
    ],
    imports: [
        CommonModule
    ],
    providers: [
        AppVersion
    ],
    exports: [
        AppFooterComponent
    ]
})

export class AppFooterModule { }
