import { Component, ViewEncapsulation } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { USER_TYPE } from '../../../../../../commonConstants';

@Component({
	selector: 'app-copy-links',
	templateUrl: './copy-links.component.html',
	styleUrls: ['./copy-links.component.styl'],
	encapsulation: ViewEncapsulation.ShadowDom
})
export class CopyLinksComponent {

	public showTeamRoleLinks: boolean = false;
	public teamLinks: any;
	public participantLink: string = null;

	constructor(public commonService: CommonService) {
		this.commonService.teamLinksListener.subscribe(links => {
			if(links) {
				const participantKey = USER_TYPE.TESTER.toLowerCase();
				this.participantLink = links[participantKey];
				delete links[participantKey];
				this.teamLinks = this.formatTeamLinks(links);
			}
		});
	}

	private formatTeamLinks(links) {
		let teamLinks = [];
		Object.keys(links).forEach(member => {
			teamLinks.push({
				member,
				link: links[member]
			});
		});
		return teamLinks;
	}

	private titleCase(str: string) {
		return str.toLowerCase().split(' ').map(function(word) {
			return (word.charAt(0).toUpperCase() + word.slice(1));
		}).join(' ');
	}

	public copyLink(item = null) {
		const cb = navigator.clipboard;
		let content: string = "";
		if(item) {
			// Copy particular link
			content = `${this.titleCase(item.member)}: ${item.link}`;
		} else {
			// Copy all links
			this.teamLinks.forEach(t => {
				content += `${this.titleCase(t.member)}: ${t.link} \n`;
			});
		}
		cb.writeText(content).then(() => {
			toast({ text: 'Link copied to clipboard', icon: ICON.INFO });
		});
	}
}
