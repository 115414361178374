import { ApplicationRef, Injectable, Injector, OnDestroy } from '@angular/core';
// capacitor imports
import { Plugins } from '@capacitor/core';
// ionic background mode plugin
import { BackgroundMode } from '@ionic-native/background-mode/ngx';
import { Connection, ConnectionEvent, OpenVidu, OpenViduError, Session, SessionDisconnectedEvent, SignalEvent, Stream, StreamEvent, Subscriber } from 'openvidu-browser';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import 'screen-sharing';
import { JoinRequestStatus, KICKED_OUT_TRUE, MessageType, SessionMode, StreamType, TERMINATED_FROM_YII, USER_TYPE } from '../../../../commonConstants';
import * as types from '../../constants';
import { RequestService } from './request.service';
import { StreamService } from './stream.service';
import { TerminateFromYiiService } from './terminate-from-yii.service';

const { ScreenSharing } = Plugins;

@Injectable({
	providedIn: 'root'
})
export class SessionService implements OnDestroy {

	constructor(
		private requestService: RequestService,
		private applicationRef: ApplicationRef,
		private streamService: StreamService,
		public backgroundMode: BackgroundMode,
		private injector: Injector) {
	}

	private userJoined: boolean = false;

	public showSessionModesPopup: boolean = false;

	// current session status
	public sessionStatus: types.SessionStatus = types.SessionStatus.SESSION_NOT_STARTED;
	// sends session status events
	private sessionStatusEventSubject: BehaviorSubject<types.SessionStatus> = new BehaviorSubject<types.SessionStatus>(this.sessionStatus);
	public sessionStatusEventListener = this.sessionStatusEventSubject.asObservable();

	// recording utils
	public recordingStatusText: string = '--:--';
	public recordingStatus: types.RecordingEventType = types.RecordingEventType.RECORDING_NOT_STARTED;
	private timer = null;
	public recordingStartTime = null;
	public disableRecordingBtn = false;
	public recordingURL = null;

	// session id recieved from backend
	public sessionId: string = null;

	// connectionId of self
	public connectionId: string = null;

	// current user type
	public userType: types.USER_TYPE = null;
	public userId: string;
	public teamLinks: any;
	// name of the current user
	public name: string = null;

	// session object
	private session: Session = null;
	private oldSession: Session = null;

	// map of Connection Id -> Connection object
	public connections = new Map<string, { connection: Connection, userMetaData: types.UserMetaData }>();

	// map of Connection Id -> Subscriber object
	private subscribers = new Map<string, Subscriber>();

	// map of stream type to publishers
	private publishers = new Map<types.StreamType, types.PublisherDetail>();
	private allPublishers = new Map<types.StreamType, types.PublisherDetail[]>();

	// peer joining/leaving events
	private connectionEventSubject: BehaviorSubject<types.ConnectionEventMessage> = new BehaviorSubject<types.ConnectionEventMessage>(null);
	public connectionEventListener = this.connectionEventSubject.asObservable();

	// stream addition/deletion events
	public streamEventSubject: BehaviorSubject<types.StreamEventMessage> = new BehaviorSubject<types.StreamEventMessage>(null);
	public streamEventListener = this.streamEventSubject.asObservable();

	// message recieved from peers
	private messageEventSubject: BehaviorSubject<types.Message> = new BehaviorSubject<types.Message>(null);
	public messageEventListener = this.messageEventSubject.asObservable();
	private messageEventSubscriber: Subscription;

	private invalidateUnhealthyConnectionsEventSubject: BehaviorSubject<StreamType> = new BehaviorSubject<StreamType>(null);
	public invalidateUnhealthyConnectionsEventListener = this.invalidateUnhealthyConnectionsEventSubject.asObservable();

	// recording status
	// private recordingEventSubject: BehaviorSubject<types.RecordingEventMessage> = new BehaviorSubject<types.RecordingEventMessage>(null);
	// public recordingEventListener = this.recordingEventSubject.asObservable();

	// session events
	// private sessionEventSubject: BehaviorSubject<types.SessionEventMessage> = new BehaviorSubject<types.SessionEventMessage>(null);
	// public sessionEventListener = this.sessionEventSubject.asObservable();

	// peer speaking event
	// private peerSpeakingEventSubject: BehaviorSubject<types.PeerSpeakingEventMessage> = new BehaviorSubject<types.PeerSpeakingEventMessage>(null);
	// public peerSpeakingEventListener = this.peerSpeakingEventSubject.asObservable();

	// session modes constants and observables
	public sessionMode: types.SessionMode = types.SessionMode.DEBRIEFING;
	private sessionModeChangeSubject: BehaviorSubject<types.SyncSession> = new BehaviorSubject<types.SyncSession>({ sessionMode: this.sessionMode });
	public sessionModeChangeListener: Observable<types.SyncSession> = this.sessionModeChangeSubject.asObservable();

	// previous chat, fetched from server
	public previousChats: { [dynamic: string]: types.SavedChat[] } = {};

	// timeout id of reconnect attempt
	private reconnectTimeout = null;

	// events session is subscribed to
	private events = [];

	// messages session is subscribed to
	private messageEventsSubscriptions = [];

	// exclude old connections of reconnected members
	private excludeConnections: Array<string> = [];
	public reconnecting = false;

	// indicator if current user is chief moderator
	public isChiefModerator = false;

	// recording success save message timeout
	private recordingTimeout = null;


	// participant image taken
	private participantPhotoTaken = false;
	private photoTimeout = null;

	// disable bookmark until recording starts
	public disableBookmark = true;
	// timeout for subscribing to audio in iOS
	private audioSubTimeout = null;

	private timeLimitTimeout = null;
	private timeLimit = null;
	private finalWarningTimeout = null;
	private startTime = null;
	private startTimeTimeout = null;
	private startTimeTimeoutFinal = null;

	public testerName: string = null;

	public setSessionDetails(details: types.SessionDetails) {
		this.userId = details.userId;
		this.userType = details.userType;
		this.sessionId = details.sessionId;
		this.teamLinks = details.links;
		this.applicationRef.tick();
	}

	public joinSession(): Promise<void> {
		return new Promise((resolve, reject) => {
			// fetch recording status
			this.updateRecordingStatus();
			this.connectToSession(null, true).then(() => {
				if (this.sessionStatus == types.SessionStatus.SESSION_ENDED) {
					this.disconnect();
					reject({ VOLUNTARY_DISCONNECTION: true });
					return;
				}

				// set session status
				this.sessionStatus = types.SessionStatus.SESSION_STARTED;
				this.sessionStatusEventSubject.next(this.sessionStatus);
				// for android
				this.enableBackgroundMode();
				this.connectionId = this.session.connection.connectionId;
				
				// subscribing to changes in session modes
				this.listenForSessionModeChanges();
				if (isIOS) {
					this.subscribeToNativeAudio();
				}

				// send status of self to server
				this.sendJoinStatus(true);

				// set self name as tester name
				if(this.userType === types.USER_TYPE.TESTER) {
					this.testerName = this.name;
				}
				// fetch recording status
				this.updateRecordingStatus();

				// TimeOut alert if session going on without participant for some defined time limit
				this.startTimeTimeout && clearTimeout(this.startTimeTimeout);
				this.startTimeTimeoutFinal && clearTimeout(this.startTimeTimeoutFinal);

				if(this.userType && this.userType !== types.USER_TYPE.TESTER && this.sessionMode !== types.SessionMode.IN_SESSION ) {
					this.requestService.fetchSessionStartTime(this.sessionId).then(res => {
						let startTime: number = null;
						if(res && res.connections) {
							startTime = res.connections.reduce((startTime: number, connection) => {
								if(startTime) return startTime;
								const { data } = connection.connectionProperties;
								if(data && JSON.parse(data).isChiefModerator) return connection.createdAt;
							}, null);
						}
						this.setSessionEndAlertTime(startTime);
					}).catch(() => { });
				}
				
				this.userJoined = true;
				resolve();
			}).catch(reject);
		});
	}

	public fetchToken(streamType: types.StreamType = null, userType: types.USER_TYPE = this.userType, sessionId = this.sessionId) {
		// data sent to joined peers
		const extraDataForPeers: types.PeerData = {
			userId: this.userId,
			streamType, name: this.name && this.name.trim() != '' ? this.name.capitalizeFirstLetter() : '',
			connectionId: this.connectionId,
			oldConnectionId: this.oldSession && this.oldSession.connection ? this.oldSession.connection.connectionId : null,
			isChiefModerator: this.isChiefModerator,
			isMobile
		};
		// fetch user token to connect to kms
		return this.requestService.fetchToken(userType, sessionId, extraDataForPeers);
	}

	private connectToSession(streamType: types.StreamType = null, handleEvents = false): Promise<{ server: OpenVidu, session: Session, mode: types.SessionMode }> {
		return new Promise((resolve, reject) => {

			// fetch user token to connect to kms
			this.fetchToken(streamType).then((res) => {

				// in case participant is somehow allowed in debriefing mode, change mode
				if (res.mode === types.SessionMode.DEBRIEFING && this.userType === types.USER_TYPE.TESTER) {
					res.mode = types.SessionMode.IN_SESSION;
				}

				if (res.chats && Object.keys(res.chats).length) {
					this.previousChats = res.chats;
				}

				// new object of open vidu needs to be created each time a session is created
				// https://github.com/OpenVidu/openvidu/issues/61
				// init media server sdk
				const server = new OpenVidu();

				// may solve reconnecting problems or worsen them, use with caution
				server.setAdvancedConfiguration({
					forceMediaReconnectionAfterNetworkDrop: true
				});

				// init session
				const session = server.initSession();
				if (handleEvents) {
					this.session = session;
					this.handleEvents();
				}

				if(res?.testerName?.trim()?.length) {
					this.testerName = res.testerName.trim();
				}

				// connect to kms
				session.connect(res.token).then((e) => {
					resolve({ server, session, mode: res.mode });
				}).catch((err: OpenViduError) => {
					logger.log('Error in joining session: ', err);
					this.requestService.forceDisconnection(session.sessionId, res.connectionId).then(() => {
						logger.log('FORCE_DISCONNECTION => connectionId ', res.connectionId, ' has been succesfully disconnected from sessionId ', session.sessionId);
					}).catch((err) => {
						logger.log('FORCE_DISCONNECTION => Error while removing connectionId ', res.connectionId , ' from sessionId ', session.sessionId, ' Error: ', err);
					})
					reject();
				});
			}).catch(err => {
				logger.log('Error fetching token: ', err);
				reject();
			});	
		});
	}

	private handleEvents() {
		this.events = [];
		// handles connection events -> https://docs.openvidu.io/en/2.14.0/api/openvidu-browser/classes/connectionevent.html
		this.handleConnectionEvents();

		// handles session events -> https://docs.openvidu.io/en/2.14.0/api/openvidu-browser/classes/sessiondisconnectedevent.html
		this.handleSessionEvents();

		// Handles stream events -> https://docs.openvidu.io/en/2.14.0/api/openvidu-browser/classes/streamevent.html
		this.handleStreamEvents();

		// handles recording events -> https://docs.openvidu.io/en/2.14.0/api/openvidu-browser/classes/recordingevent.html
		this.handleRecordingEvents();
	}

	private handleConnectionEvents() {
		// handles connection events -> https://docs.openvidu.io/en/2.14.0/api/openvidu-browser/classes/connectionevent.html

		const sendOnConnect = (msg: types.ConnectionEventMessage, silent = false) => {
			if (this.sessionStatus === types.SessionStatus.SESSION_STARTED) {
				this.connectionEventSubject.next({
					...msg,
					silent
				});
			} else {
				setTimeout(() => sendOnConnect(msg, true), 500);
			}
		};

		let event = 'connectionCreated';
		this.events.push(event);
		// peer joined the session
		this.session.on(event, (e: ConnectionEvent) => {
			// object holding info about peer
			const connection: Connection = e.connection;
			// unique id of connection across media server
			const connectionId: string = connection.connectionId;
			try {
				// data we passed when joining session
				const data: types.UserMetaData = JSON.parse(connection.data);
				if (this.isSelf(data.connectionId || connectionId) || !data.userType || data.userType == types.USER_TYPE.SUBSCRIBER) {
					return;
				}

				// push into exclusion list
				if (data.oldConnectionId && data.oldConnectionId != '') {
					this.excludeConnections.push(data.oldConnectionId);
				}
				this.removeExcludedConnections();

				if (this.excludeConnections.includes(connectionId)) {
					return;
				}
				// don't create connection for stream only connection
				if (data.streamType) {
					return;
				}
				logger.log('New Connection created: ', connection);

				// create new entry
				this.connections.set(connectionId, {
					connection,
					userMetaData: {
						userType: data.userType,
						userId: data.userId,
						name: data.name,
						connectionId,
						streamType: null,
						isChiefModerator: data.isChiefModerator,
						isMobile: data.isMobile
					}
				});
				// send event details to listeners
				sendOnConnect({
					type: types.ConnectionEventType.CONNECTION_CREATED,
					data: this.connections.get(connectionId)?.userMetaData
				});
			} catch (e) {
				logger.log('Data not parsable as JSON: ', connection.data);
			}
		});

		event = 'connectionDestroyed';
		// peer left the session
		this.session.on(event, (e: ConnectionEvent) => {
			// object holding info about peer
			this.handleDisconnection(e.connection);
		});
	}

	private handleSessionEvents() {
		/*
		 * Handles session events -> https://docs.openvidu.io/en/2.14.0/api/openvidu-browser/classes/sessiondisconnectedevent.html
		 * Reconnecting and Reconnectd Events
		*/
		let event = 'reconnecting';
		this.events.push(event);
		// connection lost -> reconnecting to session
		this.session.on(event, (e: SessionDisconnectedEvent) => {
			// this.sessionEventSubject.next({ type: types.SessionEventType.RECONNECTING });
			if (this.sessionStatus === types.SessionStatus.SESSION_ENDED) {
				this.disconnect();
			} else {
				toast({ text: 'Poor network, Reconnecting....', icon: ICON.INFO }, null);
			}
		});

		event = 'reconnected';
		this.events.push(event);

		// connection recreated -> reconnected to session
		this.session.on(event, (e: SessionDisconnectedEvent) => {
			// this.sessionEventSubject.next({ type: types.SessionEventType.RECONNECTED });
			if (this.sessionStatus === types.SessionStatus.SESSION_ENDED) {
				this.disconnect();
			} else {
				toast({ text: 'Reconnected', icon: ICON.INFO });
			}
		});

		event = 'sessionDisconnected';
		this.events.push(event);
		// current user got disconnected
		this.session.on(event, (e: SessionDisconnectedEvent) => {
			const session: any = e.target;
			logger.log('~~~~~~~~~~~~~ Session disconnnected', session);
			if (session.sessionId != this.session.sessionId) {
				return;
			}
			const reason: string = e.reason;
			let type: types.SessionEventType = null;
			logger.log('~~~~~~~~~~~~~ Disconnected from session, reason:', reason);
			switch (reason) {
				case 'networkDisconnect': {
					type = types.SessionEventType.NETWORK_DISCONNECTION;
					this.reconnect();
				}
					                         break;
				case 'forceDisconnectByUser':
					if (this.userType === types.USER_TYPE.TESTER) {
						alert({ text: 'The Session has completed. Thanks for your particpation in this Research', icon: ICON.INFO });
					}
				// case 'forceDisconnectByServer':
				// case 'sessionClosedByServer':
				// case 'disconnect':
				default: {
					type = types.SessionEventType.VOLUNTARY_DISCONNECTION;
					this.sessionStatus = types.SessionStatus.SESSION_ENDED;
					this.sessionStatusEventSubject.next(this.sessionStatus);
					this.disableBackgroundMode();
					this.removeAllStreams();
					dismissToast();
				}
					break;
			}
			// if (type) this.sessionEventSubject.next({ type });
		});

		const eventTerminatedFromYii = TERMINATED_FROM_YII;
		this.events.push(eventTerminatedFromYii);
		this.session.on(eventTerminatedFromYii, (e) => {
			logger.log('Session was terminated from Yii.');
			this.disconnect();
			this.removeAllStreams();
			const terminateFromYiiService = this.injector.get(TerminateFromYiiService);
			terminateFromYiiService.terminateFromYii();
		});
	}

	private handleStreamEvents() {
		// Handles stream events -> https://docs.openvidu.io/en/2.14.0/api/openvidu-browser/classes/streamevent.html
		// Stream -> https://docs.openvidu.io/en/2.14.0/api/openvidu-browser/classes/stream.html

		let event = 'streamCreated';
		this.events.push(event);
		// stream added by peer
		this.session.on(event, (e: StreamEvent) => {
			const stream: Stream = e.stream;
			let kind: types.StreamType = null;
			let connectionId: string = null;
			let userType: types.USER_TYPE = null;
			let self = false;
			let data: types.UserMetaData = null;
			if (stream.connection.data && stream.connection.data !== '') {
				try {
					data = JSON.parse(stream.connection.data);
					// check if stream is of self 
					if (this.isSelf(data.connectionId || stream.connection.connectionId)) {
						// allow if on mobile and cam stream
						// in desktop, camera stream is directly feeded to UI locally
						// in mobile native cam stream is used so can't be streamed locally, it is streamed via a loopback from server
						if (isMobile && data?.streamType === types.StreamType.CAMERA) {
							self = true;
						} else {
							return;
						}
					}
					// do not subscribe to audio streams on ios, they are subscribed via native webrtc, -> code in plugin.swift
					if (isIOS && data.streamType == types.StreamType.AUDIO) {
						logger.log("iOS Audio Stream: ", JSON.stringify(data));
						return;
					}

					// // ignore moderator cam for mobile participant
					// if (isMobile &&
					// 	this.userType === types.USER_TYPE.TESTER &&
					// 	data?.userType === types.USER_TYPE.MODERATOR &&
					// 	data?.streamType === types.StreamType.CAMERA) {
					// 	return;
					// }

					// if current user is participant, then only subscribe to moderator and translator streams to prevent accidental leekage
					if(!self && this.userType === types.USER_TYPE.TESTER && !(data.userType === types.USER_TYPE.MODERATOR || data.userType === types.USER_TYPE.TRANSLATOR))
						return;

					if (data.streamType && data.connectionId) {
						kind = data.streamType;
						// connection id of original connection, this id is the identifer for one connection and all his streams related connection
						connectionId = data.connectionId;
						userType = data.userType;
					}
				} catch (e) { logger.log('Data not json parsable:', stream.connection.data); }
			}

			const subscribe = (retry = 0) => {
				// have to subscribe in order to recieve the stream
				this.session.subscribeAsync(stream, null).then((subscriber: Subscriber) => {
					this.subscribers.set(stream.connection.connectionId, subscriber);

					// sending stream to view
					const src: MediaStream = stream.getMediaStream();
					const streamId = stream.streamId;
					if (self) {
						this.streamEventSubject.next({
							type: types.StreamEventType.STREAM_ADDED,
							streamId,
							kind: types.StreamType.SELF,
							src
						});

						// this.takePhoto(src);
					} else if (kind && connectionId && userType) {
						this.streamEventSubject.next({
							type: types.StreamEventType.STREAM_ADDED,
							streamId,
							kind,
							src,
							from: { connectionId, name: '', userType, ...data },
							actualConnectionID: stream.connection.connectionId
						});
					}
				}).catch(err => {
					if (retry > 5) {
						logger.log('Max retry hit, dropping subscription');
						return;
					} else {
						logger.log('Unable to subscribe, trying again:', err);
						if (!stream.connection.disposed) {
							setTimeout(() => subscribe(++retry), 500);
						}
					}
				});
			};
			subscribe();
		});

		event = 'streamDestroyed';
		// stream removed by peer
		this.session.on(event, (e: StreamEvent) => {
			if (e.reason == 'forceDisconnectByServer') {
				const data = JSON.parse(e.stream.connection.data);
				if(data.connectionId == this.connectionId) {
					this.invalidateUnhealthyConnectionsEventSubject.next(data.streamType);
				}
			}

			const stream: Stream = e.stream;
			const streamId = stream.streamId;
			try {
				const subscriber = this.subscribers.get(stream.connection.connectionId);
				if(subscriber)
					this.session.unsubscribe(subscriber);
			} catch (e) {

			}
			this.subscribers.delete(stream.connection.connectionId);
			let kind: types.StreamType = null;
			let self = false;
			if (stream.connection.data && stream.connection.data !== '') {
				try {
					const data: types.UserMetaData = JSON.parse(stream.connection.data);
					if (this.isSelf(data.connectionId || stream.connection.connectionId)) {
						if (isMobile && data && data.streamType && data.streamType === types.StreamType.CAMERA) {
							self = true;
						} else {
							return;
						}
					}
					if (data.streamType) {
						kind = data.streamType;
					}
				} catch (e) { }
			}
			if (kind) {
				if (self) {
					this.streamEventSubject.next({
						type: types.StreamEventType.STREAM_REMOVED,
						streamId,
						kind: types.StreamType.SELF
					});
				} else {
					this.streamEventSubject.next({
						type: types.StreamEventType.STREAM_REMOVED,
						kind,
						streamId,
						actualConnectionID: stream.connection.connectionId
					});
				}
			}
		});

		// event = 'streamDestroyed';
		// this.events.push(event);
		// // stream property changes, like mute, rotation, cam/screen on/off, https://docs.openvidu.io/en/2.14.0/api/openvidu-browser/classes/streampropertychangedevent.html
		// this.session.off('streamPropertyChanged').on('streamPropertyChanged', (e: StreamPropertyChangedEvent) => {
		// 	const stream = e.stream;
		// 	if (this.isSelf(stream.connection.connectionId))
		// 		return;
		// 	const changedProperty = e.changedProperty;
		// 	const oldValue = e.oldValue;
		// 	const newValue = e.newValue;
		// 	// switch (changedProperty) {
		// 	// 	case 'videoActive':
		// 	// 	case 'audioActive':
		// 	// 	case 'videoDimensions':
		// 	// 	case 'filter':
		// 	// }
		// 	logger.log('changedProperty: ', changedProperty, 'oldValue: ', oldValue, 'newValue: ', newValue);
		// });

		// PublisherSpeakingEvent -> https://docs.openvidu.io/en/2.14.0/api/openvidu-browser/classes/publisherspeakingevent.html, https://docs.openvidu.io/en/2.14.0/api/openvidu-browser/classes/streammanager.html#updatepublisherspeakingeventsoptions
		// peer has started speaking
		// this.session.on('publisherStartSpeaking', (e: PublisherSpeakingEvent) => {
		// 	const connectionId = e.connection.connectionId;
		// 	this.peerSpeakingEventSubject.next({
		// 		type: types.PeerSpeakingEventType.STARTED_SPEAKING,
		// 		connectionId
		// 	});
		// });
		// // peer has stopped speaking
		// this.session.on('publisherStartSpeaking', (e: PublisherSpeakingEvent) => {
		// 	const connectionId = e.connection.connectionId;
		// 	this.peerSpeakingEventSubject.next({
		// 		type: types.PeerSpeakingEventType.STOPPED_SPEAKING,
		// 		connectionId
		// 	});
		// });
	}

	public handleMessageEvents(signalType: types.MessageType) {
		// handles message events, called signal events -> https://docs.openvidu.io/en/2.14.0/api/openvidu-browser/classes/signalevent.html
		this.messageEventsSubscriptions.push(signalType);
		const event = `signal:${signalType}`;
		this.events.push(event);
		this.session && this.session.on(event, (e: SignalEvent) => {
			if (e.data && !this.isSelf(e.from.connectionId)) {
				try {
					const data: types.OutgoingMessage = JSON.parse(e.data);
					// if (types.checkMessageIntegrity(data)) {
					const msg = data.msg;
					const from: types.ChatRoomMember = {
						connectionId: e.from.connectionId,
						name: '',
						userType: null,
						userId: null,
						isChiefModerator: false,
						isMobile: false,
						streamType: null
					};
					const time = data.time;

					this.messageEventSubject.next({
						from,
						msg,
						time,
						type: signalType
					});
				} catch (e) {
					console.log('Received message not in json format: ', e.data);
				}
			}
		});
	}

	private handleRecordingEvents() {
		// handles recording events -> https://docs.openvidu.io/en/2.14.0/api/openvidu-browser/classes/recordingevent.html

		// RECORDING STARTED
		let event = types.RecordingEventType.RECORDING_STARTED_SIGNAL;
		this.events.push(event);

		// event from openvidu or session manager
		this.session.on(event, (e: any) => {
			if (this.recordingStatus == types.RecordingEventType.RECORDING_NOT_STARTED) {
				logger.log('Recording Started');
				let startTime = Date.now();
				if (typeof e.data !== 'undefined') {
					try {
						const data = JSON.parse(e.data);
						if (typeof data.createdAt !== 'undefined') {
							startTime = data.createdAt;
							logger.log('-------START TIME-----', startTime);
						}
					} catch (exception) {

					}
				}
				this.setRecordingStatus(types.RecordingEventType.RECORDING_STARTED, startTime);
				toast('Recording has started');
			}
		});

		// RECORDING STOPPED
		event = types.RecordingEventType.RECORDING_STOPPED_SIGNAL;
		this.events.push(event);

		// event from openvidu or session manager
		this.session.on(event, () => {
			if (this.recordingStatus == types.RecordingEventType.RECORDING_STARTED) {
				logger.log('Recording Stopped');
				this.setRecordingStatus(types.RecordingEventType.RECORDING_STOPPED);
				toast('Recording has stopped');
				if (this.isChiefModerator && !this.recordingTimeout) {
					// show fake message as recording saved, in 3 seconds
					const time = Math.floor((Math.random() * 4) + 2) * 1000;
					this.recordingTimeout = setTimeout(() => toast('Recording saved successfully', 2000), time);
				}
			}
		});
	}

	private setRecordingStatus(recordingStatus: types.RecordingEventType, recordingStartTime = Date.now()) {
		let startTimer = false;
		this.disableBookmark = true;
		if (recordingStatus === types.RecordingEventType.RECORDING_STARTED) {
			startTimer = true;
			this.disableBookmark = false;
		}

		this.disableRecordingBtn = false;
		// this.recordingEventSubject.next({ type: recordingStatus });
		this.recordingStatus = recordingStatus;
		this.toggleTimer(startTimer, recordingStartTime);
		this.applicationRef.tick();
	}

	public sendMessage(msg: types.OutgoingMessage, type: types.MessageType): Promise<void> {
		return new Promise((resolve, reject) => {
			if (!this.session) {
				reject();
				return;
			}
			logger.log('Sending message via session: ', this.session.sessionId, 'msg', msg);
			const message = {
				data: JSON.stringify(msg),
				type
			};
			this.session.signal(message).then(() => {
				// only include these message types to get saved at server
				const includeMessageTypes = [
					types.MessageType.TEAM,
					types.MessageType.TESTER_MODERATOR
				];
				if (this.sessionMode === types.SessionMode.IN_SESSION && includeMessageTypes.includes(type)) {
					let userId: string = this.userType;
					if(!this.isChiefModerator && this.userType == USER_TYPE.MODERATOR) {
						userId = 'Co-Facilitator';
					}
					const chat: types.SavedChat = {
						userName: this.name,
						userRole: this.userType,
						userId: userId,
						messageType: type,
						timestamp: msg.time,
						comment: msg.msg,
						connectionId: this.connectionId
					};
					this.requestService.saveChat(this.sessionId, chat);
				}
				resolve();
			}).catch(() => reject());
		});
	}

	public addStream(stream: MediaStream, type: types.StreamType): Promise<Stream> {
		logger.log('Adding stream of type: ', type);
		return new Promise((resolve, reject) => {
			if (this.session) {

				// remove previous streams
				this.removeStream(type);

				// fetching media tracks
				const mediaTracks: Array<MediaStreamTrack> = stream ? (type === types.StreamType.AUDIO ? stream.getAudioTracks() : stream.getVideoTracks()) : null;
				// connecting to the session as a new user
				this.connectToSession(type).then(({ server, session }) => {

					// initalizing a publisher object to attach to session, ref - https://docs.openvidu.io/en/2.14.0/api/openvidu-browser/classes/openvidu.html#initpublisher, https://docs.openvidu.io/en/2.14.0/api/openvidu-browser/interfaces/publisherproperties.html

					server.initPublisherAsync(null, {
						audioSource: type === types.StreamType.AUDIO && mediaTracks.length ? mediaTracks[0] : false,
						videoSource: type == types.StreamType.SCREEN ? "screen" : type == types.StreamType.CAMERA && mediaTracks.length ? mediaTracks[0] : false,
						publishVideo: type != types.StreamType.AUDIO,
						publishAudio: type === types.StreamType.AUDIO,
					}).then(publisher => {

						const publisherDetail = {
							publisher,
							session
						};
						this.publishers.set(type, publisherDetail);

						// add all publishers to a list in case of double subscriptions
						if (!this.allPublishers.has(type)) {
							this.allPublishers.set(type, []);
						}
						this.allPublishers.get(type).push(publisherDetail);

						// don't let openvidu destroy streams, we'll do it ourselves
						session.on('sessionDisconnected', (e: SessionDisconnectedEvent) => {
							if (e.reason != 'forceDisconnectByUser') {
								e.preventDefault();
							}
						});
						publisher.on('streamDestroyed', (e: StreamEvent) => {
							if (e.reason != 'forceDisconnectByUser') {
								e.preventDefault();
							}
						});

						// publishing the publisher onto stream, ref - https://docs.openvidu.io/en/2.14.0/api/openvidu-browser/classes/session.html#publish
						session.publish(publisher).then(() => {
							// remove duplicates
							// this.removeStream(type, true);
							resolve(publisher.stream);
						}).catch(err => {
							const error = 'Error in publishing publisher: ' + err.message;
							this.removeStream(type);
							logger.error(error);
							reject(error);
						});
					}).catch(err => {
						const error = 'Error in adding publisher: ' + err.message;
						logger.log(error);
						reject(error);
					});
				}).catch(reject);
			} else {
				const error = 'Open vidu or session not initalized';
				logger.log(error);
				reject(error);
			}
		});
	}

	public removeStream(type: types.StreamType) {
		if (this.allPublishers.has(type)) {
			[...this.allPublishers.get(type), this.publishers.get(type)].forEach(publisherDetail => {
				if (publisherDetail && publisherDetail.publisher && publisherDetail.session) {
					try {
						// unpublishes a publisher - https://docs.openvidu.io/en/2.14.0/api/openvidu-browser/classes/session.html#unpublish
						const session = publisherDetail.session;
						logger.log('Disconnecting session: ', session.sessionId);
						const publisher = publisherDetail.publisher;
						session.unpublish(publisher);
						session.disconnect();
					} catch (e) {

					}
				} else if (publisherDetail && publisherDetail.session) {
					publisherDetail.session.disconnect();
				}
			});
			this.allPublishers.set(type, []);
			this.publishers.delete(type);
		}
	}

	private isSelf(connectionId: string) {
		logger.log('Checking if connection: ', connectionId, ' is self: ', this.session.connection.connectionId);
		const selfConnectionIds = [this.session.connection.connectionId];
		if (this.oldSession && this.oldSession.connection && this.oldSession.connection.connectionId) {
			selfConnectionIds.push(this.oldSession.connection.connectionId);
		}
		const val = selfConnectionIds.includes(connectionId);
		if (val) {
			logger.log('Connection is self, ignoring...');
		}
		else {
			logger.log('Connection is remote, Adding/Removing...');
		}
		return val;
	}

	public disconnect(dontChangeStatus = false) {
		if (!dontChangeStatus) {
			// send status of self to server
			if (this.sessionStatus == types.SessionStatus.SESSION_STARTED) {
				this.sendJoinStatus(false);
			}
			this.sessionStatus = types.SessionStatus.SESSION_ENDED;
			this.sessionStatusEventSubject.next(this.sessionStatus);
		}
		this.disableBackgroundMode();
		dismissToast();
		this.reconnectTimeout && clearTimeout(this.reconnectTimeout);
		this.timeLimitTimeout && clearTimeout(this.timeLimitTimeout);
		this.finalWarningTimeout && clearTimeout(this.finalWarningTimeout);
		this.startTimeTimeout && clearTimeout(this.startTimeTimeout);
		this.startTimeTimeoutFinal && clearTimeout(this.startTimeTimeoutFinal);
		this.recordingTimeout && clearTimeout(this.recordingTimeout);
		this.photoTimeout && clearTimeout(this.photoTimeout);
		this.session && this.session.disconnect();
		this.removeAllStreams();
		this.unSubscribeToNativeAudio();
	}

	public removeAllStreams() {
		this.publishers.forEach((value, type) => {
			this.removeStream(type);
		});
		this.streamService.stopAllStreams();
	}

	private toggleTimer(start = false, startTime = Date.now()) {
		if (start) {
			this.recordingStartTime = startTime;
			this.timer && clearInterval(this.timer);
			this.timer = setInterval(() => {
				this.recordingStatusText = this.getTimeDifference(Date.now(), this.recordingStartTime);
				this.applicationRef.tick();
			}, 1000);
		} else {
			this.timer && clearInterval(this.timer);
			this.timer = null;
			this.recordingStatusText = '--:--';
			this.applicationRef.tick();
		}
	}

	private listenForSessionModeChanges() {
		this.requestService.getJoinStatus(this.sessionId).then((response) => {
			const joinRequest = response.joinRequest;
			const terminated = response.terminated;
			if(!terminated && joinRequest.status === JoinRequestStatus.ALLOW && joinRequest.requesteeName) {
				logger.log("in case participant has been allowed earlier for this session but participant couldn't join because the session was not started yet and participant kills the app, then once the session is started change the mode to IN SESSION for every user which joins later before participant actually joins");
				logger.log(">>>>>>>> getJoinStatus setSessionMode: ", SessionMode.IN_SESSION);
				this.setSessionMode(SessionMode.IN_SESSION);
				// update participant name
				this.testerName = joinRequest.requesteeName;
				this.sendMessage({
					msg: this.testerName,
					time: Date.now()
				}, MessageType.TESTER_NAME);
			}
		}).catch(() => {
			// request for current session mode from server (not fetching it from peers because in case of no peer available it was always returning Briefing mode that was wrong)
			this.session && this.session.sessionId && this.requestService.getSessionMode(this.sessionId, this.session.sessionId).then((res) => {
				logger.log(">>>>>>>> listenForSessionModeChanges setSessionMode: ", res.mode);
				res && res.mode && this.setSessionMode(res.mode, false, false);
			});
		});

		// handle message data
		this.messageEventSubscriber && this.messageEventSubscriber.unsubscribe();
		this.messageEventSubscriber = this.messageEventListener.subscribe(data => {
			switch(data?.type) {
				case types.MessageType.SESSION_MODE:
					try {
						const msg: types.SyncSession = JSON.parse(data.msg);
						logger.log(">>>>>>>> messageEventSubscriber setSessionMode: ", msg.sessionMode);
						this.setSessionMode(msg.sessionMode, false, false);
					} catch (e) {
						logger.error('Data not JSON parsable', data.msg);
					}
					break;
				case types.MessageType.REQUEST_SESSION_MODE:
					this.sendMessage({ msg: JSON.stringify({ sessionMode: this.sessionMode, supressAlert: false }), time: null }, types.MessageType.SESSION_MODE);
					break;
			}
		});

		this.handleMessageEvents(types.MessageType.SESSION_MODE);
		this.handleMessageEvents(types.MessageType.MUTE_EVENT);
		this.handleMessageEvents(types.MessageType.STOP_SCREEN_SHARING);
		this.handleMessageEvents(types.MessageType.INSTRUCTIONS_CHANGE);
		this.handleMessageEvents(types.MessageType.TESTER_NAME);
		this.handleMessageEvents(types.MessageType.REQUEST_SESSION_MODE);
	}

	public setSessionMode(mode: types.SessionMode, supressAlert = false, sendToServer = true) {
		const msg = { sessionMode: mode, supressAlert };
		// disable bookmark, notes after session ends
		if (mode === types.SessionMode.DEBRIEFING) {
			this.disableBookmark = true;
		}

		// Clear Session Start Time Timeout Alerts
		if (mode === types.SessionMode.IN_SESSION) {
			this.startTimeTimeout && clearTimeout(this.startTimeTimeout);
			this.startTimeTimeoutFinal && clearTimeout(this.startTimeTimeoutFinal);
		}

		this.showSessionModesPopup = true;
		setTimeout(() => {this.showSessionModesPopup = false;}, 3000);

		// send to self to switch modes
		this.sessionModeChangeSubject.next(msg);
		if (sendToServer) {
			logger.log(">>>>>>>> setSessionMode sending to peers and save to server: ", mode);
			// send to others for sync
			this.sendMessage({ msg: JSON.stringify(msg), time: null }, types.MessageType.SESSION_MODE);
			// save at server, for each new joinee to retrieve
			this.session && this.session.sessionId && this.requestService.setSessionMode(this.sessionId, this.session.sessionId, msg.sessionMode);
		}
	}

	public reconnect() {
		if(!this.userJoined)
			return;
		if (this.reconnectTimeout || this.sessionStatus === types.SessionStatus.SESSION_ENDED) {
			return;
		}
		logger.log('~~~~~~~~~~~~~ Trying to reconnect...');
		this.reconnecting = true;
		toast({ text: 'Poor network, Reconnecting....', icon: ICON.INFO }, null);
		const maxRetry = 5;
		const retryTimeout = 1000 * 10; // 10 seconds
		this.unsubscribeAll();
		this.removeAllEventHandlers();
		this.disposeOldSession();
		const reconnectToSession = (retryTime = 0) => {
			retryTime++;
			logger.log('~~~~~~~~~~~~~ Reconnection attempt', retryTime);
			this.joinSession().then(() => {
				const successReconnection = () => {
					logger.log('~~~~~~~~~~~~~ Reconnected');
					this.reconnecting = false;
					this.reconnectTimeout = null;
					toast({ text: 'Reconnected', icon: ICON.INFO });
				};
				if (this.sessionMode === types.SessionMode.IN_SESSION && (this.userType === types.USER_TYPE.OBSERVER || this.userType === types.USER_TYPE.NOTE_TAKER)) {
					successReconnection();
				} else {
					if (this.sessionStatus === types.SessionStatus.SESSION_ENDED) {
						logger.log('~~~~~~~~~~~~~ Session has ended, disconnecting....');
						this.disconnect();
						return;
					}
					if(this.streamService.streams.size == 0) {
						logger.log('~~~~~~~~~~~~~ No streams available for reattaching');
						successReconnection();
						return;
					}
					this.streamService.streams.forEach(async (value, type) => {
						logger.log('~~~~~~~~~~~~~ Trying to reattach stream: ', type);
						await this.addStream(this.streamService.streams.get(type), type).then(() => {
							logger.log('~~~~~~~~~~~~~ Stream reattached succesfully: ', type);
						}).catch(err => {
							logger.log('~~~~~~~~~~~~~ Unable to reattach stream of type:', type, 'Error:', err);
						});
					});
					logger.log('~~~~~~~~~~~~~ Iteration Completed, All streams have been tried for reattaching');
					successReconnection();
				}

				// reattach message events listener
				const messageEventsSubscriptions = this.messageEventsSubscriptions.slice(0, this.messageEventsSubscriptions.length);
				this.messageEventsSubscriptions = [];
				messageEventsSubscriptions.map(t => this.handleMessageEvents(t));
			}).catch((err) => {
				if (err.VOLUNTARY_DISCONNECTION) {
					logger.log('~~~~~~~~~~~~~ Catch, Voluntary Disconnection');
					return;
				}
				logger.log('~~~~~~~~~~~~~ Reconnection attempt', retryTime, 'failed', 'max retry limit:', maxRetry);
				if (retryTime == maxRetry) {
					logger.log('~~~~~~~~~~~~~ Max retry limit reached, disconnecting call');
					// session cannot be connected after so many retries, just disconnect fully
					this.sessionStatus = types.SessionStatus.SESSION_ENDED;
					this.sessionStatusEventSubject.next(this.sessionStatus);
					this.disableBackgroundMode();
					this.disconnect();
					return;
				}
				logger.log('~~~~~~~~~~~~~ Retrying after', retryTimeout, 'ms');
				this.reconnectTimeout = setTimeout(reconnectToSession, retryTimeout, retryTime);
			});
		};
		this.reconnectTimeout = setTimeout(reconnectToSession, retryTimeout);
	}

	private unsubscribeAll() {
		try {
			// unsub to all streams
			this.subscribers.forEach(sub => {
				this.session.unsubscribe(sub);
			});
			// remove all connections
			this.connections.forEach(({ connection }) => {
				this.handleDisconnection(connection, true);
			});
		} catch (e) {
			logger.log('Error in unsubscribing', e);
		}
	}

	private removeAllEventHandlers() {
		logger.log('Removing events', this.events);
		this.events.forEach(event => {
			this.session.off(event);
		});
	}

	private disposeOldSession() {
		this.oldSession = this.session;
		Object.values(this.oldSession.remoteConnections).forEach(connection => {
			this.handleDisconnection(connection, true);
		});
		this.oldSession.disconnect();
		this.session = null;
	}

	private removeExcludedConnections() {
		Object.values(this.session.remoteConnections).forEach(connection => {
			if (connection && connection.connectionId && this.excludeConnections.includes(connection.connectionId)) {
				this.session.forceDisconnect(connection);
				this.handleDisconnection(connection, true);
			}
		});
	}

	private handleDisconnection(connection: Connection, force = false) {
		// unique id of connection across kms
		const connectionId: string = connection?.connectionId;
		if (!connectionId) {
			logger.log('Invalid connection:', connection);
			return;
		}
		try {
			// data we passed when joining session
			const data: types.UserMetaData = JSON.parse(connection.data);
			if (!data || !Object.values(data).length || !Object.keys(data).length) {
				logger.log('Invalid data:', data);
				return;
			}
			logger.log('Disconnecting connection: ', data);
			if (!force && (this.isSelf(data.connectionId || connectionId) || !data.userType || data.userType == types.USER_TYPE.SUBSCRIBER)) {
				return;
			}
			// no connection for stream only connection
			if (!force && data.streamType) {
				return;
			}
			logger.log('Connection destroyed: ', connection, this.connections.get(connectionId));

			if (this.connections.has(connectionId)) {
				// send event details to listeners
				this.connectionEventSubject.next({
					type: types.ConnectionEventType.CONNECTION_DESTROYED,
					data: { ...this.connections.get(connectionId)?.userMetaData },
					silent: force
				});
				// delete entry
				this.connections.delete(connectionId);
			}
		} catch (e) {
			logger.log('Data not parasable as JSON: ', connection.data);
		}
	}

	public forceDisconnectParticipant() {
		setTimeout(() => {
			Object.values(this.session.remoteConnections).forEach(connection => {
				try {
					const data: types.UserMetaData = JSON.parse(connection.data);
					if (data.userType === types.USER_TYPE.TESTER) {
						this.session.forceDisconnect(connection);
					}
				} catch (e) {

				}
			});
		}, 1000);
	}
	private setTimeLimit(timeLimit: number) {
		if (this.timeLimit != timeLimit) {
			this.timeLimitTimeout && clearTimeout(this.timeLimitTimeout);
			this.timeLimit = timeLimit;
			// warning time
			const warningTime = 1000 * 60 * 10; // 10 minutes
			// time remaining to end call
			const timeRemaining = timeLimit - Date.now();
			// kick out time - warning time
			const finalWarningTime = timeRemaining - warningTime;
			// steps to kick out
			const kickOut = () => {
				this.requestService.endSesion(this.sessionId);
				this.disconnect();
				let msg = 'The call got automatically disconnected since it had exceeded the allocated time.';
				if (this.sessionMode === types.SessionMode.IN_SESSION) {
					msg += ' Recording of the session has been saved successfully!';
				}
				infoBox(msg);
				this.timeLimitTimeout && clearTimeout(this.timeLimitTimeout);
				this.finalWarningTimeout && clearTimeout(this.finalWarningTimeout);
			};

			this.timeLimitTimeout = setTimeout(() => {
				const timeLeft = Math.min(timeRemaining, warningTime);
				const minutesLeft = Math.floor(timeLeft / (1000 * 60));
				if (minutesLeft > 0) {
					infoBox(`This call is exceeding the allocated duration available, please finish up in the next ${minutesLeft} minutes, or the call is going to auto disconnect and session will end.`);
					this.finalWarningTimeout && clearTimeout(this.finalWarningTimeout);
					this.finalWarningTimeout = setTimeout(() => kickOut(), timeLeft);
				} else {
					kickOut();
				}
			}, finalWarningTime);
		}
	}

	private setSessionEndAlertTime(startTime: number) {
		logger.log('----- sessionTimeOutAlert => setSessionEndAlertTime => startTime: ', startTime, ' , this.startTime: ', this.startTime);
		if (this.startTime != startTime) {
			logger.log('----- sessionTimeOutAlert => setSessionEndAlertTime => startTime and this.startTime are different');
			this.startTimeTimeout && clearTimeout(this.startTimeTimeout);
			this.startTime = startTime;

			const TimeLimit = 1000 * 60 * 30; // 30 minutes
			const warningTime = 1000 * 60 * 5; // 5 minutes warning time
			const timeRemaining = (startTime + TimeLimit) - Date.now(); // time remaining to end call
			const finalWarningTime = timeRemaining - warningTime; // kick out time - warning time

			logger.log('----- sessionTimeOutAlert => setSessionEndAlertTime => finalWarningTime: ', finalWarningTime);
			if (finalWarningTime > 0 && this.sessionMode !== types.SessionMode.IN_SESSION ) {
				// kick out
				const kickOut = () => {
					this.requestService.endSesion(this.sessionId, 0, KICKED_OUT_TRUE);
					this.disconnect();
					infoBox('DeepDive ended the call because a Session did not start within the stipulated time.')
					this.startTimeTimeout && clearTimeout(this.startTimeTimeout);
					this.startTimeTimeoutFinal && clearTimeout(this.startTimeTimeoutFinal);
				};

				this.startTimeTimeout = setTimeout(() => {
					const timeLeft = Math.min(timeRemaining, warningTime);
					const minutesLeft = Math.floor(timeLeft / (1000 * 60));
					logger.log('----- sessionTimeOutAlert => setSessionEndAlertTime => startTimeTimeout => minutesLeft: ', minutesLeft);
					if (minutesLeft > 0) {
						logger.log('----- sessionTimeOutAlert => setSessionEndAlertTime => startTimeTimeout => still minutes left so displaying info alert to user');
						infoBox(`This call is exceeding the allocated duration available, please finish up in the next ${minutesLeft} minutes, or the call is going to auto disconnect and session will end.`);
						this.startTimeTimeoutFinal && clearTimeout(this.startTimeTimeoutFinal);
						this.startTimeTimeoutFinal = setTimeout(kickOut, timeLeft);
					} else {
						logger.log('----- sessionTimeOutAlert => setSessionEndAlertTime => startTimeTimeout => kickOut session');
						kickOut();
					}
				}, finalWarningTime);
			} else {
				logger.log('----- sessionTimeOutAlert => setSessionEndAlertTime => do nothing');
			}
		}
	}

	public processToken(token: string, customIceServers: any): Promise<{ token: string, sessionID: string, openViduURL: string, iceServers: any }> {
		return new Promise((resolve, reject) => {
			const match = token.match(/^(wss?\:)\/\/(([^:\/?#]*)(?:\:([0-9]+))?)([\/]{0,1}[^?#]*)(\?[^#]*|)(#.*|)$/);
			if (!!match) {
				const url = {
					protocol: match[1],
					host: match[2],
					hostname: match[3],
					port: match[4],
					pathname: match[5],
					search: match[6],
					hash: match[7]
				};
				const params = token.split('?');
				const queryParams: any = decodeURI(params[1])
					.split('&')
					.map(function (param) { return param.split('='); })
					.reduce(function (values, _a) {
						const key = _a[0], value = _a[1];
						values[key] = value;
						return values;
					}, {});
					logger.log("queryParams=====>  ", queryParams, params)
				const sessionID = queryParams.sessionId;
				const openViduURL = 'wss://' + url.host + '/openvidu';

				let iceServers = {};

				// in case iceservers available in queryParams in token
				const coturnIp = queryParams.coturnIp;
				const turnUsername = queryParams.turnUsername;
				const turnCredential = queryParams.turnCredential;
				if (!!turnUsername && !!turnCredential) {
					const stunUrl = 'stun:' + coturnIp + ':3478';
					const turnUrl1 = 'turn:' + coturnIp + ':3478';
					const turnUrl2 = turnUrl1 + '?transport=tcp';
					iceServers = {
						stun: { urlStrings: [stunUrl] },
						turn: { urlStrings: [turnUrl1, turnUrl2], username: turnUsername, credential: turnCredential }
					};
				}

				// in case iceservers are retrieved from customIceServers from connection object
				if(customIceServers && customIceServers.length && customIceServers[0]) {
					let c = customIceServers[0].url.split(':');
					const t = c[0];
					delete c[0];
					c = c.join(':');
					iceServers = {
						stun: { urlStrings: ['stun'+c] },
						turn: { urlStrings: [t+c, t+c+'?transport=tcp'], username: customIceServers[0].username, credential: customIceServers[0].credential }
					};
				}

				logger.log("processToken response: ", token, sessionID, openViduURL, iceServers);
				if (token?.trim()?.length && sessionID?.trim()?.length && openViduURL?.trim()?.length) {
					resolve({ token, sessionID, openViduURL, iceServers });
				}
				else {
					reject('Token "' + token + '" is not valid');
				}
			}
			else {
				logger.error('Token "' + token + '" is not valid');
				reject('Token "' + token + '" is not valid');
			}
		});
	}
	public enableBackgroundMode() {
		if (isMobile) {
			const func = () => {
				this.backgroundMode.setDefaults({
					text: 'You\'re on a call',
					hidden: false,
					resume: true
				});
				this.backgroundMode.enable();
				this.backgroundMode.disableBatteryOptimizations();
				ScreenSharing.initWakeLockService();
				document.removeEventListener('deviceready', func, false);
			};

			document.addEventListener('deviceready', func, false);
		}
	}

	private disableBackgroundMode() {
		if (isMobile) {
			this.backgroundMode.disable();
			ScreenSharing.killWakeLockService();
		}
	}

	/**
	 * Clicks photo of participant
	 */
	public takePhoto(mediaStream: MediaStream) {
		const participantPhotoTimeout = 1000 * 30;
		if (this.userType === types.USER_TYPE.TESTER && !this.participantPhotoTaken) {
			if (!this.photoTimeout) {
				this.photoTimeout = setTimeout(() => this.takePhoto(mediaStream), participantPhotoTimeout);
			}
			else {
				clearTimeout(this.photoTimeout);
				this.photoTimeout = null;
				logger.log('Taking participant photo.....');
				const mediaStreamTrack = mediaStream.getVideoTracks().length ? mediaStream.getVideoTracks()[0] : null;
				// creating a video element, play video and then capture photo after 30 seconds
				if (mediaStreamTrack) {
					const video = document.createElement('video');
					const func = () => {
						logger.log('Taking participant photo:', 'playing video');
						const promise = video.play();
						if (promise && promise.then && promise.catch) {
							promise.then(() => {
								logger.log('Taking participant photo:', 'video playing, will grab frame after', participantPhotoTimeout, 'ms');
								setTimeout(() => {
									if (mediaStreamTrack.readyState == 'live' && !video.paused) {
										logger.log('Taking participant photo:', 'grabing frame');
										// paint video on canvas, capture frame
										const canvas = document.createElement('canvas');
										canvas.width = types.VIDEO_MAX_WIDTH;
										canvas.height = types.VIDEO_MAX_HEIGHT;
										const ctx = canvas.getContext('2d');
										// draw image, by scaling to desired aspect ratio
										if (this.streamService.scaleToFit(video, canvas, ctx)) {
											// send blob data to node server, upload photo
											this.participantPhotoTaken = true;
											const reader = new FileReader();
											reader.onload = () => {
												logger.log('Photo taken, saving on server');
												this.requestService.saveParticipantPhoto(this.sessionId, reader.result).catch(logger.error);
											};
											canvas.toBlob(blob => reader.readAsArrayBuffer(blob), 'image/png');
										} else {
											logger.error('Couldn\'t capture frame', 'trying after', participantPhotoTimeout, 'ms');
											this.photoTimeout = setTimeout(() => this.takePhoto(mediaStream), participantPhotoTimeout);
										}
									} else {
										logger.error('MediaStream has ended', 'trying after', participantPhotoTimeout, 'ms');
										this.photoTimeout = setTimeout(() => this.takePhoto(mediaStream), participantPhotoTimeout);
									}
								}, participantPhotoTimeout);
							}).catch((err) => {
								logger.error('Error playing video while taking a photo: ', err, 'trying after', participantPhotoTimeout, 'ms');
								this.photoTimeout = setTimeout(() => this.takePhoto(mediaStream), participantPhotoTimeout);
							});
						} else {
							logger.error('function not promise like', 'trying after', participantPhotoTimeout, 'ms');
							this.photoTimeout = setTimeout(() => this.takePhoto(mediaStream), participantPhotoTimeout);
						}
						video.removeEventListener('canplaythrough', func);
					};
					video.addEventListener('canplaythrough', func);
					video.srcObject = mediaStream;
					video.setAttribute('playsinline', 'true');		// prevent video stream to go fullscreen while taking pic on ios
				} else {
					logger.log('No video streams found in cam stream, trying after', participantPhotoTimeout, 'ms');
					this.photoTimeout = setTimeout(() => this.takePhoto(mediaStream), participantPhotoTimeout);
				}
			}
		}
	}

	public subscribeToNativeAudio() {
		if (this.audioSubTimeout) {
			clearTimeout(this.audioSubTimeout);
			this.audioSubTimeout = null;
		}
		if (isIOS && this.sessionStatus == types.SessionStatus.SESSION_STARTED) {
			const timeout = 1000 * 60;
			this.fetchToken(null, types.USER_TYPE.SUBSCRIBER).then((data) => {
				this.processToken(data.token, data.iceServers).then((res) => {
					logger.log('subscribeToNativeAudio called');
					ScreenSharing.subscribeToNativeAudio({ ...res, originalConnectionID: this.connectionId });
				}).catch(err => logger.error('Error in processing token', err));
			}).catch(err => {
				logger.error('Error in fetching token', err);
				this.audioSubTimeout = setTimeout(() => this.subscribeToNativeAudio(), timeout);
			});
		}
	}

	public unSubscribeToNativeAudio() {
		if (isIOS) {
			ScreenSharing.unSubscribeToNativeAudio();
		}
	}

	public updateRecordingStatus() {
		this.requestService.fetchRecordingStatus(this.sessionId)
			.then(res => {
				if (res && res.recordingStatus) {
					this.setRecordingStatus(res.recordingStatus, res.recordingStartTime);
					res.timeLimit && this.setTimeLimit(res.timeLimit);
				}
			}).catch(() => { });
	}

	private sendJoinStatus(joined) {
		let userId: string = this.userType;
		if(!this.isChiefModerator && this.userType == USER_TYPE.MODERATOR) {
			userId = 'Co-Facilitator';
		}

		let value = `${this.name.capitalizeFirstLetter()} (${userId.capitalizeFirstLetter()}) `;
		value += joined ? 'has joined' : 'has left';
		this.requestService.saveJoinStatus(this.sessionId, {
			timestamp: Date.now(),
			connectionID: this.connectionId,
			value,
			userType: this.userType,
			userId,
			joined
		}).catch(logger.error);
	}

	ngOnDestroy() {
		this.disconnect();
	}

	public getTimeDifference(a: number, b: number): string {
		if (!a || !b)
			return null;
		const diffInSeconds = (a - b) / 1000;
		const minutes = Math.floor(diffInSeconds / 60);
		const seconds = Math.floor(diffInSeconds % 60);
		const mint = minutes < 10 ? '0' + minutes : minutes;
		const sec = seconds < 10 ? '0' + seconds : seconds;
		return mint + ':' + sec;
	}


	// // used to force disconnect user -> use with caution
	// public forceDisconnect(streamEventMessage: types.StreamEventMessage) {
	// 	if (streamEventMessage.actualConnectionID && streamEventMessage.actualConnectionID.trim() != '') {
	// 		setTimeout(() => {
	// 			Object.values(this.session.remoteConnections).forEach(connection => {
	// 				if (connection.connectionId === streamEventMessage.actualConnectionID) {
	// 					this.session.forceDisconnect(connection);
	// 				}
	// 			});
	// 		}, 1000);
	// 	}
	// }
}
