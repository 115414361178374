<div class="translationMenu">
    <div class="translationMenu-container">
        <div class="translationMenu-container-header">
            <div class="translationMenu-container-header-title">Audio volume</div>
            <!-- <div class="translationMenu-container-header-sub">A translator is present in this session. You will listen
                via the translator audio stream instead of the participant</div> -->
        </div>
        <div class="translationMenu-container-content">
            <ng-container *ngFor="let item of translationService.streamChannels">
                <div class="translationMenu-container-content-item"
                    *ngIf="item?.name && item?.isEnabled != 'undefined' && !item?.hidden">
                    <span class="translationMenu-container-content-item-text"> {{ item?.name }}</span>
                    <div class="translationMenu-container-content-item-volume">
                        <!-- icon -->
                        <div class="translationMenu-container-content-item-volume-icon">
                            <app-svg name="speaker-mute" *ngIf="item?.volume == 0" (click)="translationService.toggleChannel(item?.id, 1)"></app-svg>
                            <app-svg name="speaker-unmute" *ngIf="item?.volume != 0" (click)="translationService.toggleChannel(item?.id, 0)"></app-svg>
                        </div>
                        <!-- meter -->
                        <label class="translationMenu-container-content-item-volume-meter">
                            <input #input type="range" class="translationMenu-container-content-item-volume-meter-slider"
                                min="0" max="1" step="0.1" [value]="item?.volume"
                                (input)="translationService.toggleChannel(item?.id, input?.value)" [style.background]="item?.background"/>
                        </label>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>