import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MainComponent } from './main.component';
import { ChatStatusModule } from '../chat-status/chat-status.module';
import { ChatBoxModule } from '../chat-box/chat-box.module';
import { VideoPlayerModule } from '../video-player/video-player.module';
import { AudioPlayerModule } from '../audio-player/audio-player.module';
import { SvgUseModule } from '../svg-use/svg-use.module';
import { ClickOutsideModule } from 'src/app/directives/clickOutside/clickOutside.module';
import { ToolTipModule } from 'src/app/directives/tooltip/tooltip.module';
import { InstructionsModule } from '../instructions/instructions.module';
import { GuideModule } from '../guide/guide.module';
import { NotesListModule } from '../notes-list/notes-list.module';
import { TranslationMenuModule } from '../translation-menu/translation-menu.module';
import { CopyLinksModule } from '../copy-links/copy-links.module';

@NgModule({
	declarations: [
		MainComponent,
	],
	imports: [
		CommonModule,
		ChatStatusModule,
		ChatBoxModule,
		VideoPlayerModule,
		AudioPlayerModule,
		SvgUseModule,
		NotesListModule,
		ClickOutsideModule,
		ToolTipModule,
		InstructionsModule,
		GuideModule,
		TranslationMenuModule,
		CopyLinksModule
	],
	providers: [],
	exports: [
		MainComponent,
	]
})

export class MainModule { }
