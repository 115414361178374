import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ClickOutsideModule } from 'src/app/directives/clickOutside/clickOutside.module';
import { SvgUseModule } from '../svg-use/svg-use.module';
import { InstructionsComponent } from './instructions.component';

@NgModule({
	declarations: [
		InstructionsComponent,
	],
	imports: [
		CommonModule,
		SvgUseModule,
		ClickOutsideModule
	],
	providers: [],
	exports: [
		InstructionsComponent
	]
})

export class InstructionsModule { }
