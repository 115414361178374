<div class="session-check">
	<div class="session-check-logo">
		<img [src]="fetchResource('./assets/deepdivelogo.png')" />
	</div>
	
	<!-- Preparing System -->
	<app-role [setShowSwitchRole]=false (continueClicked)="proceedWithRole()" *ngIf="sessionService.sessionStatus === SessionStatus.SESSION_NOT_STARTED && checkDone && !checkFail && showRoleConfirmation"></app-role>

	<div class="session-check-content" *ngIf="(!checkDone || checkFail) && !showRoleConfirmation && !showSwitchRole">
		<div class="session-check-content-img"><app-svg name="welcome"></app-svg></div>
		<!-- If waiting -->
		<ng-container *ngIf="!checkFail">
			<div class="session-check-content-title">Welcome</div>
			<div class="session-check-content-desc">System is now preparing for your session.</div>
			<div class="session-check-content-desc">Please wait while we fetch your session details...</div>
			<div class="session-check-content-loader">
				<app-loader name='spinner'></app-loader>
			</div>
		</ng-container>
		<!-- If failed -->
		<ng-container *ngIf="checkFail">
			<div class="session-check-content-title">{{ failTitle }}</div>
			<div class="session-check-content-desc">{{ failReason }}</div>
			<div *ngIf="showLoader" class="session-check-content-loader">
				<app-loader name='spinner'></app-loader>
			</div>
			<button *ngIf="showRejoinBtn" class="session-check-content-rejoinBtn" (click)="rejoinSession()">
				{{((sessionService.userType != USER_TYPE.TESTER && !sessionService.isChiefModerator) && isRoomFull) ? 'Switch Role' : 'Rejoin Session'}}
			</button>
		</ng-container>
	</div>
	
	<app-role [setShowSwitchRole]=true *ngIf="showSwitchRole"></app-role>

	<!-- Success -->
	<app-tech-check *ngIf="(checkDone && !checkFail) && !showRoleConfirmation && !showSwitchRole"></app-tech-check>
	<app-footer></app-footer>
</div>

<!-- <app-page-not-found *ngIf="checkFail" [failReason]="failReason"></app-page-not-found> -->