import { environment } from 'src/environments/environment';
import { Component, ViewEncapsulation } from '@angular/core';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
	selector: 'app-translation-menu',
	templateUrl: './translation-menu.component.html',
	styleUrls: ['./translation-menu.component.styl'],
	encapsulation: ViewEncapsulation.ShadowDom
})
export class TranslationMenuComponent {

	public isIOS = isIOS;

	constructor(public translationService: TranslationService) {
	}
}
