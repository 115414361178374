import { Component, ViewEncapsulation } from '@angular/core';
import { TECH_CHECK_TYPES, TECH_CHECK_STATUS, USER_TYPE } from 'src/constants';

@Component({
	selector: 'app-svg-icons',
	templateUrl: 'svg-icons.component.html',
	styleUrls: ['svg-icons.component.styl'],
	encapsulation: ViewEncapsulation.ShadowDom
})
export class SvgIconsComponent {

	public TECH_CHECK_TYPES = TECH_CHECK_TYPES;
	public TECH_CHECK_STATUS = TECH_CHECK_STATUS;

	public USER_TYPE = USER_TYPE;
	constructor() { }
}
