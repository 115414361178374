import { environment } from 'src/environments/environment';
import { Component, ViewEncapsulation, ChangeDetectorRef, AfterViewInit, OnDestroy, ViewChildren, ElementRef, QueryList } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { Subscription } from 'rxjs';
import { SessionService } from 'src/app/services/session.service';
import { Message, Chat, MessageSentStatus, USER_TYPE, ToggleEventType, isJoinStatus } from 'src/constants';
import { ConnectionService } from 'src/app/services/connection.service';

@Component({
	selector: 'app-chat-box',
	templateUrl:'./chat-box.component.html',
	styleUrls: ['./chat-box.component.styl', '../../../../assets/scrollbar.css'],
	encapsulation: ViewEncapsulation.ShadowDom
})
export class ChatBoxComponent implements AfterViewInit, OnDestroy {

	@ViewChildren('messageBox') messageBoxElements: QueryList<ElementRef>;
	@ViewChildren('textarea') textAreaElements: QueryList<ElementRef<HTMLTextAreaElement>>;

	public activeChatIndex = 0;

	public title;

	public message: string;
	private subscriptions: Array<Subscription> = [];

	public placeholderText = 'Enter your message here...' + (isMobile ? '' : '\n\n\n\n⇧ + ↵ add a new line, ↵ send');

	constructor(
		public commonService: CommonService,
		private changeDetectorRef: ChangeDetectorRef,
		private sessionService: SessionService,
		public connectionService: ConnectionService) {
	}

	ngAfterViewInit() {
		const messageSub = this.sessionService.messageEventListener.subscribe((data: Message) => {
			if (data?.type) {
				const chat = this.connectionService.activeChats.find(i => i?.type === data?.type);
				if (chat?.messages) {
					const member = chat.members?.find(i => i?.connectionId === data?.from?.connectionId);
					if (member) {
						data.from = member;
						data.localTimeString = this.commonService.formatTimeStamp(data?.time);
						this.setMessage(chat, data);
						chat.messages.push(data);
						this.changeDetectorRef.detectChanges();
						// send toast of incoming message
						toast({ text: 'New message from: ' + member?.name, icon: ICON.MESSAGE });
						// this.commonService.playNotification();
					} else {
						logger.log('member not found with connectionId: ', data?.from?.connectionId);
					}
				} else {
					logger.log('chat not found');
				}
			}
		});

		const toggleSub = this.commonService.toggleEventListener.subscribe(res => {
			if (res && res == ToggleEventType.CHAT && this.commonService.chatInFocus) {
				this.setFocus();
			}
		});

		// chat window will be scrolled to bottom upon each insertion
		const setPushListener = () => {
			if (this.connectionService.activeChats.length) {
				this.connectionService.activeChats.forEach(d => {
					d.messages.push = (...items) => {
						const result = Array.prototype.push.apply(d.messages, items);
						setTimeout(() => this.scrollToBottom(d), 100);
						return result;
					};
				});
			} else {
				setTimeout(() => setPushListener(), 1000);
			}
		};
		setPushListener();


		this.subscriptions.push(messageSub, toggleSub);
	}

	sendMessage(item: Chat, index: number = null) {
		const textMessage: Message = {
			from: {
				name: '',
				connectionId: this.sessionService.connectionId,
				userType: USER_TYPE.SELF,
				isChiefModerator: this.sessionService.isChiefModerator,
				isMobile,
				userId: null,
				streamType: null
			},
			msg: '',
			time: Date.now(),
			status: MessageSentStatus.UNSENT
		};
		const type = item.type;
		if (index == null) {
			const message = item.message;
			if (message.trim() === '') {
				return;
			}

			this.setFocus();

			// send message on screen
			textMessage.msg = message;
			textMessage.localTimeString = this.commonService.formatTimeStamp(textMessage?.time);
			this.setMessage(item, textMessage);
			item.messages.push(textMessage);
			index = item.messages.length - 1;
		} else {
			const m = item.messages[index];
			textMessage.msg = !isJoinStatus(m) ? m.msg : null;
			item.messages[index] = textMessage;
			this.changeDetectorRef.detectChanges();
		}

		const loaderShowTime = 300;
		this.commonService.sendMessage(textMessage, type)
			.then(() => {
				// mark message status as sent
				setTimeout(() => {
					textMessage.status = MessageSentStatus.SENT;
					item.messages[index] = { ...item.messages[index] };
					this.changeDetectorRef.detectChanges();
				}, loaderShowTime);
			}).catch(() => {
				setTimeout(() => {

					// mark message status as fail, and give retry options
					textMessage.status = MessageSentStatus.FAIL;
					item.messages[index] = { ...item.messages[index] };
					this.changeDetectorRef.detectChanges();
				}, loaderShowTime);
			});
		// clearing message
		item.message = null;
	}

	public resend(item: Chat, data: { time: number, text: string }) {
		if (item && data?.time && data?.text?.trim()?.length) {
			const index = item.messages.findIndex(l => !isJoinStatus(l) && l?.time === data.time);
			if (index !== -1) {
				const m = item.messages[index];
				!isJoinStatus(m) && (m.msg = data.text);
				this.sendMessage(item, index);
			}
		}
	}

	public toggle(index) {
		this.activeChatIndex = index;
		this.changeDetectorRef.detectChanges();
		this.setFocus();
	}

	public keyHandler(event: KeyboardEvent, item: Chat) {
		// restricts input to put new line on enter and allows on shift + enter
		if (!isMobile && event.key === 'Enter' && !event.shiftKey) {
			this.sendMessage(item);
			event.preventDefault();
		}
	}

	private scrollToBottom(item: Chat) {
		// scrolling to bottom after pressing send, waiting till element has been inserted into container and is visible on screen
		if (this.messageBoxElements) {
			const elementsArray = this.messageBoxElements.toArray();
			const elementIndex = this.connectionService.activeChats.findIndex(c => c.type === item.type);
			if (elementIndex !== -1 && elementsArray[elementIndex]?.nativeElement) {
				const element = elementsArray[elementIndex].nativeElement;
				const scrollHeight = element.scrollHeight;
				const clientHeight = element.clientHeight;
				const scrollTop = scrollHeight - clientHeight;
				if (scrollTop > 0) {
					element.scrollTop = scrollTop;
					this.changeDetectorRef.detectChanges();
				}
			}
		}
	}

	trackByType(index: number, item: Chat) {
		return item.type;
	}

	trackByTime(index: number, item: Message) {
		return item.time;
	}

	private setFocus() {
		let requestID;
		if (this.textAreaElements && this.textAreaElements.toArray) {
			const arr = this.textAreaElements.toArray();
			if (arr && arr.length) {
				arr.forEach(element => {
					if (element &&
						element.nativeElement &&
						typeof element.nativeElement.focus == 'function' &&
						element.nativeElement.getAttribute('id') == this.activeChatIndex.toString()) {
							element.nativeElement.focus();
							window.cancelAnimationFrame(requestID);
					}
				});
			}
		} else {
			requestID = window.requestAnimationFrame(() => this.setFocus());
		}
	}

	public toggleMenu(item: Chat) {
		item.isMenuOpen = !item.isMenuOpen;
		this.changeDetectorRef.detectChanges();
	}

	private setMessage(item: Chat, textMessage: Message) {
		let hideName = false;
		let hideTime = false;
		if (item.messages.length) {
			const lastMessage = item.messages[item.messages.length - 1] as Message;
			// hide name if successive messages by same user
			if(lastMessage?.from?.connectionId === textMessage?.from?.connectionId) {
				hideName = true;

				// hide time if successive messages have same time string - mm:ss
				if(!lastMessage?.localTimeString)
					lastMessage.localTimeString = this.commonService.formatTimeStamp(lastMessage?.time);
				if(!textMessage?.localTimeString)
					textMessage.localTimeString = this.commonService.formatTimeStamp(textMessage?.time);

				if(lastMessage?.localTimeString === textMessage?.localTimeString)
					hideTime = true;
			}
		}

		textMessage.hideName = hideName;
		textMessage.hideTime = hideTime;
	}

	ngOnDestroy() {
		this.subscriptions.forEach(subscription => {
			subscription && subscription.unsubscribe();
		});
	}
}
