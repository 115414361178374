<div class="recorder">
	<div
		*ngIf="!isAudioStream && (screenStream && screenStream.src) || (cameraStream && cameraStream.src)"
		class="recorder-content">
		<video
			*ngIf="screenStream && screenStream.src"
			class="recorder-content-screen"
			#screen
			autoplay
			id="{{screenStream.src.id}}"
			playsinline>
		</video>
		<video
			*ngIf="cameraStream && cameraStream.src"
			class="recorder-content-camera"
			#camera
			autoplay
			id="{{cameraStream.src.id}}"
			playsinline>
		</video>
	</div>
	<div class="recorder-placeholder" 
		*ngIf="!isAudioStream && (!screenStream || !screenStream.src) && (!cameraStream || !cameraStream.src)">
		<img [src]="captureScreen ? fetchResource('./assets/screen.png') : fetchResource('./assets/camera.png')" >
	</div>
	<div class="recorder-audio"
		*ngIf="isAudioStream">
		<audio #audio autoplay *ngFor="let item of audioStreams; trackBy: trackById;" id="{{ item.src.id }}"></audio>
	</div>
</div>