import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TechCheckComponent } from './tech-check.component';
import { CheckBrowserComponent } from './checks/check-browser/check-browser.component';
import { CheckCameraComponent } from './checks/check-camera/check-camera.component';
import { CheckMicComponent } from './checks/check-mic/check-mic.component';
import { CheckScreenComponent } from './checks/check-screen/check-screen.component';
import { SvgUseModule } from '../../../svg-use/svg-use.module';
import { AppFooterModule } from 'src/app/components/app-footer/app-footer.component';
import { SessionWaitModule } from '../session-wait/session-wait.module';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [
        TechCheckComponent,
        CheckBrowserComponent,
        CheckCameraComponent,
        CheckMicComponent,
        CheckScreenComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        SvgUseModule,
        SessionWaitModule,
        AppFooterModule
    ],
    providers: [],
    exports: [
        TechCheckComponent,
        CheckBrowserComponent,
        CheckCameraComponent,
        CheckMicComponent,
        CheckScreenComponent
    ]
})

export class TechCheckModule { }
