<div class="check-camera" *ngIf="checkItem">
    <div class="check-camera-image">
        <app-svg name="checkCam" *ngIf="window.isMobile"></app-svg>
        <app-svg name="checkCamDesktop" *ngIf="!window.isMobile"></app-svg>
        <video #player autoplay playsinline ></video>
    </div>
    <div class="check-camera-message">
        <span *ngIf="checkItem.status == TECH_CHECK_STATUS.PROGRESS">Checking for Camera and other System configuration</span>
        <span *ngIf="checkItem.status == TECH_CHECK_STATUS.SUCCESS">All is good with Camera!</span>
        <span *ngIf="checkItem.status == TECH_CHECK_STATUS.FAILED">Camera</span>
    </div>
    <div class="check-camera-instruction">
        <span *ngIf="checkItem.status == TECH_CHECK_STATUS.PROGRESS">
            <div class="check-camera-instruction-progress">
                <div class="check-camera-instruction-progress-container" [ngStyle]="{'width': progressTimer + '%'}"></div>
            </div>
        </span>
        <span *ngIf="checkItem.status == TECH_CHECK_STATUS.SUCCESS"></span>
        <span class="check-camera-instruction-fail" *ngIf="checkItem.status == TECH_CHECK_STATUS.FAILED">{{checkItem.failReason}}</span>
    </div>
    <div class="check-camera-button">
        <button class="check-camera-button-btn" (click)="goToSettings()" *ngIf="checkItem.status == TECH_CHECK_STATUS.FAILED">{{ permissionDenied && !window.isAndroid ? 'Go to settings' : 'Retry' }}</button>
        <button class="check-camera-button-btn" (click)="proceed()" *ngIf="checkItem.status == TECH_CHECK_STATUS.SUCCESS">Proceed to check Screen sharing</button>
    </div>
</div>