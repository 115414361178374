import { Component, ViewEncapsulation, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { SavedNote, SetIDEvent } from 'src/constants';
import { CommonService } from 'src/app/services/common.service';
import { SessionService } from 'src/app/services/session.service';
import { RecordingEventType, SessionMode, USER_TYPE } from '../../../../../../commonConstants';

@Component({
	selector: 'app-notes-list',
	templateUrl: './notes-list.component.html',
	styleUrls: ['./notes-list.component.styl', '../../../../assets/scrollbar.css'],
	encapsulation: ViewEncapsulation.None
})
export class NotesListComponent {

	@ViewChild('container') container: ElementRef<HTMLElement>;

	public notes: SavedNote[] = [];
	public isNotesCollapsed = false;

	constructor(private changeDetectorRef: ChangeDetectorRef,
		public commonService: CommonService,
		private sessionService: SessionService) {
		this.sessionService.sessionModeChangeListener.subscribe(value => {
			let mode = value?.sessionMode === SessionMode.IN_SESSION ? 'In-session' :
			this.sessionService.recordingStatus === RecordingEventType.RECORDING_STOPPED || this.sessionService.sessionMode === SessionMode.IN_SESSION ? 'Debriefing' : 'Briefing';

			if (value?.sessionMode && mode && !this.notes.find(d => d.comment === mode)) {
				let userId: string = this.sessionService.userType;
				if(!this.sessionService.isChiefModerator && this.sessionService.userType == USER_TYPE.MODERATOR) {
					userId = 'Co-Facilitator';
				}
				// push if not already there
				this.notes.push({
					userName: this.sessionService.name,
					userRole: this.sessionService.userType,
					userId: userId,
					timestamp: Date.now(),
					comment: value?.sessionMode === SessionMode.IN_SESSION
					? 
					'In-session'
					:
					this.sessionService.recordingStatus === RecordingEventType.RECORDING_STOPPED || this.sessionService.sessionMode === SessionMode.IN_SESSION
						?
						'Debriefing'
						:
						'Briefing',
					connectionID: this.sessionService?.connectionId,
					id: -Date.now(),
					seekable: false,
					modePlaceholder: true,
					mode: value.sessionMode
				});
			}
		});
	}

	public noteAdded(note: SavedNote) {
		this.notes.push(note);
		// perform animation;

		this.changeDetectorRef.detectChanges();
		this.scrollToBottom();
	}

	// scroll to bottom
	private scrollToBottom() {
		window.requestAnimationFrame(() => {
			const element = this.container?.nativeElement;
			if (element) {
				const scrollHeight = element.scrollHeight;
				const clientHeight = element.clientHeight;
				const scrollTop = scrollHeight - clientHeight;
				if (scrollTop > 0) {
					element.scrollTop = scrollTop;
					this.changeDetectorRef.detectChanges();
				}
			}
		});
	}

	public toggleCollapse() {
		this.isNotesCollapsed = !this.isNotesCollapsed;
		this.changeDetectorRef.detectChanges();

		this.scrollToBottom();
	}

	public setID(event: SetIDEvent) {
		if (event &&
			typeof event.oldID !== 'undefined' &&
			typeof event.newID !== 'undefined') {
			const index = this.notes.findIndex(d => d.id === event.oldID);
			if (index !== -1) {
				this.notes[index].id = event.newID;
				// trigger change detection
				this.notes = [...this.notes];
				this.changeDetectorRef.detectChanges();
			}
		}
	}

	public removeNote(note: SavedNote) {
		if (note) {
			// find by id
			let index = this.notes.findIndex(d => d.id === note.id);
			// if failed find by connection id and timestamp
			if (index === -1)
				index = this.notes.findIndex(d => d.connectionID === note.connectionID && d.timestamp === note.timestamp);
			// if found -> remove
			if (index !== -1) {
				this.notes.splice(index, 1);
				// trigger change detection
				this.notes = [...this.notes];
				this.changeDetectorRef.detectChanges();
			}
		}
	}
}
