import { environment } from 'src/environments/environment';
import { Component, ElementRef, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { Plugins } from '@capacitor/core';
import 'screen-sharing';
import { TechCheckService } from 'src/app/services/tech-check.service';
import { StreamType, TechCheckItem, TECH_CHECK_STATUS, TECH_CHECK_TYPES, TECH_CHECK_VIDEO_PLAYER_MAX_TIMEOUT } from 'src/constants';

const { ScreenSharing } = Plugins;

@Component({
	selector: 'app-check-camera',
	templateUrl: './check-camera.component.html',
	styleUrls: ['./check-camera.component.styl'],
	encapsulation: ViewEncapsulation.ShadowDom
})

export class CheckCameraComponent {

	public checkItem: TechCheckItem;
	public TECH_CHECK_STATUS = TECH_CHECK_STATUS;
	private timeout;
	private testTimeout = 1000 * 7; // 10 sec

	public progressTimer: number = 0;
	private progressInterval;
	public window = window;
	public permissionDenied = false;

	@ViewChild('player') playerElement: ElementRef<HTMLMediaElement>;

	@Input() set check(value) {
		if (value) {
			this.checkItem = value;
		}
	}

	constructor(private techCheckService: TechCheckService) {
	}

	ngAfterViewInit() {
		if (this.checkItem) {
			this.performTest();
		}
	}

	private showProgressBar(max: number) {
		// updating progress counter
		const interval = 500;
		if (this.progressInterval) {
			clearInterval(this.progressInterval);
			this.progressInterval = null;
		}
		this.progressInterval = setInterval(() => {
			if (this.checkItem.status == TECH_CHECK_STATUS.PROGRESS) {
				if (this.progressTimer >= 100) {
					this.progressTimer = 0;
				}
				this.progressTimer += interval * 100 / max;
				this.progressTimer = Math.min(100, this.progressTimer);
			} else {
				clearInterval(this.progressInterval);
				this.progressInterval = null;
			}
		}, interval);
	}

	private performTest() {
		this.clearTimeouts();
		this.showProgressBar(TECH_CHECK_VIDEO_PLAYER_MAX_TIMEOUT);
		this.techCheckService.checkStream(StreamType.CAMERA).then(() => {
			this.permissionDenied = false;
			this.techCheckService.checkIfVideoPlaying(this.playerElement).then(() => {
				this.showProgressBar(this.testTimeout);
				this.timeout = setTimeout(() => {
					this.checkItem.failReason = '';
					this.checkItem.status = TECH_CHECK_STATUS.SUCCESS;
					this.testCompleted();
				}, this.testTimeout);
			}).catch((err) => {
				this.checkItem.failReason = err ? err : 'Your Camera may not be working, please retry after fixing it.';
				this.checkItem.status = TECH_CHECK_STATUS.FAILED;
				this.testCompleted();
			});
		}).catch((err) => {
			this.checkItem.failReason = err;
			this.checkItem.status = TECH_CHECK_STATUS.FAILED;
			this.permissionDenied = true;
			this.testCompleted();
		});
	}

	public testCompleted() {
		this.timeout && clearTimeout(this.timeout);
		this.progressInterval && clearInterval(this.progressInterval);
	}

	public proceed() {
		this.techCheckService.currentCheck = TECH_CHECK_TYPES.SCREEN_SHARING;
	}

	public async goToSettings() {
		// give solution to user to pass the check
		if (this.permissionDenied) {
			if (isIOS) {
				ScreenSharing.goToSettings({ type: TECH_CHECK_TYPES.CAM });
			}
			else if (!isMobile) {
				let text = 'Please open this browser\'s privacy settings, and allow permission to use Camera';
				if (isChrome) {
					text = `Copy this url and open it in a new tab - chrome://settings/content/camera, and allow DeepDive to access camera`;
				} else if (isFirefox) {
					text = `Copy this url and open it in a new tab - about:preferences#privacy, and allow DeepDive to access camera`;
				}
				await infoBox(text, 'Done');
			}
		}
		this.checkItem.failReason = '';
		this.checkItem.status = TECH_CHECK_STATUS.PROGRESS;
		this.performTest();
	}

	private clearTimeouts() {
		if (this.timeout) {
			clearTimeout(this.timeout);
			this.timeout = null;
		}
		this.progressTimer = 0;

		if (this.progressInterval) {
			clearInterval(this.progressInterval);
			this.progressInterval = null;
		}
	}
}
