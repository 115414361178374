<div class="pnf">
	<div class="pnf-logo">
		<img [src]="fetchResource('./assets/deepdivelogo.png')" />
	</div>
	<div class="pnf-container">
		<img class="pnf-container-img" [src]="fetchResource('./assets/alerts/error-red.svg')"/>
		<div class="pnf-container-title">Ouch!</div>
		<div class="pnf-container-desc">{{ text }}</div>
	</div>
</div>
<app-footer></app-footer>