<div class="copyLinks">
    <div class="copyLinks-container">
        <div class="copyLinks-container-header">
            <app-svg name="copy-links-chain"></app-svg>
            <div class="copyLinks-container-header-text">
                Copy invite links
            </div>
        </div>
        <div class="copyLinks-container-content">

            <div class="copyLinks-container-content-item" *ngIf="participantLink">
                <div class="copyLinks-container-content-item-heading">
                    Participant
                </div>
                <div class="copyLinks-container-content-item-content">
                    <div class="copyLinks-container-content-item-content-text">
                        <div class="copyLinks-container-content-item-content-text-heading">
                            {{participantLink}}
                        </div>
                        <div class="copyLinks-container-content-item-content-text-btn hide" (click)="copyLink({'member': 'participant', 'link': participantLink})">
                            <app-svg name="copy-links-btn"></app-svg>
                            <div class="copyLinks-container-content-item-content-text-btn-text">
                                Copy Participant Link
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <br/><hr/>

            <div class="copyLinks-container-content-item copyLinks-container-content-item-all">
                <div class="copyLinks-container-content-item-heading">
                    <div class="copyLinks-container-content-item-heading-text">
                        Team role links
                    </div>
                    <div class="copyLinks-container-content-item-heading-arrow" (click)="showTeamRoleLinks = !showTeamRoleLinks" [class.rotate]="showTeamRoleLinks">
                        <app-svg name="copy-links-arrow"></app-svg>
                    </div>
                    <div class="copyLinks-container-content-item-heading-subtext ">
                        <a href="https://help.uxarmy.com/guide/60b60d7cd996431b22ff32fc" target="_blank">Learn more</a>
                    </div>
                </div>
                <div class="copyLinks-container-content-item-content-text-btn" (click)="copyLink()">
                    <app-svg name="copy-links-btn"></app-svg>
                    <div class="copyLinks-container-content-item-content-text-btn-text">
                        Copy All
                    </div>
                </div>
            </div>

            <ng-container *ngIf="showTeamRoleLinks && teamLinks">
                <div class="copyLinks-container-content-item" *ngFor="let item of teamLinks">
                    <div class="copyLinks-container-content-item-heading">
                        {{item.member}}
                    </div>
                    <div class="copyLinks-container-content-item-content">
                        <div class="copyLinks-container-content-item-content-text">
                            <div class="copyLinks-container-content-item-content-text-heading">
                                {{item.link}}
                            </div>
                            <div class="copyLinks-container-content-item-content-text-btn hide" (click)="copyLink(item)">
                                <app-svg name="copy-links-btn"></app-svg>
                                <div class="copyLinks-container-content-item-content-text-btn-text">
                                    Copy {{item.member}} Link
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

        </div>
    </div>
</div>