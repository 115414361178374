<div class="session-wait" *ngIf="commonService.sessionInWaiting">
	
	<div class="session-wait-content">
		<div class="session-wait-content-img">
			<app-svg name="session-wait"></app-svg>
		</div>
		<div class="session-wait-content-title">{{msg}}</div>
		<div class="session-wait-content-desc">{{subMsg}}</div>
	</div>

	<app-footer></app-footer>
</div>
<app-join *ngIf="!commonService.sessionInWaiting"></app-join>