import { environment } from 'src/environments/environment';
import { ChangeDetectorRef, Component, Input, ViewEncapsulation } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { Subscription } from 'rxjs';
import 'screen-sharing';
import { TechCheckService } from 'src/app/services/tech-check.service';
import { StreamType, TechCheckItem, TECH_CHECK_STATUS, TECH_CHECK_TYPES, TECH_CHECK_VIDEO_PLAYER_MAX_TIMEOUT } from 'src/constants';

const { ScreenSharing } = Plugins;

@Component({
	selector: 'app-check-mic',
	templateUrl: './check-mic.component.html',
	styleUrls: ['./check-mic.component.styl'],
	encapsulation: ViewEncapsulation.ShadowDom
})

export class CheckMicComponent {

	public checkItem: TechCheckItem;
	public TECH_CHECK_STATUS = TECH_CHECK_STATUS;
	private subscription: Subscription;
	public volume: number = 0;
	private timeout;
	private testTimeout = 1000 * 7; // 5 sec
	public window = window;
	public permissionDenied = false;

	public progressTimer: number = 0;
	private progressInterval;

	@Input() set check(value) {
		if (value) {
			this.checkItem = value;
			this.performTest();
		}
	}

	constructor(private techCheckService: TechCheckService, private changeDetetoreRef: ChangeDetectorRef) {
	}

	private showProgressBar(max: number) {

		if(this.progressInterval) {
			clearInterval(this.progressInterval);
			this.progressInterval = null;
		}

		// updating progress counter
		const interval = 500;
		this.progressInterval = setInterval(() => {
			if (this.checkItem.status == TECH_CHECK_STATUS.PROGRESS) {
				if (this.progressTimer >= 100) {
					this.progressTimer = 0;
				}
				this.progressTimer += interval * 100 / max;
				this.progressTimer = Math.min(100, this.progressTimer);
			} else {
				clearInterval(this.progressInterval);
				this.progressInterval = null;
			}
		}, interval);
	}

	private performTest() {
		this.clearTimeouts();
		this.showProgressBar(TECH_CHECK_VIDEO_PLAYER_MAX_TIMEOUT);
		this.techCheckService.checkStream(StreamType.AUDIO).then(() => {
			this.permissionDenied = false;
			this.showProgressBar(this.testTimeout);
			// if for sometime the volume level is > 0 then pass the check
			let maxVolume = 0;
			this.timeout = setTimeout(() => {
				if (maxVolume > 0) {
					this.checkItem.failReason = '';
					this.checkItem.status = TECH_CHECK_STATUS.SUCCESS;
					this.testCompleted();
				} else {
					this.checkItem.failReason = 'Your Mic may be muted or not working properly, please retry after fixing it.';
					this.checkItem.status = TECH_CHECK_STATUS.FAILED;
					this.testCompleted();
				}
			}, this.testTimeout);

			this.subscription = this.techCheckService.audioLevelListener.subscribe((res) => {
				if (res) {
					if (res.enabled) {
						this.volume = Math.round(res.volume);
					} else {
						// mic is muted
						this.volume = 0;
					}

					maxVolume = Math.max(maxVolume, this.volume);
					this.changeDetetoreRef.detectChanges();
				}
			});
		}).catch((err) => {
			this.checkItem.failReason = err;
			this.checkItem.status = TECH_CHECK_STATUS.FAILED;
			this.permissionDenied = true;
			this.testCompleted();
		});
	}

	public testCompleted() {
		this.techCheckService.stopAudioProcessing();
		this.subscription && this.subscription.unsubscribe();
		this.timeout && clearTimeout(this.timeout);
	}

	public proceed() {
		this.techCheckService.currentCheck = TECH_CHECK_TYPES.CAM;
	}

	public async goToSettings() {
		// give solution to user to pass the check
		if (this.permissionDenied) {
			if (isIOS) {
				ScreenSharing.goToSettings({ type: TECH_CHECK_TYPES.MIC });
			}
			else if (!isMobile) {
				let text = 'Please open this browser\'s privacy settings, and allow permission to use Microphone';
				if (isChrome) {
					text = `Copy this url and open it in a new tab - chrome://settings/content/microphone, and allow DeepDive to access microphone`;
				} else if (isFirefox) {
					text = `Copy this url and open it in a new tab - about:preferences#privacy, and allow DeepDive to access microphone`;
				}
				await infoBox(text, 'Done');
			}
		}
		this.checkItem.failReason = '';
		this.checkItem.status = TECH_CHECK_STATUS.PROGRESS;
		this.performTest();
	}

	private clearTimeouts() {
		if(this.timeout) {
			clearTimeout(this.timeout);
			this.timeout = null;
		}
		this.progressTimer = 0;

		if(this.progressInterval) {
			clearInterval(this.progressInterval);
			this.progressInterval = null;
		}
	}
}
