<div class="notes-list">
    <!-- collapse window -->
    <div class="notes-list-header">
        <span class="notes-list-header-icon" (click)="toggleCollapse()">
            <app-svg name="notes-collapsed" *ngIf="isNotesCollapsed" title="Expand"></app-svg>
            <app-svg name="notes-expanded" *ngIf="!isNotesCollapsed" title="Collapse"></app-svg>
        </span>
        <span class="notes-list-header-text">Notes</span>
        <span class="notes-list-header-icon" (click)="commonService.toggleNotes(true)">
            <app-svg name="close" color="white"></app-svg>
        </span>
    </div>
    <div #container class="notes-list-content">
        <!-- prefilled notes -->
        <div class="notes-list-content-items" [class.hide]="isNotesCollapsed">
            <app-notes
                *ngFor="let item of notes"
                [data]="item"
                (removeNote)="removeNote($event)"
                (setID)="setID($event)">
            </app-notes>
        </div>
        <!-- new note -->
        <div class="notes-list-content-newNote">
            <app-notes
                (noteAdded)="noteAdded($event)"
                (setID)="setID($event)">
            </app-notes>
        </div>
    </div>
</div>