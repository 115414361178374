import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SvgUseModule } from '../svg-use/svg-use.module';
import { JoineeTooltipComponent } from './joinee-tooltip.component';
import { ClickOutsideModule } from 'src/app/directives/clickOutside/clickOutside.module';
@NgModule({
    declarations: [
        JoineeTooltipComponent,
    ],
    imports: [
        CommonModule,
        SvgUseModule,
        ClickOutsideModule
    ],
    providers: [],
    exports: [
        JoineeTooltipComponent
    ]
})

export class JoineeTooltipModule { }
