import { Component, ViewEncapsulation, ChangeDetectorRef, ViewChild, ElementRef, ViewChildren, QueryList, OnDestroy } from '@angular/core';
import { Session, OpenVidu, StreamEvent, Stream, Subscriber } from 'openvidu-browser';
import { StreamType, USER_TYPE, UserMetaData, TOKEN_KEY, STREAMS_TO_CAPTURE } from 'src/constants';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { RequestService } from 'src/app/services/request.service';
import { USERS_TO_CAPTURE } from '../../../../../commonConstants';


/**
 * Custom Layout for Composite Recorder for openvidu
 * Here this component will act as a standalone recording interface, and join openvidu session as a recorder, and 	attaches video elements to html.
*/

interface StreamDetail {
	connectionID: string;
	streamType: StreamType;
	src: MediaStream;
	userType: USER_TYPE;
}

@Component({
	selector: 'app-recorder',
	templateUrl: './recorder.component.html',
	styleUrls: ['./recorder.component.styl'],
	encapsulation: ViewEncapsulation.ShadowDom
})
export class RecorderComponent implements OnDestroy {

	@ViewChild('screen') screenElement: ElementRef<HTMLVideoElement>;
	@ViewChild('camera') cameraElement: ElementRef<HTMLVideoElement>;
	@ViewChildren('audio') audioElements: QueryList<ElementRef<HTMLMediaElement>>;

	private paramSub: Subscription;

	private session: Session;
	private sessionID: string;
	public StreamType = StreamType;
	public USER_TYPE = USER_TYPE;
	public fetchResource = fetchResource;

	public screenStream: StreamDetail;
	public cameraStream: StreamDetail;
	public audioStreams: StreamDetail[] = [];

	private subscribers = new Map<string,Subscriber>();

	public captureScreen = true;
	public isAudioStream = false;

	public streamTypeToCapture: StreamType;
	public userTypeToCapture: USER_TYPE;

	private logIdentifier: string = "";

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private route: ActivatedRoute,
		private requestService: RequestService) {
		// parse url -> openvidu will call this component by an absolute url
		this.requestService.sendRecorderLog({ URL: window.location.href });
		this.paramSub = this.route.queryParams.subscribe(async (params) => {
			this.logIdentifier = params[STREAMS_TO_CAPTURE] + '_' + params[USERS_TO_CAPTURE];
			this.debugLog('logIdentifier: ', this.logIdentifier);
			logger.log(params);
			this.requestService.sendRecorderLog({ queryParams: params });
			if (params && params[TOKEN_KEY]) {

				// set streams to capture as per in request
				if (params[STREAMS_TO_CAPTURE] && params[STREAMS_TO_CAPTURE].trim() != '' && params[USERS_TO_CAPTURE] && params[USERS_TO_CAPTURE].trim() != '') {
					this.streamTypeToCapture = params[STREAMS_TO_CAPTURE];
					this.userTypeToCapture = params[USERS_TO_CAPTURE];

					if (this.streamTypeToCapture != StreamType.SCREEN) {
						this.captureScreen = false;
					}
					if (this.streamTypeToCapture == StreamType.AUDIO) {
						this.isAudioStream = true;
					}
				}

				this.debugLog('streamTypeToCapture: ', this.streamTypeToCapture, ' userTypeToCapture: ', this.userTypeToCapture, ' captureScreen: ', this.captureScreen, ' isAudioStream: ', this.isAudioStream);

				// fetch session id and secret
				const TOKEN = params[TOKEN_KEY];
				if (TOKEN && TOKEN.trim() != '') {
					// init openvidu
					const OV = new OpenVidu();
					if (OV && OV instanceof OpenVidu && typeof OV.initSession == 'function') {
						// init session
						this.session = OV.initSession();
						if (this.session) {

							// subscribe to only stream events
							this.session.on('streamCreated', (e: StreamEvent) => this.subscribeToStream(e));
							this.session.on('streamDestroyed', (e: StreamEvent) => this.unsubscribeToStream(e));

							const connect = () => {
								const m = 'Connecting to session';
								logger.log(m);
								this.debugLog(m);
								this.requestService.sendRecorderLog({ sessionID: this.sessionID, status: m });
								// connect to session
								this.session.connect(TOKEN)
									.then(() => {
										this.sessionID = this.session.sessionId;
										const m = 'Recorder participant connected';
										this.requestService.sendRecorderLog({ sessionID: this.sessionID, status: m });
										logger.log(m);
										this.debugLog(m);
									})
									.catch(error => {
										logger.error(error);
										this.debugLog(error);
										this.requestService.sendRecorderLog({ sessionID: this.sessionID, error });
										connect();
									});
							};
							connect();
						} else {
							const e = 'Error in init session';
							logger.error(e);
							this.debugLog(e);
							this.requestService.sendRecorderLog({ sessionID: this.sessionID, error: e });
						}
					} else {
						const e = 'Error in init openvidu';
						logger.error(e);
						this.debugLog(e);
						this.requestService.sendRecorderLog({ sessionID: this.sessionID, error: e });
					}
				} else {
					const e = 'SESSION_ID or SECRET empty or missing';
					logger.error(e);
					this.debugLog(e);
					this.requestService.sendRecorderLog({ sessionID: this.sessionID, error: e });
				}
			} else {
				const e = `${TOKEN_KEY} Params missing in query string`;
				logger.error(e);
				this.debugLog(e);
				this.requestService.sendRecorderLog({ sessionID: this.sessionID, error: e });
			}
		});
	}

	public subscribeToStream(e: StreamEvent) {
		const stream: Stream = e.stream;
		let streamType: StreamType = null;
		let userType: USER_TYPE = null;
		let connectionID: string = null;
		this.requestService.sendRecorderLog({ sessionID: this.sessionID, status: 'Stream Recieved' });
		if (stream && stream.connection && stream.connection.data && stream.connection.data.trim() !== '') {
			// only subscribe to those streams which are present in usertype and streamtype constant above
			try {
				const data: UserMetaData = JSON.parse(stream.connection.data);
				this.requestService.sendRecorderLog({ sessionID: this.sessionID, status: 'Stream data: ' + stream.connection.data });
				if (data &&
					data.streamType &&
					data.userType &&
					stream.connection.connectionId &&
					stream.connection.connectionId.trim() != '' &&
					this.userTypeToCapture == data.userType &&
					this.streamTypeToCapture == data.streamType
				) {
					this.debugLog('subscribeToStream for UserMetaData: ', data);
					userType = data.userType;
					streamType = data.streamType;
					connectionID = stream.connection.connectionId;
					this.requestService.sendRecorderLog({ sessionID: this.sessionID, status: 'Subscribing to ConnectionID:' + connectionID });
				} else {
					return;
				}

			} catch (e) {
				const err = 'Data not json parsable:' + stream.connection.data;
				logger.log(err);
				this.debugLog(err);
				this.requestService.sendRecorderLog({ sessionID: this.sessionID, error: err });
			}
		} else {
			this.requestService.sendRecorderLog({ sessionID: this.sessionID, error: 'Data missing from stream:' + stream });
			this.debugLog('Data missing from stream');
			return;
		}

		const subscribe = (retry = 0) => {
			// have to subscribe in order to recieve the stream
			this.session.subscribeAsync(stream, null).then((subscriber: Subscriber) => {
				this.requestService.sendRecorderLog({ sessionID: this.sessionID, status: 'Subscribed to ConnectionID:' + connectionID });
				this.debugLog('sessionID: ', this.sessionID, ' status: Subscribed to ConnectionID: ', connectionID);
				this.subscribers.set(connectionID, subscriber);

				// sending stream to view
				const src: MediaStream = stream.getMediaStream();
				const data: StreamDetail = {
					connectionID,
					userType,
					streamType,
					src
				};
				this.debugLog('subscribe data: ', data);
				switch (streamType) {
					case StreamType.SCREEN:
						this.screenStream = data;
						break;
					case StreamType.CAMERA:
						this.cameraStream = data;
						break;
					case StreamType.AUDIO:
						this.audioStreams.push(data);
						break;
					default:
						this.requestService.sendRecorderLog({ sessionID: this.sessionID, status: `Invalid streamType: ${streamType}, unsubscribing` });
						this.session.unsubscribe(subscriber);
						this.subscribers.delete(connectionID);
						break;
				}
				this.debugLog('screenStream: ', this.screenStream, ' cameraStream: ', this.cameraStream, ' audioStreams: ', this.audioStreams);
				this.changeDetectorRef.detectChanges();
				this.setStream(streamType);
			}).catch(err => {
				if (retry > 5) {
					const e = 'Max retry hit, dropping subscription';
					logger.log(e);
					this.debugLog(e);
					this.requestService.sendRecorderLog({ sessionID: this.sessionID, error: e });
					return;
				} else {
					const e = 'Unable to subscribe, trying again:' + err;
					logger.log(e);
					this.debugLog(e);
					this.requestService.sendRecorderLog({ sessionID: this.sessionID, error: e });
					if (!stream.connection.disposed) {
						setTimeout(() => subscribe(++retry), 500);
					}
				}
			});
		};
		subscribe();
	}

	public unsubscribeToStream(e: StreamEvent) {
		const stream: Stream = e.stream;
		this.requestService.sendRecorderLog({ sessionID: this.sessionID, status: 'Unsubscribing from stream' });
		this.debugLog('Unsubscribing from stream: ', stream);
		if (stream && stream.connection && stream.connection.connectionId && stream.connection.connectionId.trim() != '') {
			const connectionID = stream.connection.connectionId;
			// if subscriber present then unsubscribe
			if (this.subscribers.has(connectionID)) {
				this.session.unsubscribe(this.subscribers.get(connectionID));
				this.subscribers.delete(connectionID);
			}

			// if audio stream
			if (this.audioStreams && this.audioStreams.length && this.audioStreams.some(d => d.connectionID == connectionID)) {
				this.audioStreams = this.audioStreams.filter(d => d.connectionID != connectionID);
			}

			// if screen stream
			if (this.screenStream && this.screenStream.connectionID && this.screenStream.connectionID == connectionID) {
				this.screenStream = null;
			}

			// if camera stream
			if (this.cameraStream && this.cameraStream.connectionID && this.cameraStream.connectionID == connectionID) {
				this.cameraStream = null;
			}

			// detect change
			this.changeDetectorRef.detectChanges();
		} else {
			return;
		}
	}

	trackById(index: number, item: StreamDetail) {
		return item.connectionID;
	}

	public setStream(streamType: StreamType) {
		this.requestService.sendRecorderLog({ sessionID: this.sessionID, status: `Trying to play stream of type: ${streamType}` });
		this.debugLog('sessionID: ', this.sessionID, ' status: Trying to play stream of type: ', streamType);
		const func = (element: HTMLMediaElement) => {
			const promise = element.play();
			if (promise && promise instanceof Promise) {
				promise.then().catch((err) => {
					const e = 'Error playing: ' + streamType + ' : ' + err;
					logger.error(e);
					this.requestService.sendRecorderLog({ sessionID: this.sessionID, error: e });
					setTimeout(() => this.setStream(streamType), 500);
				});
			}
			this.changeDetectorRef.detectChanges();
			element.removeEventListener('canplaythrough', () => func(element));
		};

		switch (streamType) {
			case StreamType.AUDIO:
				if (this.audioElements && this.audioElements.length) {
					this.audioElements.forEach((element: ElementRef) => {
						if (element.nativeElement) {
							const el = element.nativeElement;
							if (el.paused || el.ended) {
								const streamID = el.getAttribute('id');
								if (streamID) {
									const stream = this.audioStreams.find(d => d && d.src && d.src.id == streamID);
									if (stream) {
										el.srcObject = stream.src;
										el.addEventListener('canplaythrough', () => func(el));
									}
								}
							}
						}
					});
					this.changeDetectorRef.detectChanges();
				}
				else {
					setTimeout(() => this.setStream(streamType), 500);
				}
				break;
			case StreamType.SCREEN:
				if (this.screenElement && this.screenElement.nativeElement) {
					const el = this.screenElement.nativeElement;
					const streamID = el.getAttribute('id');
					if (streamID && this.screenStream && this.screenStream.src && this.screenStream.src.id == streamID) {
						el.srcObject = this.screenStream.src;
						el.addEventListener('canplaythrough', () => func(el));
					} else {
						setTimeout(() => this.setStream(streamType), 500);
					}
				} else {
					setTimeout(() => this.setStream(streamType), 500);
				}
				break;
			case StreamType.CAMERA:
				if (this.cameraElement && this.cameraElement.nativeElement) {
					const el = this.cameraElement.nativeElement;
					const streamID = el.getAttribute('id');
					if (streamID && this.cameraStream && this.cameraStream.src && this.cameraStream.src.id == streamID) {
						el.srcObject = this.cameraStream.src;
						el.addEventListener('canplaythrough', () => func(el));
					} else {
						setTimeout(() => this.setStream(streamType), 500);
					}
				} else {
					setTimeout(() => this.setStream(streamType), 500);
				}
				break;
		}
	}

	ngOnDestroy() {
		this.debugLog('-------------- DESTROYED --------------');
		this.paramSub && this.paramSub.unsubscribe();
		this.subscribers.forEach(sub => {
			if (this.session) {
				this.session.unsubscribe(sub);
			}
		});
		this.subscribers.clear();
		if (this.session) {
			this.session.disconnect();
			this.session = null;
		}

		this.audioStreams = [];
		this.screenStream = null;
		this.cameraStream = null;
	}

	debugLog(...msg) {
		const arr = [];
		arr.push('Recorder_Component_Log:');
		arr.push(this.logIdentifier);
		msg.forEach(m => {
			try {
				// to check if object is serializable
				JSON.stringify(m);
				arr.push(m);
			} catch (e) {
				if (typeof m.toString == 'function') {
					arr.push(m.toString());
				}
			}
		});
		this.requestService.sendRecorderLog(arr);
	}
}
