import { environment } from 'src/environments/environment';
import { Component, ViewEncapsulation, ChangeDetectorRef, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { SessionService } from 'src/app/services/session.service';
import { USER_TYPE, MessageType, ToggleEventType } from 'src/constants';
import { Subscription } from 'rxjs';
import { RequestService } from 'src/app/services/request.service';
import tippy from 'tippy.js';

// library used to detect urls - https://github.com/validatorjs/validator.js
import Validator from 'validator';

@Component({
	selector: 'app-instructions',
	templateUrl: './instructions.component.html',
	styleUrls: ['./instructions.component.styl', '../../../../assets/scrollbar.css'],
	encapsulation: ViewEncapsulation.ShadowDom
})
export class InstructionsComponent implements AfterViewInit, OnDestroy {

	@ViewChild('container') container: ElementRef<HTMLElement>;

	public editModeEnabled = false;
	public USER_TYPE = USER_TYPE;
	private sub: Subscription;
	public isMobile = isMobile;

	constructor(
		public commonService: CommonService,
		public sessionService: SessionService,
		private requestService: RequestService,
		private changeDetectorRef: ChangeDetectorRef) {
	}

	ngAfterViewInit() {

		// setting listeners for click event
		const set = () => {
			if (this.container && this.container.nativeElement) {

				if (this.sessionService.isChiefModerator) {
					// hover
					this.container.nativeElement.removeEventListener('mouseover', e => this.handleLinks(e));
					this.container.nativeElement.addEventListener('mouseover', e => this.handleLinks(e));

					// dblclick
					this.container.nativeElement.removeEventListener('dblclick', e => this.handleLinks(e));
					this.container.nativeElement.addEventListener('dblclick', e => this.handleLinks(e));

					// keydown
					this.container.nativeElement.removeEventListener('keyup', e => this.handleKeyPress(e));
					this.container.nativeElement.addEventListener('keyup', e => this.handleKeyPress(e));
				} else {
					// click for others
					this.container.nativeElement.removeEventListener('click', e => this.handleLinks(e));
					this.container.nativeElement.addEventListener('click', e => this.handleLinks(e));
				}

				// forcing conversion to links at start
				this.handleKeyPress(null, true);
			} else {
				window.requestAnimationFrame(set);
			}
		};
		set();

		// subscribing to content change by other people
		this.sub = this.sessionService.messageEventListener.subscribe(message => {
			if (message && message.type == MessageType.INSTRUCTIONS_CHANGE && typeof message.msg !== 'undefined') {
				this.commonService.instructions = message.msg.trim();
				this.changeDetectorRef.detectChanges();
			}
		});


		// setting editable
		if (this.sessionService.isChiefModerator) {
			this.editModeEnabled = true;
		}
		else {
			this.editModeEnabled = false;
		}

		// triggering change detection
		this.changeDetectorRef.detectChanges();

		// reset html upon closing of instructions
		this.commonService.toggleEventListener.subscribe(event => {
			if (event && event == ToggleEventType.INSTRUCTIONS &&
				!this.commonService.instructionsInFocus && this.container && this.container.nativeElement) {
				this.container.nativeElement.innerHTML = this.commonService.instructions;
			}
		});
	}

	public save() {

		// forcing conversion to links at start
		this.handleKeyPress(null, true);

		// save data
		if (this.container && this.container.nativeElement) {
			this.container.nativeElement.innerHTML = this.container.nativeElement.innerHTML.replace(/^<br>/g, '').replace(/^<div><br><\/div>/g, '');
			this.commonService.instructions = (this.container.nativeElement.innerHTML || '').toString();
			this.changeDetectorRef.detectChanges();
		}

		// send to all
		this.sessionService.sendMessage({ msg: this.commonService.instructions.toString(), time: Date.now() }, MessageType.INSTRUCTIONS_CHANGE);

		// send to server
		this.requestService.saveInstructions(this.sessionService.sessionId, this.commonService.instructions).catch(() => {});

		this.commonService.toggleInstructions();
	}


	public handleLinks(event: MouseEvent) {
		const path = event.path || (event.composedPath && event.composedPath());
		if (path) {
			const element = path.shift();
			// @ts-ignore
			if (element && typeof element.getAttribute == 'function') {
				// @ts-ignore
				const link = element.getAttribute('href');
				if (link && link.trim() != '') {
					switch (event.type) {
						case 'mouseover':
							// @ts-ignore
							tippy(element, {
								allowHTML: false,
								content: `Double click to open link`,
								arrow: true,
								hideOnClick: true,
								interactive: false,
								placement: 'top',
								ignoreAttributes: true,
							}).show();
							break;
						case 'dblclick':
							this.commonService.openLink(link);
							break;
						case 'click':
							if (!this.sessionService.isChiefModerator) {
								this.commonService.openLink(link);
							}
							break;
					}
				}
			}
		}
		event.preventDefault();
	}

	private handleKeyPress(event: KeyboardEvent, force = false) {
		// act on space or enter or when forced
		if (force || (event &&
			// enter key
			(event.key && (event.key.toLowerCase() == 'enter')) ||
			(event.keyCode && event.keyCode == 13) ||
			(event.code && event.code.toLowerCase() == 'enter') ||
			// space key
			(event.key && (event.key.toLowerCase() == ' ')) ||
			(event.keyCode && event.keyCode == 32) ||
			(event.code && event.code.toLowerCase() == 'space')) && this.container && this.container.nativeElement) {
			this.createHyperLink(this.container.nativeElement);
		}
	}

	private createHyperLink(element: HTMLElement | ChildNode, childIndex = 0) {
		const textNodeName = '#text';
		const anchorNodeName = 'a';
		if (element && (
			// check if current tag text node and parent not anchor tag
			(element.nodeName.toLowerCase() == textNodeName &&
				element.nodeValue && element.nodeValue.trim() != '' &&
				element.parentNode.nodeName.toLowerCase() != anchorNodeName) ||
			// check if element has child nodes
			(element.childNodes && element.childNodes.length))) {
			if (element.nodeName.toLowerCase() == textNodeName) {
				const textContent = element.nodeValue.split(' ');
				if (textContent.length) {
					for (let i = 0; i < textContent.length; i++) {
						const word = textContent[i].trim();
						// currently only allowing urls with protocol, if want to allow regardless of it, then check url without protocol and then add it if missing, keep innerhtml as the typed text and href as url with protocol
						if (Validator.isURL(word, { require_protocol: true })) {
							// // if url does not have a protocol to it, add http://
							// if (!Validator.isURL(word, { require_protocol: true })) {
							// 	url = 'http://' + url;
							// }
							const a = document.createElement('a');
							a.href = word;
							a.innerHTML = word;

							// split html of parent node in two parts

							// element before hyperlink
							const before = document.createElement('text');
							before.innerHTML = textContent.slice(0, i).join(' ') + '&nbsp;';

							// element after hyper link
							const after = document.createElement('text');
							after.innerHTML = textContent.slice(i + 1, textContent.length).join(' ');

							// create space after hyper link, not putting space before "after" in it's content, as the caret position is to be shifted at the start, putting it in infront moves the position after hyperlink
							const space = document.createElement('span');
							space.innerHTML = '&nbsp;';

							// current element
							const currentNode = element.parentNode.childNodes[childIndex];

							// insert all
							[before, a, space, after].forEach(d => element.parentNode.insertBefore(d, currentNode));
							// remove original
							element.parentNode.removeChild(currentNode);

							this.moveCaretToStart(after);
						}
					}
				}
			} else {
				element.childNodes.forEach((childNode, i) => this.createHyperLink(childNode, i));
			}
		} else {
			return;
		}
	}

	// moves the caret position to start of the element
	private moveCaretToStart(el: HTMLElement | ChildNode) {
		const range = document.createRange();
		const sel = window.getSelection();

		range.setStart(el, 0);
		range.collapse(true);

		sel.removeAllRanges();
		sel.addRange(range);
	}


	ngOnDestroy() {
		this.sub && this.sub.unsubscribe();
		if (this.container && this.container.nativeElement) {
			this.container.nativeElement.removeEventListener('mouseover', e => this.handleLinks(e));
			this.container.nativeElement.removeEventListener('dblclick', e => this.handleLinks(e));
			this.container.nativeElement.removeEventListener('keyup', e => this.handleKeyPress(e));
			this.container.nativeElement.removeEventListener('click', e => this.handleLinks(e));
		}
	}
}
