import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ChatBoxComponent } from './chat-box.component';
import { FormsModule } from '@angular/forms';
import { MessageComponent } from './components/message/message.component';
import { SvgUseModule } from '../svg-use/svg-use.module';
import { ClickOutsideModule } from 'src/app/directives/clickOutside/clickOutside.module';

@NgModule({
    declarations: [
        ChatBoxComponent,
        MessageComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        SvgUseModule,
        ClickOutsideModule
    ],
    providers: [],
    exports: [
        ChatBoxComponent
    ]
})

export class ChatBoxModule { }
