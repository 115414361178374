import { Component, Output, ViewEncapsulation,EventEmitter, Input } from '@angular/core';
import { SessionService } from 'src/app/services/session.service';
import { USER_TYPE } from 'src/constants';

@Component({
	selector: 'app-role',
	templateUrl: './role.component.html',
	styleUrls: ['./role.component.styl'],
	encapsulation: ViewEncapsulation.ShadowDom
})
export class RoleComponent {
    public fetchResource = fetchResource;
    public USER_TYPE = USER_TYPE;
    public showSwitchRole: boolean;
    public roleDetails = {};
    public activeRole = null;

    @Input() set setShowSwitchRole(value) {
        this.showSwitchRole = value;
	}

    @Output() public continueClicked = new EventEmitter();

	constructor(public sessionService: SessionService) {
        // this.roleDetails[USER_TYPE.TESTER] = {name: 'Participant', desc: 'Participate the session to provide feedback', color: '#EA5757', 'background': 'rgb(234 87 87 / 10%)'};
        this.roleDetails[USER_TYPE.MODERATOR] = {name: 'Co-Facilitator', desc: 'For the secondary Moderator for the Session. This role cannot start or Record the Session.', color: '#357AEB', 'background': 'rgb(52 122 235 / 10%)'};
        this.roleDetails[USER_TYPE.NOTE_TAKER] = {name: 'Note taker', desc: 'For the person assigned to take Notes during the Session. Notes are automatically merged in the Report.', color: '#F28727', 'background': 'rgb(255 165 85 / 10%)'};
        this.roleDetails[USER_TYPE.OBSERVER] = {name: 'Observer', desc: 'For anyone to observe the user research. Observers can also type-chat internally during the Session.', color: '#0EC2A6', 'background': 'rgb(17 194 166 / 10%)'};
        this.roleDetails[USER_TYPE.TRANSLATOR] = {name: 'Translator', desc: 'For the language Interpreter to verbally translate Live during the Session.', color: '#6C7AFF', 'background': 'rgb(109 122 255 / 10%)'};
    }

    ngAfterViewInit() {
        this.activeRole = this.sessionService.userType;
    }

    public continueToRole() {
        if(this.sessionService.teamLinks) {
            const indx = Object.keys(this.sessionService.teamLinks).find(k => k.toLowerCase().replace('_','') == this.activeRole.toLowerCase().replace('_',''));

            if(this.activeRole == USER_TYPE.MODERATOR && !indx) {
                const r = 'Co-Facilitator'.toLowerCase().replace('_','');
                const link = this.sessionService.teamLinks[r];
                window.open(link, '_self');
            } else {
                if(indx) {
                    const link = this.sessionService.teamLinks[indx];
                    window.open(link, '_self');
                } else {
                    toast({ text: 'Some error occured while switching the role', icon: ICON.ERROR });    
                }
            }
        } else {
            toast({ text: 'Some error occured while switching the role', icon: ICON.ERROR });
        }
    }
}
