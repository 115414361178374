import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SvgUseModule } from '../svg-use/svg-use.module';
import { TranslationMenuComponent } from './translation-menu.component';

@NgModule({
    declarations: [
        TranslationMenuComponent
    ],
    imports: [
        CommonModule,
        SvgUseModule
    ],
    providers: [],
    exports: [
        TranslationMenuComponent
    ]
})

export class TranslationMenuModule { }
