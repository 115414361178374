import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TesterComponent } from './components/users/tester/tester.component';
import { ModeratorComponent } from './components/users/moderator/moderator.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { MainModule } from './components/common/main/main.module';
import { ObserverComponent } from './components/users/observer/observer.component';
import { NoteTakerComponent } from './components/users/note-taker/note-taker.component';
import { HttpClientModule } from '@angular/common/http';
import { SvgUseModule } from './components/common/svg-use/svg-use.module';
import { CheckModule } from './components/common/check/check.module';
// ionic background mode plugin
import { BackgroundMode } from '@ionic-native/background-mode/ngx';
import { RecorderComponent } from './components/recorder/recorder.component';
import { AppFooterModule } from './components/app-footer/app-footer.component';
import { NoSessionComponent } from './components/no-session/no-session.component';
import { PageNotFoundModule } from './components/page-not-found/page-not-found.module';
import { TranslatorComponent } from './components/users/translator/translator.component';

@NgModule({
	declarations: [
		AppComponent,
		TesterComponent,
		ModeratorComponent,
		DashboardComponent,
		ObserverComponent,
		NoteTakerComponent,
		RecorderComponent,
		NoSessionComponent,
		TranslatorComponent
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		MainModule,
		HttpClientModule,
		SvgUseModule,
		CheckModule,
		AppFooterModule,
		PageNotFoundModule
	],
	providers: [
		// { provide: ErrorHandler, useClass: GlobalErrorHandler },
		BackgroundMode,
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
