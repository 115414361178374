<div class="pnf">
	<div class="pnf-logo">
		<img [src]="fetchResource('./assets/deepdivelogo.png')" />
	</div>
	<div class="pnf-container">
		<img class="pnf-container-img" src='./assets/alerts/no-session.svg' />
		<div class="pnf-container-title">There is no session ongoing</div>
		<div class="pnf-container-desc">Please join using the link provided to you by the organizer</div>
	</div>
</div>
<app-footer></app-footer>