import { Injectable} from '@angular/core';
import { CommonService } from './common.service';

// capacitor imports
import { Plugins } from '@capacitor/core';
const { ScreenSharing } = Plugins;
import 'screen-sharing';

@Injectable({
	providedIn: 'root'
})
export class TerminateFromYiiService {

    constructor(private commonService: CommonService) {}
    
    public terminateFromYii() {
        this.commonService.stopAllStreams();
        this.commonService.micEnabled = false;
		this.commonService.isMobileMicEnabled = false;
		this.commonService.camEnabled = false;
		this.commonService.screenSharingEnabled = false;
		isMobile && ScreenSharing.stopAllStreams();
    }
}
