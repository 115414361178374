<div class="join">
	<div class="join-box">
		<div class="join-box-img">
			<app-svg name="profile"></app-svg>
		</div>
		<div class="join-box-title"> Happy to see you! </div>
		<div class="join-box-details" *ngIf="!showWaitMsg">
			<div class="join-box-details-input">
				<input
					id="join-box-details-input-boxx"
					class="join-box-details-input-box" type="text"
					placeholder="Enter your name..."
					(keyup)="checkName($event)"
					[(ngModel)]="sessionService.name" #input maxlength="10"/>
					<div class='tooltip'>
						<span *ngIf="showToolTip">Minimum 3 characters</span>
					</div>
				<button class="join-box-details-input-button"
					(click)="joinSession()"
					[class.disable]="disableButton">Join Session
				</button>
			</div>
		</div>
		<div class="join-box-waitmsg" *ngIf="showWaitMsg">
			Let's wait a bit. Others would arrive shortly.
		</div>
		<div class="join-box-loader" *ngIf="loader">
			<img [src]="fetchResource('./assets/loader.gif')"/>
		</div>
	</div>
</div>