import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppFooterModule } from 'src/app/components/app-footer/app-footer.component';
import { PageNotFoundComponent } from './page-not-found.component';
import { SvgUseModule } from '../common/svg-use/svg-use.module';

@NgModule({
	declarations: [
		PageNotFoundComponent
	],
	imports: [
		CommonModule,
		AppFooterModule,
		SvgUseModule
	],
	providers: [],
	exports: [
		PageNotFoundComponent
	]
})

export class PageNotFoundModule { }
