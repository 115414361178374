import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SvgUseModule } from '../svg-use/svg-use.module';
import { CheckComponent } from './check.component';
import { SessionCheckComponent } from './components/session-check/session-check.component';
import { TechCheckModule } from './components/tech-check/tech-check.module';
import { AppFooterModule } from 'src/app/components/app-footer/app-footer.component';
import { SessionWaitModule } from './components/session-wait/session-wait.module';
import { PageNotFoundModule } from '../../page-not-found/page-not-found.module';
import { RoleComponent } from './components/role/role.component';

@NgModule({
    declarations: [
        CheckComponent,
        SessionCheckComponent,
        RoleComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        SvgUseModule,
        TechCheckModule,
        AppFooterModule,
        SessionWaitModule,
        PageNotFoundModule
    ],
    providers: [],
    exports: [
        CheckComponent,
        RoleComponent
    ]
})

export class CheckModule { }
