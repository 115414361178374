<div #container class="notes" *ngIf="!note?.modePlaceholder">
    <!-- content -->
    <div class="notes-content">
        <div class="notes-content-timeStamp">{{ formattedNotesTimeStamp }}</div>
        <div class="notes-content-input">
            <div 
                #textArea
                class="notes-content-input-input"
                [innerHTML]="note?.comment?.trim()"
                [attr.placeholder]="'Enter your comment here...'"
                (keydown)="keyHandler($event)"
                (keydown.enter)="takeNote()"
                contenteditable="true"></div>
            <!-- <app-svg *ngIf="!note" name="send-no-circle" class="notes-content-input-icon" (click)="takeNote()"></app-svg> -->
            <div *ngIf="!note" class="placeholder">{{ placeholderText }}</div>
        </div>
    </div>
</div>

<div class="notes-placeholder" *ngIf="note?.modePlaceholder && note?.comment?.trim()?.length">
    <span class="notes-placeholder-line"></span>
    <span class="notes-placeholder-text">{{ note?.comment?.trim() }}</span>
    <span class="notes-placeholder-line"></span>
</div>