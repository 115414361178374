import { environment } from 'src/environments/environment';
import { Component, ViewEncapsulation, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { SessionService } from 'src/app/services/session.service';
import { Subscription } from 'rxjs';
import { SessionStatus } from 'src/constants';
import { USER_TYPE } from '../../../../../../../../commonConstants';

@Component({
	selector: 'app-session-check',
	templateUrl: './session-check.component.html',
	styleUrls: ['./session-check.component.styl'],
	encapsulation: ViewEncapsulation.ShadowDom
})
export class SessionCheckComponent implements OnDestroy {

	public checkDone = false;
	public checkFail = false;
	public failReason = null;
	public failTitle = null;
	public showLoader = false;
	public showRejoinBtn = false;
	public isRoomFull = false;
	public showSwitchRole = false;

	public moveToTechCheckForMobile = false;
	public fetchResource = fetchResource;
	private sub: Subscription = null;
	public SessionStatus = SessionStatus;

	constructor(
		public commonService: CommonService,
		public sessionService: SessionService,
		private changeDetectorRef: ChangeDetectorRef) {
			this.fetchSessionDetails();
	}

	public showRoleConfirmation: boolean = false;
	public USER_TYPE = USER_TYPE;

	public proceedWithRole() {
		this.showRoleConfirmation = false;
	}

	private fetchSessionDetails() {
		this.isRoomFull = false;
		this.sub = this.commonService.joiningKeyChangeListener.subscribe(key => {
			if (key?.trim()?.length) {
				this.failReason = null;
				const check = () => {
					this.commonService.fetchSessionDetails(key).then((details) => {
						this.checkFail= false;
						if(!details.chiefModerator && details.userType == USER_TYPE.MODERATOR) {
							details.userId = 'Co-Facilitator';
						}
						this.sessionService.setSessionDetails(details);
						this.checkDone = true;
						if(details.userType == USER_TYPE.TESTER || details.chiefModerator) {
							this.showRoleConfirmation = false;
						} else {
							this.showRoleConfirmation = true;
						}
						this.changeDetectorRef.detectChanges();
					}).catch(err => {
						let msg = err.msg;
						if (!msg) {
							msg = 'There seems to be some trouble at our server, please try again';
						}
						if(typeof msg === 'object') {
							this.failReason = msg?.msg ?? 'There seems to be some trouble at our server, please try again';
							if(msg?.roomFull == false) {
								this.showLoader = true;
								this.failTitle = 'Waiting for the Moderator';
								// check every 5 seconds
								setTimeout(check, 1000 * 5);
							} else {
								this.isRoomFull = true;
								this.failTitle = 'Ouch!';
								this.showRejoinBtn = true;
							}
						} else
							this.failReason = msg;
						if(err.data) {
							if(!err.data.chiefModerator && err.data.userType == USER_TYPE.MODERATOR) {
								err.data.userId = 'Co-Facilitator';
							}
							this.sessionService.setSessionDetails({
								userId: err.data.userType,
								userType: err.data.userType,
								sessionId: err.data.mediaSessionKey,
								links: err.data.links,
								chiefModerator: err.data.chiefModerator
							});
						}
						this.checkDone = true;
						this.checkFail = true;
						this.showRoleConfirmation = false;
						this.changeDetectorRef.detectChanges();
					});
				};
				check();	
			}
		});
	}

	public rejoinSession() {
		if((this.sessionService.userType != USER_TYPE.TESTER && !this.sessionService.isChiefModerator) && this.isRoomFull) {
			this.showSwitchRole = true;
		} else {
			this.showSwitchRole = false;
			window.location.reload();
		}
	}

	ngOnDestroy() {
		this.sub && this.sub.unsubscribe();
	}
}
