import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SvgUseModule } from '../../../svg-use/svg-use.module';
import { JoinComponent } from '../join/join.component';

@NgModule({
    declarations: [
        JoinComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        SvgUseModule
    ],
    providers: [],
    exports: [
        JoinComponent
    ]
})

export class JoinModule { }
