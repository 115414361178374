import { Injectable, ApplicationRef } from '@angular/core';
import { resolve } from 'path';
import { StreamType, VIDEO_MAX_WIDTH, VIDEO_MAX_HEIGHT, VIDEO_MAX_FPS } from 'src/constants';

let mediaStreamConstraints: MediaTrackConstraints = {
	cursor: 'always',
	displaySurface: 'application',
	width: { min: 640, ideal: VIDEO_MAX_WIDTH, max: 1280 },
	height: { min: 480, ideal: VIDEO_MAX_HEIGHT, max: 720 },
	frameRate: { min: 5, ideal: VIDEO_MAX_FPS, max: parseFloat((VIDEO_MAX_FPS).toFixed(2)) },
	resizeMode: 'crop-and-scale',
	echoCancellation: true,
	noiseSuppression: true,
	aspectRatio: { max: parseFloat((16/9).toFixed(2)), min: parseFloat((16/9).toFixed(2)) }
};

@Injectable({
	providedIn: 'root'
})
export class StreamService {

	public streams = new Map<StreamType, MediaStream>();
	public allCapturedStreams = new Map<StreamType, MediaStream[]>();

	constructor(private applicationRef: ApplicationRef) {
	}

	private captureCamera(): Promise<MediaStream> {
		return new Promise((resolve, reject) => {
			if (!navigator || !navigator.mediaDevices || !navigator.mediaDevices.getUserMedia || typeof navigator.mediaDevices.getUserMedia != 'function') {
				reject(this.getRejectMessage('camera'));
				return;
			}
			const streamPromise = navigator.mediaDevices.getUserMedia({
				video: true
			});
			resolve(this.processStream(streamPromise, StreamType.CAMERA));
		});
	}

	private captureScreen(techCheck: boolean = false): Promise<MediaStream> {
		return new Promise((resolve, reject) => {
			if(techCheck) {
				if (!navigator || !navigator.mediaDevices || !navigator.mediaDevices.getDisplayMedia || typeof navigator.mediaDevices.getDisplayMedia != 'function') {
					reject(this.getRejectMessage('screen sharing'));
					return;
				}
				const streamPromise = navigator.mediaDevices.getDisplayMedia({
					video: true
				});
				resolve(this.processStream(streamPromise, StreamType.SCREEN, techCheck));
			} else {
				resolve(this.processStream(null, StreamType.SCREEN, techCheck));
			}
		});
	}

	private captureAudio(): Promise<MediaStream> {
		return new Promise((resolve, reject) => {
			if (!navigator || !navigator.mediaDevices || !navigator.mediaDevices.getUserMedia || typeof navigator.mediaDevices.getUserMedia != 'function') {
				reject(this.getRejectMessage('microphone'));
				return;
			}
			const streamPromise = navigator.mediaDevices.getUserMedia({
				audio: true
			});
			resolve(this.processStream(streamPromise, StreamType.AUDIO));
		});
	}

	private handleError(err: DOMException, streamType: StreamType) {
		logger.error('Error: ', err, err.code, err.message);
		let msg = 'Sorry an error is not allowing to capture device, Error: ';
		msg += err.message && err.message.trim() !== '' ? err.message : err.name;
		// handle error based on error code and display alert
		let device;
		switch (streamType) {
			case StreamType.AUDIO: device = 'Microphone'; break;
			case StreamType.CAMERA: device = 'Camera'; break;
			case StreamType.SCREEN: device = 'Screen Sharing'; break;
			default: break;
		}
		switch (err.name) {
			case 'NOT_FOUND_ERR':
			case 'NotFoundError':
				msg = `Please check your ${device} if it is attached properly and is in working condition. Refresh after fixing.`;
				break;
			case 'NotAllowedError':
				msg = `Please allow access to ${device} ${!isMobile ? ', also check if this browser has permission to use the device in your OS\'s privacy settings' : ''}`;
				break;
			case 'NotReadableError':
				msg = `There seems to be some trouble with your ${device}, please check if it is enabled or is in working condition`;
				break;
		}
		return msg;
	}

	private applyConstraints(stream: MediaStream, mediaTrackConstraints: MediaTrackConstraints): Promise<MediaStream> {
		return new Promise((resolve, reject) => {	
			mediaTrackConstraints = JSON.parse(JSON.stringify(mediaTrackConstraints));
			if (mediaTrackConstraints && typeof mediaTrackConstraints == 'object' && stream && stream.getTracks && stream.getTracks().length) {
				const tracks = stream.getTracks();
				tracks.forEach(track => {
					const trackCapabilites = track.getCapabilities();
					logger.log('Current TrackCapabilites of stream:', JSON.stringify(trackCapabilites));
					for (const [key, value] of Object.entries(mediaTrackConstraints)) {
						const capability = trackCapabilites[key];
						if (capability) {
							switch (typeof capability) {
								case 'object':
									// check if array or native object
									if (capability.length) {
										if (capability.indexOf(value) == -1) {
											// delete from object
											delete mediaTrackConstraints[key];
										} else {
											// keep in set
										}
									} else if (typeof value === 'object') {
										for (const [innerKey, innerValue] of Object.entries(value)) {
											if (capability[innerKey] !== undefined && typeof capability[innerKey] === typeof innerValue) {
												// keep in set
											} else {
												// delete in object
												delete mediaTrackConstraints[key][innerKey];
											}
										}
									} else {
										// delete from object
										delete mediaTrackConstraints[key];
									}
									break;
								default:
									// delete from object
									delete mediaTrackConstraints[key];
									break;
							}
						} else {
							// delete from object
							delete mediaTrackConstraints[key];
						}
					}
					logger.log('Applying constraints to stream:', JSON.stringify(mediaTrackConstraints));
					track.applyConstraints(mediaTrackConstraints).finally(() => {
						logger.log('New TrackCapabilites of stream', JSON.stringify(track.getSettings()));
						resolve(stream);
					});
				});
			} else {
				resolve(stream);
			}
		});
	}

	private processStream(promise: Promise<MediaStream>, streamType: StreamType, techCheck: boolean = false): Promise<MediaStream> {
		if(!techCheck && !isMobile && streamType == StreamType.SCREEN) {
			// for desktop screen sharing (not tech check)
			return new Promise((resolve, reject) => {
				resolve();
			});
		} else {
			let mediaTrackConstraints = {...mediaStreamConstraints};
			return new Promise((resolve, reject) => {
				promise
					.then(
						(stream) =>
							this.applyConstraints(stream, mediaTrackConstraints)
								.then(resolve)
								.catch(() => resolve(stream))
					).catch((err) => reject(this.handleError(err, streamType)));
			});
		}
	}

	public addStream(type: StreamType, techCheck: boolean = false): Promise<MediaStream> {
		return new Promise((resolve, reject) => {
			let captureStream;
			switch (type) {
				case StreamType.AUDIO: captureStream = this.captureAudio(); break;
				case StreamType.CAMERA: captureStream = this.captureCamera(); break;
				case StreamType.SCREEN: captureStream = this.captureScreen(techCheck); break;
				default:
					break;
			}
			if (captureStream) {
				captureStream.then((stream: MediaStream) => {
					if(techCheck) {
						this.streams.set(type, stream);
						if (!this.allCapturedStreams.has(type)) {
							this.allCapturedStreams.set(type, []);
						}
						this.allCapturedStreams.get(type).push(stream);
					}
					resolve(stream);
				}).catch(err => {
					// show alert
					alert({ text: err, icon: ICON.ERROR });
					logger.log(err);
					reject(err);
				});
			} else {
				logger.log('Invalid stream type: ', type);
				reject();
			}
		});
	}

	public removeStream(type: StreamType) {
		if (this.allCapturedStreams.has(type) && this.allCapturedStreams.get(type).length) {
			logger.info('Removing stream of type: ', type);
			this.allCapturedStreams.get(type).map(stream => this.stopStream(stream));
			this.allCapturedStreams.set(type, []);
		}
		if (this.streams.has(type)) {
			this.stopStream(this.streams.get(type));
			this.streams.delete(type);
		}
	}

	private stopStream(stream: MediaStream) {
		logger.info('Stopping stream: ', stream);
		stream && stream.getTracks && stream.getTracks().forEach(track => track.stop());
	}

	public stopAllStreams() {
		logger.info('Stopping all streams: ');
		this.streams.forEach((value, type) => this.removeStream(type));
		this.streams.clear();
		this.allCapturedStreams.clear();
	}

	public scaleToFit(video: HTMLVideoElement, canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D) {
		if (!video.videoHeight || !video.videoWidth) {
			logger.error('Image does not have width or height', video);
			return false;
		}

		const canvasWidth = canvas.width ? canvas.width : VIDEO_MAX_WIDTH;
		const canvasHeight = canvas.height ? canvas.height : VIDEO_MAX_HEIGHT;

		// get the scale
		const imageW = parseInt(video.videoWidth.toString());
		const imageH = parseInt(video.videoHeight.toString());
		const scale = Math.min(canvasWidth / imageW, canvasHeight / imageH);
		// get the top left position of the image
		const x = (canvasWidth / 2) - (imageW / 2) * scale;
		const y = (canvasHeight / 2) - (imageH / 2) * scale;
		ctx.drawImage(video, x, y, imageW * scale, imageH * scale);
		this.applicationRef.tick();
		return true;
	}

	private getRejectMessage(stream: any) {
		if (isMobile) {
			return 'This device is not fit for use with this app, Please use some other device';
		}
		else {
			return `This browser is old and does not support to access to ${stream}, Please use latest version of Chrome or Firefox`;
		}
	}
}
