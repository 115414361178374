import { environment } from 'src/environments/environment';
import { Component, ViewEncapsulation } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { RequestService } from 'src/app/services/request.service';
import { SessionService } from 'src/app/services/session.service';

@Component({
	selector: 'app-session-wait',
	templateUrl: './session-wait.component.html',
	styleUrls: ['./session-wait.component.styl'],
	encapsulation: ViewEncapsulation.ShadowDom
})
export class SessionWaitComponent {

	public msg = '';
	public subMsg = '';
	public fetchResource = fetchResource;

	constructor(public commonService: CommonService,
		public requestService: RequestService,
		public sessionService: SessionService) {
		this.check();
	}

	private secondsToHms(d) {
		d = Number(d);
		let h: any = Math.floor(d / 3600);
		let m: any = Math.floor(d % 3600 / 60);
		let s: any = Math.floor(d % 3600 % 60);
	
		h = h < 10 ? "0" + h : h;
		m = m < 10 ? "0" + m : m;
		s = s < 10 ? "0" + s : s;
	  
		return h + ':' + m + ':' + s; 
	}

	private check() {
		const currentTime = Date.now();
		const allowedTime = currentTime + 15 * 60 * 1000; // allowed to join early
		if (this.commonService.sessionStartTime && this.commonService.sessionStartTime > allowedTime) {
			this.commonService.sessionInWaiting = true;
			this.msg = 'Session will start in ' + this.secondsToHms((this.commonService.sessionStartTime - currentTime) /1000);
			this.subMsg = 'You may wait or come back later';
			setTimeout(() => this.check(), 1000);
		} else {
			this.commonService.sessionInWaiting = true;
			// logger.log('---- sessionWait, Timer is completed, Checking for ChiefModerator is available or not in the session');
			if(!this.sessionService.isChiefModerator) {
				// logger.log('---- sessionWait, User is not ChiefModerator, Checking if ChiefModerator is present in the session');
				this.isChiefModeratorPresent().then(present => {
					if(present) {
						// logger.log('---- sessionWait, ChiefModerator is present in the session, Allow user to join');
						this.commonService.sessionInWaiting = false;
					} else {
						// logger.log('---- sessionWait, ChiefModerator is not present in the session, Will check again after 5 seconds');
						this.commonService.sessionInWaiting = true;
						this.msg = 'Waiting for Moderator to start the Session';
						this.subMsg = '';
						setTimeout(() => this.check(), 5000);
					}
				});
			} else {
				// logger.log('---- sessionWait, User is ChiefModerator, Allow him to join');
				this.commonService.sessionInWaiting = false;
			}
		}
	}

	private async isChiefModeratorPresent() {
		let present: boolean = false;
		await this.requestService.isChiefModeratorPresent(this.sessionService.sessionId, this.sessionService.userType, this.sessionService.isChiefModerator).then(({isChiefModeratorPresent, activeUserTypeConnections}) => {
			if(isChiefModeratorPresent) {
				present = true;
			} else {
				present = false;
			}
		}).catch(() => {
			present = false;
		});
		return present;
	}
}
