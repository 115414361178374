import { Injectable, OnDestroy } from '@angular/core';
import { SessionService } from './session.service';
import { MessageType, USER_TYPE, ConnectionEventMessage, ConnectionEventType, ChatRoomMember, ChatRoom, Chat, StreamEventType, StreamType, Message, SessionMode, MemberMap } from 'src/constants';
import { Subscription, BehaviorSubject } from 'rxjs';
import { CommonService } from './common.service';

@Injectable({
	providedIn: 'root'
})
export class ConnectionService implements OnDestroy {

	public members: MemberMap = new Map<MessageType, ChatRoomMember[]>();
	private colors = {
		[USER_TYPE.TESTER]: '#988DE6',
		[USER_TYPE.MODERATOR]: '#526FD8',
		[USER_TYPE.OBSERVER]: '#79E1D0',
		[USER_TYPE.NOTE_TAKER]: '#FFBB48',
		[USER_TYPE.TRANSLATOR]: '#988DE6'
	};

	private subscriptions: Array<Subscription> = [];
	// peer joining/leaving events
	private memberChangeEventSubject: BehaviorSubject<MemberMap> = new BehaviorSubject<MemberMap>(this.members);
	public memberChangeEventListener = this.memberChangeEventSubject.asObservable();

	public activeChats: Array<Chat> = [];

	// private numberOfJoineesInCall: number = 0;
	// private numberOfJoineesInCallSubject = new BehaviorSubject<number>(0);
	// public numberOfJoineesInCallListener = this.numberOfJoineesInCallSubject.asObservable();

	constructor(
		private sessionService: SessionService,
		private commonService: CommonService) {

		// add default members
		const chatRoomTypes = [MessageType.TEAM, MessageType.TESTER_MODERATOR];
		chatRoomTypes.forEach(type => {
			if (!this.members.has(type)) {
				this.members.set(type, []);
			}
		});

		// handle new connections/joinees and adds them in their member list
		this.handleNewConnections();
	}

	private handleNewConnections() {
		const connectionSub = this.sessionService.connectionEventListener.subscribe((msg: ConnectionEventMessage) => {
			if (msg && msg.type) {
				let chatroomTypes: MessageType[] = [];
				switch (this.sessionService.userType) {
					// if self tester, then only add moderator to TESTER_MODERATOR, ignore rest
					case USER_TYPE.TESTER:
						if (msg.data.userType === USER_TYPE.MODERATOR) {
							chatroomTypes = [MessageType.TESTER_MODERATOR];
						}
						break;
					// if self moderator, then add Tester to TESTER_MODERATOR, moderator to both, rest to team
					case USER_TYPE.MODERATOR:
						if (msg.data.userType === USER_TYPE.TESTER) {
							chatroomTypes = [MessageType.TESTER_MODERATOR];
						}
						else if (msg.data.userType === USER_TYPE.MODERATOR) {
							chatroomTypes = [MessageType.TEAM, MessageType.TESTER_MODERATOR];
						} else {
							chatroomTypes = [MessageType.TEAM];
						}
						break;
					// for all other users, add Tester to TESTER_MODERATOR, moderator to both and rest to TEAM
					default:
						if (msg.data.userType === USER_TYPE.MODERATOR) {
							chatroomTypes = [MessageType.TEAM, MessageType.TESTER_MODERATOR];
						}
						else if (msg.data.userType === USER_TYPE.TESTER) {
							chatroomTypes = [MessageType.TESTER_MODERATOR];
						}
						else {
							chatroomTypes = [MessageType.TEAM];
						}
						break;
				}

				let sendOnce = false;
				// for each chat room type assign a name and color to new connnection
				if (msg.type === ConnectionEventType.CONNECTION_CREATED) {
					// this.numberOfJoineesInCall++;
					// send info of if mic is enabled to others, mute is opposite of micEnabled
					this.sessionService.sendMessage({ msg: (!this.commonService.micEnabled).toString(), time: Date.now() }, MessageType.MUTE_EVENT);

					// send new instructions to all
					// send to participant
					this.sessionService.sendMessage({ msg: this.commonService.instructions.toString(), time: Date.now() }, MessageType.INSTRUCTIONS_CHANGE);

					// update participant name
					if (this.sessionService.userType === USER_TYPE.TESTER && this.sessionService.testerName.trim().length) {
						this.sessionService.sendMessage({
							msg: this.sessionService.testerName,
							time: Date.now()
						}, MessageType.TESTER_NAME);
					}
				}
				// else {
				// 	this.numberOfJoineesInCall--;
				// }

				chatroomTypes.forEach(type => {
					if (msg.type === ConnectionEventType.CONNECTION_CREATED) {
						logger.log('Adding member, with connectionId: ', msg.data.connectionId, ' in chat room type: ', type);
						// assign a name and color of the avatar icon, name: 'Entered Name (UserType)'
						const member: ChatRoomMember = {
							...msg.data,
							alias: msg.data.name
						};
						member.name = `${msg.data.name} (${msg.data.userId.capitalizeFirstLetter()})`;
						member.color = this.fetchNewColor(msg.data.userType);

						if (!this.members.has(type)) {
							this.members.set(type, []);
						}
						this.members.get(type).push(member);
						if (!sendOnce) {
							this.sessionService.streamEventSubject.next({
								type: StreamEventType.STREAM_ADDED,
								streamId: member.connectionId,
								kind: StreamType.PLACEHOLDER,
								from: member,
								actualConnectionID: member.connectionId
							});
							sendOnce = true;
						}
					} else {
						logger.log('Removing members with connectionId', msg.data.connectionId, ' in chat room type: ', type);
						if (this.members.has(type)) {
							const index = this.members.get(type).findIndex(i => i.connectionId === msg.data.connectionId);
							if (index !== -1) {
								const member = this.members.get(type)[index];
								this.members.get(type).splice(index, 1);
								if (!sendOnce) {
									this.sessionService.streamEventSubject.next({
										type: StreamEventType.STREAM_REMOVED,
										streamId: member.connectionId,
										kind: StreamType.PLACEHOLDER,
										from: member,
										actualConnectionID: member.connectionId
									});
									sendOnce = true;
								}
							}
						}
					}
					logger.log('List of members of chat room type: ', type, ' members: ', this.members.get(type));
				});

				// notify about members
				if (chatroomTypes.length && !msg.silent && !this.sessionService.reconnecting) {
					this.notify(msg.type, `${msg.data.name} (${msg.data.userId.capitalizeFirstLetter()})`);
				}
				this.memberChangeEventSubject.next(this.members);
			}
		});

		this.subscriptions.push(connectionSub);
	}

	// to assign a new color to the new user
	private fetchNewColor(userType: USER_TYPE) {
		return this.colors[userType] ? this.colors[userType] : '#C17AED';
	}

	// sets chat rooms used in chatbox component
	public setChatRooms(chatRooms: Array<ChatRoom>) {
		chatRooms.forEach((chatRoom: ChatRoom) => {
			if(!chatRoom?.type)
				return;

			this.sessionService.handleMessageEvents(chatRoom.type);
			if (!this.members.has(chatRoom.type)) {
				this.members.set(chatRoom.type, []);
			}
			const messages: Message[] = [];
			this.sessionService.previousChats[chatRoom.type]?.forEach(d => {
				let hideName = false;
				let hideTime = false;
				const localTimeString = this.commonService.formatTimeStamp(d?.timestamp);
				if(messages.length && messages[messages.length - 1]?.from?.connectionId === d?.connectionId) {
					hideName = true;
					if(messages[messages.length - 1]?.localTimeString === localTimeString)
						hideTime = true;
				}
				messages.push({
					from: {
						connectionId: d?.connectionId ?? '',
						alias: d.userName,
						userType: this.sessionService.userType === USER_TYPE.TESTER && d.userRole === USER_TYPE.TESTER ? USER_TYPE.SELF : d.userRole,
						name: `${d.userName} (${d.userId?.capitalizeFirstLetter()})`,
						color: this.colors[d.userRole],
						isChiefModerator: false,
						isMobile: false,
						userId: null,
						streamType: null,
					},
					msg: d.comment,
					time: d.timestamp,
					hideName,
					hideTime,
					localTimeString
				});
			});
			this.activeChats.push({
				...chatRoom,
				messages,
				message: '',
				members: this.members.get(chatRoom.type),
				isMenuOpen: false
			});
		});
		this.activeChats = [...this.activeChats];
	}

	// shows toast notification of joining/leaving session
	private notify(eventType: ConnectionEventType, name: string) {
		let msg;
		switch (eventType) {
			case ConnectionEventType.CONNECTION_CREATED:
				msg = name + ' has joined';
				// this.commonService.playNotification();
				break;
			case ConnectionEventType.CONNECTION_DESTROYED:
				msg = name + ' has left';
				// this.commonService.playNotification();
				break;
		}
		if (msg) {
			const chat = this.activeChats.find(d => d.type == MessageType.TEAM);
			if (chat) {
				chat.messages.push({
					type: eventType,
					msg
				});
			}
		}
	}

	ngOnDestroy() {
		this.subscriptions.forEach(sub => sub && sub.unsubscribe());
		// this.numberOfJoineesInCallSubject.unsubscribe();
	}
}
