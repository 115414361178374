<div class="dashboard" *ngIf="!noSession">
	<!-- Before Call starts -->
	<app-check *ngIf="sessionService.sessionStatus === SessionStatus.SESSION_NOT_STARTED"></app-check>

	<!-- During Call -->
	<div class="dashboard-content" *ngIf="sessionService.sessionStatus === SessionStatus.SESSION_STARTED">
		<app-tester *ngIf="sessionService.userType == USER_TYPE.TESTER"></app-tester>
		<app-moderator *ngIf="sessionService.userType == USER_TYPE.MODERATOR"></app-moderator>
		<app-observer *ngIf="sessionService.userType == USER_TYPE.OBSERVER"></app-observer>
		<app-note-taker *ngIf="sessionService.userType == USER_TYPE.NOTE_TAKER"></app-note-taker>
		<app-translator *ngIf="sessionService.userType == USER_TYPE.TRANSLATOR"></app-translator>
	</div>

	<!-- After call ends -->
	<div class="dashboard-session" *ngIf="sessionService.sessionStatus === SessionStatus.SESSION_ENDED">
		<div class="dashboard-session-logo">
			<img [src]="fetchResource('./assets/deepdivelogo.png')" />
		</div>
		<div class="dashboard-session-img">
			<app-svg name="call-ended"></app-svg>
		</div>
		<div class="dashboard-session-title">
			Call Ended
		</div>
		<div class="dashboard-session-text">
			Thanks for attending the session
		</div>
		<div>
			<button *ngIf="sessionService.userType == USER_TYPE.TESTER ? sessionService.sessionMode == SessionMode.IN_SESSION : true " class="dashboard-session-rejoinBtn" (click)="rejoinSession()">Rejoin Session</button>
		</div>
		<app-footer></app-footer>
	</div>
</div>

<app-no-session *ngIf="noSession"></app-no-session>