<div class="message" [class.self]="self" [class.joinStatus]="joinStatus" *ngIf="content?.length">
	<!-- Message Card -->
	<div class="message-card" *ngIf="!joinStatus" [class.noPadding]="!msg?.hideName">
		<!-- Avatar icon with member color -->
		<div class="message-card-info">
			<div class="message-card-info-detail" *ngIf="!msg?.hideName">
				<span
					class="message-card-info-detail-thumbnail"
					[style.background]="msg?.from?.color || '#988DE6'">
					{{ self ? 'Y' : commonService.getInitialsFromName(msg?.from?.alias) }}
				</span>
				<span class="message-card-info-detail-name">{{ self ? 'You' : msg?.from?.name }}</span>
			</div>
			<div class="message-card-info-detail" *ngIf="self"></div>
			<span class="message-card-info-timestamp" *ngIf="!msg?.hideTime">{{ msg?.localTimeString }}</span>
		</div>
		<!-- Info on top of card, timetamp and name -->
		
		<!-- Actual message text -->
		<div class="message-card-body">
			<ng-container *ngFor="let item of content;">
				<a *ngIf="item?.type == TextType.LINK" class="message-card-body-link" 
					(click)="commonService.openLink(item?.link)">{{ item?.value }}</a>
				<span *ngIf="item?.type == TextType.TEXT">{{ item?.value }}</span>
			</ng-container>
		</div>

		<!-- Sent status icons -->
		<div class="message-card-status" *ngIf="self">
			<!-- <div class="message-card-status-pending" *ngIf="msg?.status == MessageSentStatus.UNSENT">
				<app-loader name="spinner-2" style="width:1.5rem"></app-loader>
			</div> -->
			<!-- <div class="message-card-status-success" *ngIf="msg?.status == MessageSentStatus.SENT">
				<app-svg name="tick"></app-svg>
			</div> -->
			<div class="message-card-status-fail" *ngIf="msg?.status === MessageSentStatus.FAIL">
				<app-svg name="error"></app-svg>
				<app-svg name="retry" style="cursor: pointer" (click)="retry()"></app-svg>
			</div>
		</div>
	</div>
	<div class="message-joinStatus" *ngIf="joinStatus">
		<ng-container *ngFor="let item of content;">
			<span
				class="message-joinStatus-icon message-joinStatus-icon-join"
				*ngIf="item.status == ConnectionEventType.CONNECTION_CREATED"></span>
			<span
				class="message-joinStatus-icon message-joinStatus-icon-leave"
				*ngIf="item?.status == ConnectionEventType.CONNECTION_DESTROYED"></span>
			<span class="message-joinStatus-text">{{ item?.value }}</span>
		</ng-container>
	</div>
</div>
