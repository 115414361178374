import { environment } from 'src/environments/environment';
import { Component, ViewEncapsulation, Input } from '@angular/core';

@Component({
    selector: 'app-page-not-found',
	templateUrl: './page-not-found.component.html',
	styleUrls: ['./page-not-found.component.styl'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class PageNotFoundComponent {
    public fetchResource = fetchResource;

    public text: string;
    @Input() set failReason(value) {
        if (value && value.trim() != '') {
            this.setText(value);
        }
    }

    public setText(value = `The System could not identify that link. Please contact the organiser of this research for an updated link.`) {
        this.text = value;
    }

    constructor() {
        this.setText();
    }
}
