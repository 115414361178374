import { Component, ViewEncapsulation } from '@angular/core';

@Component({
	selector: 'app-check',
	templateUrl: './check.component.html',
	styleUrls: ['./check.component.styl'],
	encapsulation: ViewEncapsulation.ShadowDom
})
export class CheckComponent {

	constructor() {
	}

}
