<div class="check-browser" *ngIf="checkItem">
    <div class="check-browser-image">
        <app-svg name="checkBrowser" *ngIf="checkItem.status == TECH_CHECK_STATUS.PROGRESS"></app-svg>
        <app-svg name="checkBrowserPass" *ngIf="checkItem.status == TECH_CHECK_STATUS.SUCCESS"></app-svg>
        <app-svg name="checkBrowserFail" *ngIf="checkItem.status == TECH_CHECK_STATUS.FAILED"></app-svg>
    </div>
    <div class="check-browser-message">
        <span *ngIf="checkItem.status == TECH_CHECK_STATUS.PROGRESS">Checking {{ deviceName }} compatibility</span>
        <span *ngIf="checkItem.status == TECH_CHECK_STATUS.SUCCESS">{{ deviceName }} is compatible!</span>
        <span *ngIf="checkItem.status == TECH_CHECK_STATUS.FAILED">{{ deviceName }} compatibility</span>
    </div>
    <div class="check-browser-instruction">
        <span *ngIf="checkItem.status == TECH_CHECK_STATUS.PROGRESS">
            <div class="check-browser-instruction-progress">
                <div class="check-browser-instruction-progress-container" [ngStyle]="{'width': progressTimer + '%'}"></div>
            </div>
        </span>
        <span *ngIf="checkItem.status == TECH_CHECK_STATUS.SUCCESS"></span>
        <span class="check-browser-instruction-fail" *ngIf="checkItem.status == TECH_CHECK_STATUS.FAILED">{{checkItem.failReason}}</span>
    </div>
    <div class="check-browser-button" *ngIf="checkItem.status == TECH_CHECK_STATUS.SUCCESS">
        <button class="check-browser-button-btn" (click)="testCompleted()">Proceed to check Camera</button>
    </div>
</div>