import { ChangeDetectorRef, Component, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { ConnectionService } from 'src/app/services/connection.service';
import { SessionService } from 'src/app/services/session.service';
import { ChatRoom, MessageType, CallOptions, SessionMode, RecordingEventType } from 'src/constants';

@Component({
    selector: 'app-translator',
    templateUrl: './translator.component.html',
    styleUrls: ['./translator.component.styl'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class TranslatorComponent {

    private chatRooms: Array<ChatRoom> = [
        {
			title: 'Participant',
			type: MessageType.TESTER_MODERATOR,
			silent: true
		},
		{
			title: 'Internal',
			type: MessageType.TEAM
		}
	];

	private callOptions: CallOptions = {
		mic: true,
		cam: true,
		screen: false,
		endSession: false,
		notes: false
	};

	private subscriptions: Array<Subscription> = [];

	constructor(
		private commonService: CommonService,
		private connectionService: ConnectionService,
		private sessionService: SessionService,
		private changeDetectorRef: ChangeDetectorRef) {
	}

	ngAfterViewInit() {
		// setting up different tabs in chat window
		this.connectionService.setChatRooms(this.chatRooms);
		this.commonService.callOptions = this.callOptions;
		this.changeDetectorRef.detectChanges();

		const sub = this.sessionService.sessionModeChangeListener.subscribe(sessionState => {
			if (sessionState.sessionMode && this.sessionService.sessionMode !== sessionState.sessionMode) {
				switch (sessionState.sessionMode) {
					case SessionMode.DEBRIEFING:
						this.sessionService.forceDisconnectParticipant();
						if (this.sessionService.sessionMode === SessionMode.IN_SESSION) {
							infoBox('The Session has ended');
							this.sessionService.recordingStatus = RecordingEventType.RECORDING_STOPPED;
						}
						break;
					case SessionMode.IN_SESSION:
						!sessionState.supressAlert && infoBox('The Session has started');
						break;
					default: break;
				}
				this.sessionService.sessionMode = sessionState.sessionMode;
				this.changeDetectorRef.detectChanges();
			}
		});
		this.subscriptions.push(sub);
	}

	ngOnDestroy() {
		this.subscriptions?.forEach(sub => sub?.unsubscribe());
        this.subscriptions = [];
	}
}
