<div class="main">
	<div class="main-body">
		<div class="main-body-content">
			<div class="main-body-content-video">
				<app-video-player>
				</app-video-player>
			</div>
			<div class="main-body-content-audio">
				<app-audio-player></app-audio-player>
			</div>
			<div class="main-body-content-rightOptions">
				<!-- Right Panel is open -->
				<div class="main-body-content-rightOptions-close" [class.hidePanel]="!commonService.isRightPanelOpened">
					<!-- Close Icon -->
					<div class="main-body-content-rightOptions-close-icon"
						(click)="commonService.toggleRightPanel(false)">
						<app-svg name="arrowOpen"></app-svg>
					</div>
					<!-- Panel contains only icon and the way to enable them, not the acutal items -->
					<div class="main-body-content-rightOptions-close-tab">
						<!-- Chat -->
						<div class="main-body-content-rightOptions-close-tab-item"
							[class.activeTab]="commonService.chatInFocus" (click)="commonService.toggleChat(true)">
							<span class="main-body-content-rightOptions-close-tab-item-icon"appToolTip="Toggle Chat Panel {{getShortcutKey('Alt', 'C')}}">
								<app-svg name="chat"></app-svg>
							</span>
							<span class="main-body-content-rightOptions-close-tab-item-text">Chat</span>
						</div>
						<!-- Video Panel -->
						<div class="main-body-content-rightOptions-close-tab-item"
							[class.activeTab]="commonService.videoPanelInFocus"
							(click)="commonService.toggleVideoPanel(true)">
							<span class="main-body-content-rightOptions-close-tab-item-icon"appToolTip="Toggle Members Panel {{getShortcutKey('Alt', 'V')}}">
								<app-svg name="videoPanel"></app-svg>
							</span>
							<span class="main-body-content-rightOptions-close-tab-item-text">Members</span>
						</div>
					</div>
				</div>
				<!-- Right Panel is closed -->
				<div class="main-body-content-rightOptions-open" *ngIf="!commonService.isRightPanelOpened"
					(click)="commonService.toggleRightPanel(true)">
					<app-svg name="arrowOpen"></app-svg>
				</div>
			</div>
		</div>
		<div class="main-body-chat" [class.hide]="!commonService.chatInFocus">
			<app-chat-box></app-chat-box>
		</div>
		<div class="main-body-instructions" [class.hide]="!commonService.instructionsInFocus"
			[class.shift]="commonService.isRightPanelOpened || commonService.notesInFocus">
			<app-instructions></app-instructions>
		</div>
		<div class="main-body-bottom-bar">
			<div class="main-body-bottom-bar-middle"
				[class.endSession]="commonService.callOptions.endSession && sessionService.sessionMode === SessionMode.IN_SESSION"
				*ngIf="commonService.callOptions && objectKeys(commonService.callOptions).length">
				<div style="min-width: 20rem;">
					<div class="main-body-bottom-bar-middle-left"
						[class.DEBRIEFING]="sessionService.sessionMode === SessionMode.DEBRIEFING">
						<div class="main-body-bottom-bar-middle-item-sessionMode"
							*ngIf="sessionService.userType != USER_TYPE.TESTER">
							<div class="main-body-bottom-bar-middle-item-sessionMode"
								[class.active]="sessionService.showSessionModesPopup"
								style="cursor: pointer;" 
								(mouseover)="sessionService.showSessionModesPopup=true" 
								(mouseleave)="sessionService.showSessionModesPopup=false">
								<app-svg name="mode-icon-briefing" *ngIf="sessionService.sessionMode !== SessionMode.IN_SESSION"></app-svg>
								<app-svg name="mode-icon-insession" *ngIf="sessionService.sessionMode === SessionMode.IN_SESSION"></app-svg>
								<span class="main-body-bottom-bar-middle-item-sessionMode-text">
									{{ sessionService.sessionMode === SessionMode.IN_SESSION
										?
										'In Session'
										:
										sessionService.recordingStatus === RecordingEventType.RECORDING_STOPPED
											?
											'Debriefing'
											:
											'Briefing'
									}}
								</span>
								<span *ngIf="sessionService.sessionMode === SessionMode.IN_SESSION"
									class="main-body-bottom-bar-middle-item-sessionMode-text-sub"> with
									{{ sessionService.testerName }}</span>
							</div>
							<div class="main-body-bottom-bar-middle-item-sessionMode-popup" *ngIf="sessionService.showSessionModesPopup">
								<div class="main-body-bottom-bar-middle-item-sessionMode-popup-item">
									<div class="main-body-bottom-bar-middle-item-sessionMode-popup-item-icon">
										<app-svg name="mode-current-briefing" *ngIf="sessionService.sessionMode === SessionMode.DEBRIEFING && sessionService.recordingStatus !== RecordingEventType.RECORDING_STOPPED"></app-svg>
										<app-svg name="mode-done" *ngIf="!(sessionService.sessionMode === SessionMode.DEBRIEFING && sessionService.recordingStatus !== RecordingEventType.RECORDING_STOPPED)"></app-svg>
									</div>
									<div class="main-body-bottom-bar-middle-item-sessionMode-popup-item-text" [class.currentMode]="sessionService.sessionMode === SessionMode.DEBRIEFING && sessionService.recordingStatus !== RecordingEventType.RECORDING_STOPPED">
										<div class="main-body-bottom-bar-middle-item-sessionMode-popup-item-text-mode-name">Briefing</div>
										<div class="main-body-bottom-bar-middle-item-sessionMode-popup-item-text-mode-going" *ngIf="sessionService.sessionMode === SessionMode.DEBRIEFING && sessionService.recordingStatus !== RecordingEventType.RECORDING_STOPPED">On Going</div>
									</div>
									<app-svg name="mode-line" class="main-body-bottom-bar-middle-item-sessionMode-popup-item-line" [class.currentMode]="sessionService.sessionMode === SessionMode.DEBRIEFING && sessionService.recordingStatus !== RecordingEventType.RECORDING_STOPPED"></app-svg>
								</div>

								<div class="main-body-bottom-bar-middle-item-sessionMode-popup-item">
									<div class="main-body-bottom-bar-middle-item-sessionMode-popup-item-icon">
										<app-svg name="mode-not-visited-insession" *ngIf="sessionService.sessionMode === SessionMode.DEBRIEFING && sessionService.recordingStatus !== RecordingEventType.RECORDING_STOPPED"></app-svg>
										<app-svg name="mode-current-insession" *ngIf="sessionService.sessionMode === SessionMode.IN_SESSION"></app-svg>
										<app-svg name="mode-done" *ngIf="sessionService.sessionMode === SessionMode.DEBRIEFING && sessionService.recordingStatus === RecordingEventType.RECORDING_STOPPED"></app-svg>
									</div>
									<div class="main-body-bottom-bar-middle-item-sessionMode-popup-item-text" [class.currentMode]="sessionService.sessionMode === SessionMode.IN_SESSION">
										<div class="main-body-bottom-bar-middle-item-sessionMode-popup-item-text-mode-name">In Session <span *ngIf="sessionService.sessionMode === SessionMode.IN_SESSION">with {{ sessionService.testerName }}</span></div>
										<div class="main-body-bottom-bar-middle-item-sessionMode-popup-item-text-mode-going" *ngIf="sessionService.sessionMode === SessionMode.IN_SESSION">On Going</div>
									</div>
									<app-svg name="mode-line" class="main-body-bottom-bar-middle-item-sessionMode-popup-item-line" [class.currentMode]="sessionService.sessionMode === SessionMode.IN_SESSION"></app-svg>
								</div>

								<div class="main-body-bottom-bar-middle-item-sessionMode-popup-item">
									<div class="main-body-bottom-bar-middle-item-sessionMode-popup-item-icon">
										<app-svg name="mode-not-visited-briefing" *ngIf="!(sessionService.sessionMode === SessionMode.DEBRIEFING && sessionService.recordingStatus === RecordingEventType.RECORDING_STOPPED)"></app-svg>
										<app-svg name="mode-current-briefing" *ngIf="sessionService.sessionMode === SessionMode.DEBRIEFING && sessionService.recordingStatus === RecordingEventType.RECORDING_STOPPED"></app-svg>
									</div>
									<div class="main-body-bottom-bar-middle-item-sessionMode-popup-item-text" [class.currentMode]="sessionService.sessionMode === SessionMode.DEBRIEFING && sessionService.recordingStatus === RecordingEventType.RECORDING_STOPPED">
										<div class="main-body-bottom-bar-middle-item-sessionMode-popup-item-text-mode-name">Debriefing</div>
										<div class="main-body-bottom-bar-middle-item-sessionMode-popup-item-text-mode-going" *ngIf="sessionService.sessionMode === SessionMode.DEBRIEFING && sessionService.recordingStatus === RecordingEventType.RECORDING_STOPPED">On Going</div>
									</div>
								</div>
							</div>
						</div>

						<div class="main-body-bottom-bar-middle-item-extra">
							<div class="main-body-bottom-bar-middle-item-extra-rec"
								[ngClass]="sessionService.recordingStatus == RecordingEventType.RECORDING_STARTED ? 'on' : 'off' ">
								<span class="main-body-bottom-bar-middle-item-extra-rec-icon"></span>
								<div class="main-body-bottom-bar-middle-item-extra-rec-text">REC {{ sessionService.recordingStatusText }}</div>
							</div>
							<button class="main-body-bottom-bar-middle-item-extra-btns main-body-bottom-bar-middle-item-extra-btns-startrec"
								[class.disabled]="sessionService.disableRecordingBtn"
								*ngIf="sessionService.isChiefModerator && sessionService.sessionMode == SessionMode.IN_SESSION && sessionService.recordingStatus == RecordingEventType.RECORDING_NOT_STARTED"
								(click)="commonService.startRecording()">
								Start Recording
							</button>
							<button class="main-body-bottom-bar-middle-item-extra-btns main-body-bottom-bar-middle-item-extra-btns-stoprec"
								*ngIf="sessionService.isChiefModerator && sessionService.sessionMode == SessionMode.IN_SESSION && sessionService.recordingStatus == RecordingEventType.RECORDING_STARTED"
								(click)="commonService.stopRecording()">
								Stop Recording
							</button>
						</div>
					</div>
				</div>

				<!-- call menu -->
				<div class="main-body-bottom-bar-middle-center">
					<!-- End call -->
					<div class="main-body-bottom-bar-middle-center-item endCall"
					(click)="(sessionService.isChiefModerator && commonService.callOptions.endSession && sessionService.sessionMode === SessionMode.IN_SESSION) ? commonService.endSession() : commonService.endCall()">
						<app-svg *ngIf="(!sessionService.isChiefModerator || sessionService.sessionMode != SessionMode.IN_SESSION)" name="endCall" appToolTip="Quit Call {{getShortcutKey('Alt', 'Q')}}"></app-svg>
						<app-svg *ngIf="(sessionService.isChiefModerator && sessionService.sessionMode === SessionMode.IN_SESSION)" name="leaveSession" appToolTip="Leave {{getShortcutKey('Alt', 'Q')}}"></app-svg>
						<div class="main-body-bottom-bar-middle-item-title">
							{{(sessionService.isChiefModerator && sessionService.sessionMode === SessionMode.IN_SESSION) ? 'Leave' : 'Quit Call'}}
						</div>
					</div>

					<!-- Mic -->
					<div class="main-body-bottom-bar-middle-center-item mic"
						(click)="commonService.toggleMic()"
						*ngIf="commonService.callOptions.mic"
						[class.disabled]="commonService.disableMicButton">
						<app-svg [class.hide]="!commonService.micEnabled" name="micOn" appToolTip="Mic Off {{getShortcutKey('Alt', 'M')}}"></app-svg>
						<app-svg [class.hide]="commonService.micEnabled" name="micOff" appToolTip="Mic On {{getShortcutKey('Alt', 'M')}}"></app-svg>
						<div class="main-body-bottom-bar-middle-item-title">Mic 
							<span>{{commonService.micEnabled ? 'ON' : 'OFF'}}</span>
						</div>
					</div>

					<!-- Cam -->
					<div class="main-body-bottom-bar-middle-center-item camera"
						(click)="commonService.toggleCam()"
						*ngIf="commonService.callOptions.cam"
						[class.disabled]="commonService.disableCamButton">
						<app-svg name="camOn" [class.hide]="!commonService.camEnabled" appToolTip="Camera Off {{getShortcutKey('Alt', 'R')}}"></app-svg>
						<app-svg name="camOff" [class.hide]="commonService.camEnabled" appToolTip="Camera On {{getShortcutKey('Alt', 'R')}}"></app-svg>
						<div class="main-body-bottom-bar-middle-item-title">Camera
							<span>{{commonService.camEnabled ? 'ON' : 'OFF'}}</span>
						</div>
					</div>

					<!-- Screen Sharing -->
					<div class="main-body-bottom-bar-middle-center-item screen"
						(click)="commonService.toggleScreenSharing()"
						*ngIf="commonService.callOptions.screen"
						[class.disabled]="commonService.disableScreenSharingButton">
						<app-svg name="shareScreenOn" [class.hide]="!commonService.screenSharingEnabled" appToolTip="Screen Off {{getShortcutKey('Alt', 'S')}}"></app-svg>
						<app-svg name="shareScreenOff" [class.hide]="commonService.screenSharingEnabled" appToolTip="Screen On {{getShortcutKey('Alt', 'S')}}"></app-svg>
						<div class="main-body-bottom-bar-middle-item-title">Screen
							<span>{{commonService.screenSharingEnabled ? 'ON' : 'OFF'}}</span>
						</div>
					</div>
				</div>

				<div class="main-body-bottom-bar-middle-right">
					<!-- translation menu -->
					<div *ngIf="translationService.isTranslatorPresent"
						class="main-body-bottom-bar-middle-item main-body-bottom-bar-middle-item-translationMenu"
						[class.active]="commonService.translationMenuInFocus"
						(appClickOutside)="commonService.translationMenuInFocus && commonService.toggleTranslationMenu(false)">
						<div class="main-body-bottom-bar-middle-item-highlight"></div>
						<app-svg name="translation-menu" (click)="commonService.toggleTranslationMenu()" appToolTip="Audio Volume {{getShortcutKey('Alt', 'T')}}"></app-svg>
						<div class="main-body-bottom-bar-middle-item-title">Audio volume</div>
						<app-translation-menu [class.hide]="!commonService.translationMenuInFocus"></app-translation-menu>
					</div>

					<!-- copy links -->
					<div *ngIf="sessionService.isChiefModerator"
						class="main-body-bottom-bar-middle-item main-body-bottom-bar-middle-item-copylinks"
						[class.active]="commonService.copyLinksInFocus">
						<div class="main-body-bottom-bar-middle-item-highlight"></div>
						<app-svg name="copy-links" (click)="commonService.toggleCopyLinks()" appToolTip="Send Invite {{getShortcutKey('Alt', 'L')}}"></app-svg>
						<div class="main-body-bottom-bar-middle-item-title">Invite</div>
						<app-copy-links [class.hide]="!commonService.copyLinksInFocus"></app-copy-links>
					</div>

					<!-- Instructions -->
					<div class="main-body-bottom-bar-middle-item main-body-bottom-bar-middle-item-instructions"
						(click)="commonService.toggleInstructions()"appToolTip="View Task Instruction {{getShortcutKey('Alt', 'I')}}"
						[class.active]="commonService.instructionsInFocus">
						<div class="main-body-bottom-bar-middle-item-highlight"></div>
						<app-svg name="instructions"></app-svg>
						<div class="main-body-bottom-bar-middle-item-title">Instructions</div>
					</div>

					<!-- Bookmark -->
					<div
						*ngIf="sessionService.isChiefModerator"
						class="main-body-bottom-bar-middle-item main-body-bottom-bar-middle-item-bookmark"
						(click)="commonService.saveBookMark()" [class.inactive]="sessionService.disableBookmark"
						appToolTip="Mark Evidence {{getShortcutKey('Alt', 'B')}}">
						<div class="main-body-bottom-bar-middle-item-highlight"></div>
						<app-svg name="bookmark" [class.hideIcon]="commonService.bookmarkInFocus"></app-svg>
						<img src="./assets/bookmark.gif" [class.hideIcon]="!commonService.bookmarkInFocus" />
						<div class="main-body-bottom-bar-middle-item-title">
							{{commonService.bookmarkCount}} Bookmark{{commonService.bookmarkCount > 1 ? 's' : ''}}
						</div>
					</div>

					<!-- Notes Window -->
					<div class="main-body-bottom-bar-middle-item main-body-bottom-bar-middle-item-notes"
						[class.active]="commonService.notesInFocus"
						*ngIf="commonService.callOptions.notes">
						<div class="main-body-bottom-bar-middle-item-highlight"></div>
						<app-svg name="notes" (click)="commonService.toggleNotes()" 
							appToolTip="Take Note {{getShortcutKey('Alt', 'N')}}">
						</app-svg>
						<div class="main-body-bottom-bar-middle-item-title">Notes</div>
						<div class="main-body-bottom-bar-middle-item-notes-notes"
							[class.hide]="!commonService.notesInFocus">
							<app-notes-list></app-notes-list>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>