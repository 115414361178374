import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ClickOutsideDirective } from './clickOutside.directive';
@NgModule({
    declarations: [
        ClickOutsideDirective,
    ],
    imports: [
        CommonModule,
    ],
    providers: [],
    exports: [
        ClickOutsideDirective
    ]
})

export class ClickOutsideModule { }
