import { Component, ViewEncapsulation, AfterViewInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { CommonService } from '../../../services/common.service';
import { SessionService } from 'src/app/services/session.service';
import { ChatRoom, MessageType, CallOptions, SessionMode, RecordingEventType } from 'src/constants';
import { ConnectionService } from 'src/app/services/connection.service';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-observer',
	templateUrl: './observer.component.html',
	styleUrls: ['./observer.component.styl'],
	encapsulation: ViewEncapsulation.ShadowDom
})

export class ObserverComponent implements AfterViewInit, OnDestroy {

	private chatRooms: Array<ChatRoom> = [
		{
			title: 'Participant',
			type: MessageType.TESTER_MODERATOR,
			silent: true
		},
		{
			title: 'Internal',
			type: MessageType.TEAM
		}
	];

	private callOptions: CallOptions = {
		mic: true,
		cam: true,
		screen: true,
		endSession: false,
		notes: false
	};

	private subscriptions: Array<Subscription> = [];

	constructor(
		private commonService: CommonService,
		private connectionService: ConnectionService,
		private sessionService: SessionService,
		private changeDetectorRef: ChangeDetectorRef) {
	}

	ngAfterViewInit() {
		// setting up different tabs in chat window
		this.connectionService.setChatRooms(this.chatRooms);
		this.commonService.callOptions = this.callOptions;
		this.changeDetectorRef.detectChanges();
		const sub = this.sessionService.sessionModeChangeListener.subscribe(sessionState => {
			if (sessionState.sessionMode && this.sessionService.sessionMode !== sessionState.sessionMode) {
				switch (sessionState.sessionMode) {
					case SessionMode.DEBRIEFING:
						// start a timer in debrifing, Date.now() - sessionStartTime => alert => 5 min later end
						this.callOptions = {
							mic: true,
							cam: true,
							screen: true,
							endSession: false,
							notes: false
						};
						this.sessionService.forceDisconnectParticipant();
						if (this.sessionService.sessionMode === SessionMode.IN_SESSION) {
							infoBox('Session has ended, You will now have access to Mic, Camera and Screen sharing');
							this.sessionService.recordingStatus = RecordingEventType.RECORDING_STOPPED;
						}
						break;
					case SessionMode.IN_SESSION:
						// clear that timeout
						this.callOptions = {
							mic: false,
							cam: false,
							screen: false,
							endSession: false,
							notes: false
						};
						!sessionState.supressAlert && infoBox('The Session has started. Your Mic and Camera is disabled.');
						break;
					default: break;
				}
				this.sessionService.sessionMode = sessionState.sessionMode;
				this.commonService.callOptions = this.callOptions;
				this.commonService.stopAllStreams();
				this.changeDetectorRef.detectChanges();
			}
		});
		this.subscriptions.push(sub);
	}

	ngOnDestroy() {
		this.subscriptions && this.subscriptions.forEach(sub => sub && sub.unsubscribe());
	}
}
