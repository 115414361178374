<div class="video-player">
	<!-- Place holder while waiting for someone to join -->
	<div
		class="video-player-bgImg"
		[ngClass]="!commonService.isRightPanelOpened ? 'full' : 'adjust'"
		*ngIf="showPlaceholder || (!commonService.placeholderStreams.length && !commonService.videoStreams.length && !commonService.mobileParticipantStreams.length)">
		<img *ngIf="sessionService.userType === USER_TYPE.TESTER || (sessionService.sessionMode === SessionMode.DEBRIEFING && sessionService.recordingStatus !== RecordingEventType.RECORDING_STOPPED)" [src]="fetchResource('./assets/cup.gif')"/>
		<div class="video-player-bgImg-text" [ngClass]="(sessionService.sessionMode === SessionMode.DEBRIEFING && sessionService.recordingStatus === RecordingEventType.RECORDING_STOPPED) ? 'debriefing' : ''">
			{{
				(sessionService.userType === USER_TYPE.TESTER) ? 'Please wait for moderator to join...' :
				(sessionService.sessionMode === SessionMode.IN_SESSION) ? 'Participant is not in this call...' :
				(sessionService.sessionMode === SessionMode.DEBRIEFING && sessionService.recordingStatus !== RecordingEventType.RECORDING_STOPPED) ? 
					!sessionService.isChiefModerator ? 'Please hold on\nOthers will be joining soon' :
					'You are the only one here.\nYou can invite other members to join this call by sending the links' :
				(sessionService.sessionMode === SessionMode.DEBRIEFING && sessionService.recordingStatus === RecordingEventType.RECORDING_STOPPED) ? 'Everyone has left the call...' : ''
			}}
			<button class="video-player-bgImg-text-quit" *ngIf="(sessionService.sessionMode === SessionMode.DEBRIEFING && sessionService.recordingStatus === RecordingEventType.RECORDING_STOPPED)" (click)="commonService.endCall()">
				Quit session now
			</button>
			<button
				class="video-player-bgImg-text-invite"
				*ngIf="(sessionService.sessionMode === SessionMode.DEBRIEFING && sessionService.recordingStatus != RecordingEventType.RECORDING_STOPPED) && sessionService.isChiefModerator"
				(click)="commonService.toggleCopyLinks()"
				[class.active]="commonService.copyLinksInFocus">
				Invite Members
			</button>
		</div>
		<app-svg name="sessionBG" *ngIf="sessionService.userType !== USER_TYPE.TESTER && (sessionService.sessionMode === SessionMode.DEBRIEFING && sessionService.recordingStatus === RecordingEventType.RECORDING_STOPPED)"></app-svg>
	</div>
	<div class="video-player-container"
		[class.hidePanel]="!commonService.isRightPanelOpened">
		<div
			class="video-player-item-others video-player-item-others-self first"
			[ngClass]="(commonService.isRightPanelOpened ? '' : 'hide')">
			<div class="video-player-item-others-items {{sessionService.userType}}"
				[ngClass]="selfCamStream?.src ? 'camAvailable': ''">
				<app-svg name="placeholder-{{sessionService.userType}}"></app-svg>
				<div
					class="video-player-item-others-text">
					<span class="video-player-item-others-text-alias"><b>{{ sessionService.name.capitalizeFirstLetter() }}</b> (You)
						<span class="video-player-item-others-text-alias-role">
							{{ sessionService.userId.capitalizeFirstLetter() }}
						</span>
					</span>
					<span class="video-player-item-others-mic">
						<app-svg name="mute" [class.hide]="commonService.micEnabled"></app-svg>
						<app-svg name="unmute" [class.hide]="!commonService.micEnabled"></app-svg>
					</span>
				</div>
			</div>
			<!-- Self Cam Stream -->
			<div class="video-player-item video-player-item-self" *ngIf="selfCamStream?.src">
				<video #player autoplay playsinline id="{{selfCamStream?.src?.id}}"></video>
			</div>
		</div>

		<div class="video-player-item-others-self-members" style="margin-top: 0; margin-bottom: 0.5rem" *ngIf="commonService.placeholderStreams.length">Others who have joined ({{commonService.placeholderStreams.length}})</div>
		<ng-container *ngFor="let item of commonService.placeholderStreams; trackBy: trackById; let i = index;">
			<div
				*ngIf="item.active"
				class="video-player-item"
				[ngClass]="item.hide ? 'hide' : item.active ?
				(commonService.isRightPanelOpened ? 'focus' : 'focus fullWidth') :
				(commonService.isRightPanelOpened ? '' : 'hide')">
				<div class="video-player-item-placeholder">
					<app-svg name="sessionBG"></app-svg>
					<div
						class="video-player-item-placeholder-text"
						*ngIf="item && item.from && item.from.alias">
						<span class="video-player-item-placeholder-mic">
							<app-svg name="mute" *ngIf="item.muted == undefined || item.muted"></app-svg>
							<app-svg name="unmute" *ngIf="item.muted != undefined && !item.muted"></app-svg>
						</span>
						<span
							class="video-player-item-placeholder-text-alias"
							[style.borderColor]="item?.from?.color">
							<b>{{ item?.from?.alias.capitalizeFirstLetter() }}</b>
							<span class="video-player-item-placeholder-text-alias-role">
								({{ item?.from?.userId?.capitalizeFirstLetter() }})
							</span>
						</span>
					</div>
				</div>
			</div>
			<div
				*ngIf="!item?.hide"
				class="video-player-item-others"
				[ngClass]="(commonService.isRightPanelOpened ? '' : 'hide')"
				[ngClass]="i == commonService.placeholderStreams.length - 1 ? 'first': ''">
				<div class="video-player-item-others-items {{item?.from?.userType}}">
					<app-svg name="placeholder-{{item?.from?.userType}}"></app-svg>
					<div
						*ngIf="item?.from?.alias"
						class="video-player-item-others-text">
						<span class="video-player-item-others-text-alias">{{ item.from.alias }}
							<span class="video-player-item-others-text-alias-role">
								{{ item.from.userId.capitalizeFirstLetter() }}
							</span>
						</span>
						<span class="video-player-item-others-mic">
							<app-svg name="mute" [class.hide]="item.muted != undefined && !item.muted"></app-svg>
							<app-svg name="unmute" [class.hide]="item.muted == undefined || item.muted"></app-svg>
						</span>
					</div>
				</div>
			</div>
		</ng-container>

		<!-- Video Streams -->
		<div class="video-player-item-others-self-members" *ngIf="getInactiveVideoCount()">Videos ({{getInactiveVideoCount()}})</div>
		<div *ngFor="let item of commonService.videoStreams; trackBy: trackById; let i = index;" 
			class="video-player-item"
			[ngClass]="item.active ?
			(commonService.isRightPanelOpened ? 'focus' : 'focus fullWidth') :
			(commonService.isRightPanelOpened ? '' : 'hide')"
			(click)="setFocus(item)">
			<video
				*ngIf="item && item.src"
				#player
				autoplay
				class="video-player-item-{{i}}"
				id="{{item.src.id}}"
				playsinline>
			</video>
			<div *ngIf="item?.from?.alias" class="video-player-item-text">
				<span class="video-player-item-text-mic">
					<app-svg name="mute" size=20 *ngIf="item.muted == undefined || item.muted"></app-svg>
					<app-svg name="unmute" size=20 *ngIf="item.muted != undefined && !item.muted"></app-svg>
				</span>
				<div class="video-player-item-text-desc">
					<span> {{ item.from.alias }} </span>
					<span class="video-player-item-text-role"
						[style.borderColor]="item?.from?.color">({{ item?.from?.userId?.capitalizeFirstLetter() }})</span>
				</div>
			</div>
		</div>

		<!-- Special handling for mobile participant streams -->
		<div 
			*ngIf="commonService.mobileParticipantStreams?.length"
			class="video-player-item focus">
			<div class="video-player-item-mobileParticipant">
				<ng-container *ngFor="let item of commonService.mobileParticipantStreams; trackBy: trackById; let i = index;">
					<video
						*ngIf="item?.src"
						#player
						autoplay
						class="video-player-item-{{i}} {{ item?.kind }}"
						id="{{item?.src?.id}}"
						playsinline>
					</video>
				</ng-container>
			</div>
			<div *ngIf="mobileParticipantStreamDetail?.from?.alias" class="video-player-item-text">
				<span class="video-player-item-text-mic">
					<app-svg name="mute" size=20 *ngIf="mobileParticipantStreamDetail.muted == undefined || mobileParticipantStreamDetail.muted"></app-svg>
					<app-svg name="unmute" size=20 *ngIf="mobileParticipantStreamDetail.muted != undefined && !mobileParticipantStreamDetail.muted"></app-svg>
				</span>
				<div class="video-player-item-text-desc">
					<span> {{ mobileParticipantStreamDetail.from.alias }} </span>
					<span class="video-player-item-text-role"
						[style.borderColor]="mobileParticipantStreamDetail.from?.color">({{ mobileParticipantStreamDetail.from?.userId?.capitalizeFirstLetter() }})</span>
				</div>
			</div>
		</div>
	</div>
</div>