import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ChatStatusComponent } from './chat-status.component';

@NgModule({
    declarations: [
        ChatStatusComponent
    ],
    imports: [
        CommonModule,
    ],
    providers: [],
    exports: [
        ChatStatusComponent
    ]
})

export class ChatStatusModule { }
