import { Directive, ElementRef, Input } from '@angular/core';
import tippy from 'tippy.js'; // library to show tooltips -> https://atomiks.github.io/tippyjs/

@Directive({
	selector: '[appToolTip]'
})
export class TooltipDirective {

	@Input() set appToolTip(value: string) {
		if (value?.trim()?.length) {
			this.content = value;
			this.setToolTip();
		}
	}

	@Input() set appToolTipTheme(value: string) {
		if (value?.trim()?.length) {
			this.theme = value;
			this.setToolTip();
		}
	}

	private content: string;
	private theme: string = null;
	private tooltipInstance;

	constructor(private elementRef: ElementRef) {
		this.setToolTip();
	}

	setToolTip() {
		if (this.elementRef?.nativeElement && this.content?.trim()?.length) {
			if (this.tooltipInstance) {
				this.tooltipInstance.setContent(this.content);
			} else {
				this.tooltipInstance = tippy(this.elementRef.nativeElement, {
					content: this.content,
					duration: 0,
					arrow: true,
					hideOnClick: true,
					trigger: 'mouseenter'
				});
			}
		}

		if (this.theme && this.tooltipInstance) {
			this.tooltipInstance.setProps({
				theme: this.theme
			});

			if (this.theme == 'light-custom') {
				this.tooltipInstance.setProps({
					arrow: false,
					placement: 'bottom',
					offset: [0, -10]
				});
			}
		}
	}
}