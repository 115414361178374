import { WebPlugin } from '@capacitor/core';
import { ScreenSharingPlugin } from './definitions';

export class ScreenSharingWeb extends WebPlugin implements ScreenSharingPlugin {
	constructor() {
		super({
			name: 'ScreenSharing',
			platforms: ['web'],
		});
	}

	async echo(options: { value: string }): Promise<{ value: string }> {
		console.log('ECHO', options);
		return options;
	}

	async startStream(): Promise<void> { }
	stopStream(): void | Promise<void> { }
	
	stopAllStreams(): void { }
	quitApp(): void { }
	openInAppBrowser(): void { }
	toggleIOSChannel(): void { }
	initWakeLockService(): void { }
	killWakeLockService(): void { }
	
	createNotification(): void { }
	removeNotification(): void { }

	setMute(): void { }
	subscribeToNativeAudio(): void { }
	unSubscribeToNativeAudio(): void { }
	goToSettings(): void { }
	async isPowerSaveMode(): Promise<{ mode: boolean }> { return { mode: false } };
}

const ScreenSharing = new ScreenSharingWeb();

export { ScreenSharing };

import { registerWebPlugin } from '@capacitor/core';
registerWebPlugin(ScreenSharing);
