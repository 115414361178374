<div class="instructions">
    <div class="instructions-header">
        <span class="instructions-header-text">Instructions for Participant</span>
        <span class="instructions-header-icon">
            <app-svg name="close" (click)="commonService.toggleInstructions()"></app-svg>
        </span>
    </div>
    <div class="instructions-content">
        <div class="instructions-content-text" [class.silent]="!editModeEnabled">
           <div #container
            class="instructions-content-text-content"
            [class.centerPlaceholder]="!editModeEnabled"
            [attr.contenteditable]="editModeEnabled"
            [innerHTML]="(commonService.instructions || '').trim()"
            [attr.placeholder]="editModeEnabled ? 'Enter the instructions for your participants. Instructions can also include links which you want your participants to view and give feedback on e.g. a design prototype. Participants will be able to view these instructions during the Session. You can change these instructions anytime. Yes, even during the Session :-)' : 'Instructions have not been set. The Moderator using the DeepDive account can modify the Instructions anytime.'"></div>
        </div>
        <div class="instructions-content-btns" *ngIf="editModeEnabled">
            <button class="instructions-content-btns-left" (click)="commonService.toggleInstructions()">Cancel</button>
            <button class="instructions-content-btns-right" (click)="save()">Save</button>
        </div>
    </div>
</div>