import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { VideoPlayerComponent } from './video-player.component';
import { SvgUseModule } from '../svg-use/svg-use.module';
import { JoineeTooltipModule } from '../joinee-tooltip/joinee-tooltip.module';
import { ClickOutsideModule } from 'src/app/directives/clickOutside/clickOutside.module';
import { ToolTipModule } from 'src/app/directives/tooltip/tooltip.module';
import { AngularDraggableModule } from 'angular2-draggable';

@NgModule({
    declarations: [
        VideoPlayerComponent,
    ],
    imports: [
        CommonModule,
        SvgUseModule,
        JoineeTooltipModule,
        ClickOutsideModule,
        ToolTipModule,
        AngularDraggableModule
    ],
    providers: [],
    exports: [
        VideoPlayerComponent,
    ]
})

export class VideoPlayerModule { }
