import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SvgUseModule } from '../svg-use/svg-use.module';
import { FormsModule } from '@angular/forms';
import { NotesListComponent } from './notes-list.component';
import { NotesComponent } from './notes/notes.component';

@NgModule({
    declarations: [
        NotesListComponent,
        NotesComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        SvgUseModule
    ],
    providers: [],
    exports: [
        NotesListComponent,
        NotesComponent
    ]
})

export class NotesListModule { }
