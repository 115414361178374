import { Component, Input, ViewEncapsulation } from '@angular/core';
import { TechCheckService } from 'src/app/services/tech-check.service';
import { TECH_CHECK_STATUS, TechCheckItem, TECH_CHECK_TYPES } from 'src/constants';
import { CommonService } from 'src/app/services/common.service';
const DetectRTC = require('detectrtc');
@Component({
	selector: 'app-check-browser',
	templateUrl: './check-browser.component.html',
	styleUrls: ['./check-browser.component.styl'],
	encapsulation: ViewEncapsulation.ShadowDom
})

export class CheckBrowserComponent {

	public checkItem: TechCheckItem;
	public TECH_CHECK_STATUS = TECH_CHECK_STATUS;

	public maxProgressTime = 1000 * 3; // 3 sec
	public progressTimer = 0;
	private progressInterval;
	private timeout;

	public deviceName = window.isMobile ? 'Device' : 'Browser';

	@Input() set check(value) {
		if (value) {
			this.checkItem = value;
		}
	}

	constructor(private techCheckService: TechCheckService, public commonService: CommonService) {
	}

	private showProgressBar(max: number) {
		// updating progress counter
		const interval = 500;
		if(this.progressInterval) {
			clearInterval(this.progressInterval);
			this.progressInterval = null;
		}
		this.progressInterval = setInterval(() => {
			if (this.checkItem.status == TECH_CHECK_STATUS.PROGRESS) {
				if (this.progressTimer >= 100) {
					this.progressTimer = 0;
				}
				this.progressTimer += interval * 100 / max;
				this.progressTimer = Math.min(100, this.progressTimer);
			} else {
				clearInterval(this.progressInterval);
				this.progressInterval = null;
			}
		}, interval);
	}

	ngAfterViewInit() {
		if (this.checkItem) {
			this.showProgressBar(this.maxProgressTime);

			this.timeout = setTimeout(() => {
				this.performTest();
			}, this.maxProgressTime);
		}
	}

	private performTest(): Promise<void> {
		return new Promise((resolve, reject) => {
			DetectRTC.load(() => {
					const staticMessage = 'Please use latest version of Google Chrome or Mozilla Firefox';

					// basic checks
					const browserChecks = [
						{
							condition: 'isWebRTCSupported',
							reason: `This ${this.deviceName} does not support WEBRTC protocols, ` + staticMessage,
						},
						{
							condition: 'isWebSocketsSupported',
							reason: `This ${this.deviceName} does not support Web Socket protocols, ` + staticMessage,
						},
						{
							condition: 'isPromisesSupported',
							reason: `This ${this.deviceName} is old, ` + staticMessage,
						},
						{
							condition: 'isWebSocketsBlocked',
							reason: `This ${this.deviceName} has blocked WebSockets protocols, please enable them and refresh the page`,
							negate: true,
						},
						{
							condition: 'isTakinPhotoSupported',
							reason: `This ${this.deviceName} is old, ` + staticMessage,
							optional: false
						}
					];

					if (!isIOS) {
						// browserChecks.push({
						// 	condition: 'hasSpeakers',
						// 	reason: 'Your speakers might not be working, please check and refresh the page',
						// 	optional: true
						// });
					}

					let isTakinPhotoSupported = false;
					if (typeof document !== 'undefined' &&
						typeof document.createElement == 'function') {
						const canvas = document.createElement('canvas');
						if (typeof canvas !== 'undefined' &&
							typeof CanvasRenderingContext2D !== 'undefined' &&
							typeof canvas.getContext !== 'undefined') {
							const ctx = canvas.getContext('2d');
							if (typeof ctx !== 'undefined' &&
								ctx instanceof CanvasRenderingContext2D &&
								typeof ctx.drawImage == 'function' &&
								typeof canvas.toBlob == 'function' &&
								typeof FileReader == 'function' &&
								typeof FileReader.prototype.readAsArrayBuffer == 'function') {
								isTakinPhotoSupported = true;
							}
						}
					}
					DetectRTC.isTakinPhotoSupported = isTakinPhotoSupported;

					for (const check of browserChecks) {
						if (check && check.condition && check.negate ? DetectRTC[check.condition] : !DetectRTC[check.condition]) {
							this.checkItem.status = TECH_CHECK_STATUS.FAILED;
							this.checkItem.failReason = check.reason;
							if (!check.optional) {
								this.markAll(TECH_CHECK_STATUS.NONE);
								reject(check.reason);
								return;
							}
						}
					}

				 this.commonService.disablePowerSaverMode().then(() => {
					this.markAll(TECH_CHECK_STATUS.SUCCESS);
					resolve();
				});
			});
		});
	}

	private markAll(status: TECH_CHECK_STATUS) {
		if (this.checkItem.status == TECH_CHECK_STATUS.PROGRESS) {
			this.checkItem.status = status;
		}
	}

	public testCompleted() {
		this.progressInterval && clearInterval(this.progressInterval);
		this.timeout && clearTimeout(this.timeout);
		this.techCheckService.currentCheck = TECH_CHECK_TYPES.CAM;
	}
}
