<div class="role">
    <div class="role-content" *ngIf="!showSwitchRole">
        <div class="role-content-heading">
            You will be joining as
        </div>
        <div class="role-content-container" [ngStyle]="{'background': roleDetails[sessionService.userType]?.background}">
            <div class="role-content-container-img">
                <app-svg name="role-active-{{sessionService.userType}}"></app-svg>
            </div>
            <div class="role-content-container-heading" [ngStyle]="{'color': roleDetails[sessionService.userType]?.color}">
                {{roleDetails[sessionService.userType]?.name}}
            </div>
            <div class="role-content-container-desc">
                {{roleDetails[sessionService.userType]?.desc}}
            </div>
        </div>
        <div class="role-content-desc">
            Please confirm your exact role before proceeding to join the Research
        </div>
        <div class="role-content-btn">
            <button class="role-content-btn-button" (click)="continueClicked.emit()">Continue</button>
        </div>
        <div class="role-content-switch">
            Not in the exact role? <span class="role-content-switch-btn" (click)="showSwitchRole=true">Switch Role</span> here
        </div>
    </div>

    <div class="role-content" *ngIf="showSwitchRole">
        <div class="role-switch">
            <ng-container *ngFor="let r of roleDetails | keyvalue">
                <div class="role-content-container" [ngStyle]="{'background': '#F9F9F9'}" [ngClass]="{'activeRole': r?.key == activeRole}" (click)="activeRole=r?.key">
                    <app-svg class="role-content-container-checked" name="role-checked" [class.hide]="r?.key != activeRole"></app-svg>
                    <div class="role-content-container-img">
                        <app-svg name="role-inactive-{{r?.key}}" *ngIf="r?.key != activeRole"></app-svg>
                        <app-svg name="role-active-{{r?.key}}" *ngIf="r?.key == activeRole"></app-svg>
                    </div>
                    <div class="role-content-container-heading" [ngStyle]="{'color': r?.key == activeRole ? r?.value?.color : '#AAAAAA'}">
                        {{r?.value?.name}}
                    </div>
                    <div class="role-content-container-desc">
                        {{r?.value?.desc}}
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="role-content-heading">
            Switch Role
        </div>
        <div class="role-content-desc">
            Select your exact role before proceeding to join the Research
        </div>
        <div class="role-content-btn" style="margin-top: 1rem">
            <button class="role-content-btn-button" (click)="continueToRole()">Continue to selected role</button>
        </div>
    </div>
</div>