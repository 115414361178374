import { Component, Input, ViewEncapsulation, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';

@Component({
	selector: 'app-svg',
	encapsulation: ViewEncapsulation.None,
	templateUrl: 'svg-use.component.html',
	styleUrls: ['svg-use.component.styl']
})
export class SvgUseComponent {
	@ViewChild('svgUse') svg: ElementRef<HTMLElement>;

	@Input() disabled = '';
	@Input() hideCircle = false;
	@Input() color = null;
	@Input() size = null;

	// id of the svg element is sent via name input
	@Input() set name(value) {
		if (value) {
			// we traverse the document to find the element which we inserted into dom as a template in app.component.html
			const selectors = [
				'app-root', // root of our app
				'app-svg-icons', // root of svg-icons component
				'.svg-icons', // container element of svg icons
				(`#${value}`) // our element
			];
			const rootSelector = document.body;
			let element = rootSelector;
			// as we have used shadow root as the ViewEncapsulation method, we have to check if current element is a shadowroot or not in order to search in it's tree
			for (const selector of selectors) {
				element = element.shadowRoot ? element.shadowRoot.querySelector(selector) : element.querySelector(selector);
			}
			if (element) {
				// we clone the node, because it might be needed in multiple places
				const clone = element.cloneNode(true);
				// insert into this components html
				const insertIntoSvg = () => {
					if (this.svg && this.svg.nativeElement) {
						this.svg.nativeElement.appendChild(clone);
						if (this.hideCircle) {
							const circle = this.svg.nativeElement.querySelector('circle');
							if (circle && circle.style) {
								circle.style.display = 'none';
							}
						}

						if (this.color) {
							const path = this.svg.nativeElement.querySelector('path');
							if (path && path.style) {
								path.style.fill = this.color;
							}
						}

						if (this.size) {
							const svg = this.svg.nativeElement.querySelector('svg');
							if (svg) {
								// @ts-ignore
								svg.setAttribute('width', this.size);
								// @ts-ignore
								svg.setAttribute('height', this.size);
							}
						}

						this.changeDetectorRef.detectChanges();
					} else {
						window.requestAnimationFrame(() => insertIntoSvg());
					}
				};
				insertIntoSvg();
			} else {
				logger.log('SVG Icon by id: ', value, ' not found');
			}
		}
	}

	constructor(private changeDetectorRef: ChangeDetectorRef) {}
}
