import { Publisher, Session } from 'openvidu-browser';
import { SweetAlertPosition } from 'sweetalert2';
import { UserMetaData, StreamType, MessageType, RecordingEventType, SessionMode, USER_TYPE, DUMMY_TECH_CHECK_SESSION_PREFIX } from '../../commonConstants';
export * from '../../commonConstants';


export interface ChatRoomMember extends UserMetaData {
	color?: string;
	alias?: string;
}

export enum ConnectionEventType {
	CONNECTION_CREATED = 'CONNECTION_CREATED',
	CONNECTION_DESTROYED = 'CONNECTION_DESTROYED'
}

export interface ConnectionEventMessage {
	type: ConnectionEventType;
	data: UserMetaData;
	silent?: boolean;
}

export enum SessionEventType {
	RECONNECTING = 'RECONNECTING',
	RECONNECTED = 'RECONNECTED',
	VOLUNTARY_DISCONNECTION = 'VOLUNTARY_DISCONNECTION',
	NETWORK_DISCONNECTION = 'NETWORK_DISCONNECTION',
}

export interface SessionEventMessage {
	type: SessionEventType;
}

export enum StreamEventType {
	STREAM_ADDED = 'STREAM_ADDED',
	STREAM_REMOVED = 'STREAM_REMOVED',
	STREAM_STOPPED = 'STREAM_STOPPED'
}


export interface StreamEventMessage {
	type: StreamEventType;
	streamId: string;
	kind?: StreamType;
	src?: MediaStream;
	active?: boolean;
	from?: ChatRoomMember;
	hide?: boolean;
	muted?: boolean;
	displayTooltip?: boolean;
	actualConnectionID?: string;
	volume?: number;
}

export enum PeerSpeakingEventType {
	STARTED_SPEAKING = 'STARTED_SPEAKING',
	STOPPED_SPEAKING = 'STOPPED_SPEAKING'
}

export interface PeerSpeakingEventMessage {
	type: PeerSpeakingEventType;
	connectionId: string;
}

export interface Message {
	from: ChatRoomMember;
	msg: string;
	type?: MessageType;
	time?: number;
	status?: MessageSentStatus;
	hideName?: boolean;
	hideTime?: boolean;
	localTimeString?: string
}

export interface JoinStatus {
	type: ConnectionEventType;
	msg: string;
}


// typeguard for Message union JoinStatus type
export function isJoinStatus(msg: Message | JoinStatus): msg is JoinStatus {
	for(const type in ConnectionEventType) {
		if((msg as JoinStatus).type === ConnectionEventType[type])
			return true;
	}
	return false;
}

export interface OutgoingMessage {
	msg: string;
	time: number;
}

// /**
//  * Checks for hardcoded properites(from, msg, time) on the incoming message
//  */
// export function checkMessageIntegrity(data) {
// 	const schema = {
// 		from: 'ChatRoomMember',
// 		msg: 'string',
// 		time: 'number'
// 	};
// 	let result = true;
// 	for (const obj of Object.entries(schema)) {
// 		const key = obj[0];
// 		const type = obj[1];
// 		if (!data[key] || typeof data[key] !== type || data[key] ) {
// 			result = false;
// 			break;
// 		}
// 		if (type === 'string') {
// 			data[key] = data[key].trim();
// 			if (data[key] === '') {
// 				result = false;
// 				break;
// 			}
// 		}
// 		if (type === 'number' && !isNaN(Date.parse(data[key]))) {
// 			result = false;
// 			break;
// 		}
// 	}
// 	console.log('Message Check result: ', result, ' message: ', data);
// 	return result;
// }

export interface RecordingEventMessage {
	type: RecordingEventType;
}

export enum SessionStatus {
	SESSION_NOT_STARTED = 'SESSION_NOT_STARTED',
	SESSION_STARTED = 'SESSION_STARTED',
	SESSION_ENDED = 'SESSION_ENDED',
	SESSION_DISRUPTED = 'SESSION_DISRUPTED',
	SESSION_RESUMED = 'SESSION_RESUMED',
}

export interface ChatRoom {
	title: string;
	type: MessageType;
	silent?: boolean;
}

export enum MessageSentStatus {
	UNSENT = 'UNSENT',
	SENT = 'SENT',
	FAIL = 'FAIL',
	EDIT = 'EDIT'
}

export interface Chat extends ChatRoom {
	messages: Array<Message | JoinStatus>; // all recieved messages
	message: string; // current message being typed
	members: Array<ChatRoomMember>; // all members of this type
	isMenuOpen: boolean;
}

export interface PublisherDetail {
	publisher: Publisher;
	session: Session;
}

export interface CallOptions {
	mic: boolean;
	cam: boolean;
	screen: boolean;
	endSession: boolean;
	notes: boolean;
}

export interface Logger {
	log?: (...msg) => void;
	info?: (...msg) => void;
	warn?: (...msg) => void;
	error?: (...msg) => void;
	debug?: (...msg) => void;
	dir?: (...msg) => void;
	trace?: (...msg) => void;
}

// icons used in alerts/toasts
export enum ICONS {
	INFO = 'INFO',
	ERROR = 'ERROR',
	MESSAGE = 'MESSAGE',
	END_SESSION = 'END_SESSION',
	AVATAR = 'AVATAR',
	EXCLAMATION = 'EXCLAMATION',
	START_RECORDING = 'START_RECORDING',
	STOP_RECORDING = 'STOP_RECORDING',
}

export type Toast = (data: string | { text: string, icon: ICONS }, timer?: number, position?: SweetAlertPosition) => void;

export type MemberMap = Map<MessageType, ChatRoomMember[]>;

export interface SyncSession {
	sessionMode: SessionMode;
	supressAlert?: boolean;
}

export interface MuteMessage {
	connectionId: string;
	muted: string;
}

export interface SessionInfo {
	userType: USER_TYPE;
	mediaSessionKey: string;
	duration: number;
	userLimit: number;
	chiefModerator: false;
	instructions?: string;
	sessionStartTime?: number;
	bookmarksCount: number;
	links?: any;
}

export interface SessionDetails {
	userType: USER_TYPE;
	sessionId: string;
	userId: string;
	chiefModerator: boolean;
	links?: any;
}

export enum TECH_CHECK_TYPES {
	BROWSER = 'BROWSER',
	MIC = 'MIC',
	CAM = 'CAM',
	SCREEN_SHARING = 'SCREEN_SHARING'
}

export enum TECH_CHECK_STATUS {
	PROGRESS = 'PROGRESS',
	SUCCESS = 'SUCCESS',
	FAILED = 'FAILED',
	NONE = 'NONE'
}

// constraints applied to video streams
export const VIDEO_MAX_FPS = 30;
export const VIDEO_MAX_WIDTH = 640;
export const VIDEO_MAX_HEIGHT = 480;

export enum ToggleEventType {
	CHAT = 'CHAT',
	NOTES = 'NOTES',
	INSTRUCTIONS = 'INSTRUCTIONS',
}

// custom layout for recorder, it's a component of this application solely to be used by openvidu alone
// below is the absolute path to reach it
export const CUSTOM_LAYOUT_PATH = window.location.origin + window.location.pathname + '/#/recorder';

// for iOS home button
export const HOME_BUTTON = 'HOME_BUTTON';
export const IOS_FOREGROUND = 'IOS_FOREGROUND';

// interface used in tech check, send microphone audio level and status of availability
export interface AudioLevel {
	volume: number;
	enabled: boolean;
}

export interface TechCheckItem {
	key: TECH_CHECK_TYPES;
	name: string;
	status: TECH_CHECK_STATUS;
	failReason: string;
}

export const TECH_CHECK_VIDEO_PLAYER_MAX_TIMEOUT = 1000 * 30; // 30 secs

export const INSTRUCTIONS_ACTIVE: number = 0;
export const CHAT_ACTIVE: number = 1;
export const VIDEOS_ACTIVE: number = 2;
export const TRANSLATION_ACTIVE: number = 3;
// session if used for tech check service, this prefix is used in server also, so change in both places
// DUMMY_TECH_CHECK_SESSION_PREFIX is ignored for recording
const date = Date.now().toString();
export const sessionPrefix = DUMMY_TECH_CHECK_SESSION_PREFIX + date.substr(date.length - 5, date.length);

export interface SetIDEvent {
	oldID: number;
	newID: number;
}

export const guideShownKey: string = 'guideShown';

export interface StreamChannel {
	id: number,
	name: string,
	userTypes: USER_TYPE[],
	streamTypes: StreamType[],
	isChiefModerator: boolean,
	volume: number,
	hidden: boolean,
	background: string
}