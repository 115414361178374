<div class="check-screen" *ngIf="checkItem">
    <div class="check-screen-image">
        <app-svg name="checkScreenShare" *ngIf="window.isMobile && checkItem.status != TECH_CHECK_STATUS.SUCCESS"></app-svg>
        <app-svg name="checkScreenShareDesktop" *ngIf="!window.isMobile && checkItem.status != TECH_CHECK_STATUS.SUCCESS"></app-svg>
        <app-svg name="checkAllPassed" *ngIf="checkItem.status == TECH_CHECK_STATUS.SUCCESS"></app-svg>
        <video #player autoplay playsinline [class.hide]="checkItem.status != TECH_CHECK_STATUS.PROGRESS"></video>
    </div>
    <div class="check-screen-message">
        <span *ngIf="checkItem.status == TECH_CHECK_STATUS.PROGRESS">Checking system configuration</span>
        <span *ngIf="checkItem.status == TECH_CHECK_STATUS.SUCCESS">All set to go!</span>
        <span *ngIf="checkItem.status == TECH_CHECK_STATUS.FAILED">Screen sharing</span>
    </div>
    <div class="check-screen-instruction">
        <span *ngIf="checkItem.status == TECH_CHECK_STATUS.PROGRESS">
            <div class="check-screen-instruction-progress">
                <div class="check-screen-instruction-progress-container" [ngStyle]="{'width': retryTimeElapsed + '%'}"></div>
            </div>
        </span>
        <span *ngIf="checkItem.status == TECH_CHECK_STATUS.SUCCESS"></span>
        <span class="check-screen-instruction-fail" *ngIf="checkItem.status == TECH_CHECK_STATUS.FAILED">{{checkItem.failReason}}</span>
    </div>
    <div class="check-screen-button">
        <button class="check-screen-button-btn" (click)="retry()" *ngIf="checkItem.status == TECH_CHECK_STATUS.FAILED">Retry</button>
        <button class="check-screen-button-btn" (click)="proceed()" *ngIf="checkItem.status == TECH_CHECK_STATUS.SUCCESS">Enter Session</button>
    </div>
</div>