import { Directive, Output, EventEmitter, ElementRef, HostListener } from '@angular/core';

@Directive({
	selector: '[appClickOutside]'
})
export class ClickOutsideDirective {

	@Output() appClickOutside = new EventEmitter<void>();

	constructor(private elementRef: ElementRef) { }

	@HostListener('body:click', ['$event'])
	onClick(event: MouseEvent) {
		if (event) {
			// @ts-ignore
			const path = event.path || (event.composedPath && event.composedPath());
			const clickedInside = path && typeof path.indexOf == 'function' && this.elementRef && this.elementRef.nativeElement && path.indexOf(this.elementRef.nativeElement) !== -1;
			if (!clickedInside) {
				this.appClickOutside.emit();
			}
		}
	}
}
