import { environment } from 'src/environments/environment';
import { Component, ViewEncapsulation, ChangeDetectorRef, AfterViewInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { SessionService } from 'src/app/services/session.service';
import { USER_TYPE } from 'src/constants';
import { CommonService } from 'src/app/services/common.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-join',
	templateUrl: './join.component.html',
	styleUrls: ['./join.component.styl'],
	encapsulation: ViewEncapsulation.ShadowDom
})
export class JoinComponent implements AfterViewInit, OnDestroy {

	@ViewChild('input') inputElement: ElementRef<HTMLInputElement>;

	public disableButton = true;
	public loader = false;

	public showWaitMsg = false;
	public showToolTip = false;
	public fetchResource = fetchResource;

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		public sessionService: SessionService,
		private commonService: CommonService,
		private router: Router) {
	}

	ngAfterViewInit() {
		let requestID;
		const setFocus = () => {
			if (this.inputElement && this.inputElement.nativeElement && typeof this.inputElement.nativeElement.focus == 'function') {
				this.inputElement.nativeElement.focus();
				window.cancelAnimationFrame(requestID);
			} else {
				requestID = window.requestAnimationFrame(() => setFocus());
			}
		};
		setFocus();
		// Hide scroll, if not hidden by previous component
		document.body.style.overflow = 'hidden';
	}

	public checkName(event: KeyboardEvent) {
		this.showToolTip = false;
		if (this.sessionService.name && this.sessionService.name.trim() !== '' &&
			this.sessionService.name.trim().length > 2) {
			this.disableButton = false;
		} else {
			this.disableButton = true;
		}
		if (event && event.key && event.key == 'Enter') {
			this.joinSession();
		}
	}

	public joinSession() {
		if (this.disableButton) {
			this.showToolTip = true;
			this.changeDetectorRef.detectChanges();
			return;
		}
		this.loader = true;
		this.disableButton = true;
		this.changeDetectorRef.detectChanges();
		// only storing first 10 characters
		this.sessionService.name = this.sessionService.name.trim().substr(0, 10);
		// tester won't join directly, moderator will allow him
		if (this.sessionService.userType === USER_TYPE.TESTER) {
			this.showWaitMsg = true;
			this.changeDetectorRef.detectChanges();
			this.commonService.sendJoinSessionRequest().then(() => this.join()).catch(() => {
				this.showWaitMsg = false;
				this.loader = false;
				this.disableButton = false;
				this.changeDetectorRef.detectChanges();
			});
		} else {
			this.join();
		}
	}

	private join() {
		this.sessionService.joinSession().then(() => {
			this.loader = false;
			// for participant, camera and mic will be on as soon as joining the session
			if (this.sessionService.userType == USER_TYPE.TESTER) {
				if (!this.commonService.micEnabled) {
					this.commonService.toggleMic();
				}
				if (!this.commonService.camEnabled) {
					this.commonService.toggleCam();
				}
			}
			this.changeDetectorRef.detectChanges();
		}).catch((err) => {
			this.disableButton = false;
			this.loader = false;
			this.changeDetectorRef.detectChanges();
			logger.error('Cannot start session:', err);
			alert({ text: err && err.overrideDefaultAlert ? err.text : 'Ouch! There seems to be some trouble at our server, please try again', icon: ICON.ERROR });
			if (err && err.routeToPageNotFound) {
				this.router.navigate(['page-not-found']);
			}
		});
	}

	ngOnDestroy() {
	}
}
