import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SvgUseModule } from '../svg-use/svg-use.module';
import { CopyLinksComponent } from './copy-links.component';

@NgModule({
    declarations: [
        CopyLinksComponent
    ],
    imports: [
        CommonModule,
        SvgUseModule
    ],
    providers: [],
    exports: [
        CopyLinksComponent
    ]
})

export class CopyLinksModule { }
