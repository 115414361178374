import { environment } from 'src/environments/environment';
import { AfterViewInit, ChangeDetectorRef, Component, NgZone, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// capacitor plugin to access storage, device and app
import { Plugins } from '@capacitor/core';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { SessionMode, SessionStatus, USER_TYPE } from '../../../constants';
import { SessionService } from '../../services/session.service';

const { Device, App, StatusBar } = Plugins;

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.styl'],
	encapsulation: ViewEncapsulation.ShadowDom
})
export class DashboardComponent implements AfterViewInit, OnDestroy {

	private paramSub: Subscription;
	public sessionId: number = null;
	public SessionStatus = SessionStatus;
	public SessionMode = SessionMode;

	// View Indexes
	public USER_TYPE = USER_TYPE;
	public test = false;
	public loader = false;
	public fetchResource = fetchResource;

	public noSession = false;

	private noLinkTimeout = null;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		public commonService: CommonService,
		private changeDetectorRef: ChangeDetectorRef,
		public sessionService: SessionService,
		private zone: NgZone) {
		// force route reload whenever params change;
		this.router.routeReuseStrategy.shouldReuseRoute = (future, curr) => false;
	}

	ngAfterViewInit() {
		// desktop
		this.paramSub = this.route.params.subscribe(params => this.routeApp(params));
	}

	private async detectDevice() {
		// Detect Device
		const info = await Device.getInfo();
		logger.log('Device Info', info);
		let isMobile = false;
		let isIOS = false;
		if (info.platform == 'web') {
			isMobile = false;
			isIOS = false;
		} else {
			isMobile = true;
			if (info.platform == 'ios') {
				isIOS = true;
			}
		}

		window.isMobile = isMobile;
		window.isIOS = isIOS;
		window.isAndroid = !isIOS;

		if (isMobile) {
			this.commonService.handleBackButton();
			!isIOS && StatusBar.setOverlaysWebView({ overlay: false });
			// if no link appears in 5 seconds -> route to no-session
			this.clearNoLinkTimeout();
			this.noLinkTimeout = setTimeout(() => {
				this.noSession = true;
			}, 1000 * 10);
			// mobile
			App.addListener('appUrlOpen', (data: any) => {
				this.clearNoLinkTimeout();
				this.zone.run(() => {
					logger.log('-----LINK-----', data);

					const inviteKey = data?.url?.trim()?.length ? data.url?.split('/')?.pop() : null;
					if (inviteKey?.trim()?.length) {
						logger.log('Routing to ', inviteKey);
						this.router.navigate([`dashboard/${inviteKey}`])
							.then(logger.log)
							.catch(logger.error);
					}
				});
			});
		}
	}

	private async routeApp(params: { id?: string }) {
		await this.detectDevice();
			if (params?.id?.length) {
				this.clearNoLinkTimeout();
				this.noSession = false;
				if (this.sessionService.sessionStatus === SessionStatus.SESSION_STARTED) {
					// do nothing if same key comes twice during a session
					if (this.commonService.joiningKey == params.id) {
						return;
					}
					const result = await confirmBox({
						title: 'End call',
						text: 'Do you wish to end this call and join another?',
						icon: ICON.END_SESSION
					});
					if (result && result.value) {
						this.commonService.endCall();
						this.rejoinSession();
					} else if (this.commonService.joiningKey?.trim()?.length) {
						this.router.navigate([`dashboard/${this.commonService.joiningKey}`])
							.then(logger.log)
							.catch(logger.error);
					}
				} else if (this.commonService.joiningKey?.trim()?.length) {
					this.commonService.endCall(true);
					this.rejoinSession();
				} else {
					this.commonService.joiningKeyChangeSubject.next(params.id);
					this.changeDetectorRef.detectChanges();
				}
			} else if(!isMobile){
				this.noSession = true;
			}
	}

	public rejoinSession() {
		window.location.reload();
	}

	private clearNoLinkTimeout() {
		if(this.noLinkTimeout) {
			clearTimeout(this.noLinkTimeout);
			this.noLinkTimeout = null;
		}
	}

	ngOnDestroy() {
		this.paramSub && this.paramSub.unsubscribe();
		App.removeAllListeners();
	}
}
