import { DashboardComponent } from './components/dashboard/dashboard.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { RecorderComponent } from './components/recorder/recorder.component';
import { NoSessionComponent } from './components/no-session/no-session.component';

const routes: Routes = [
	{
		path: '',
		redirectTo: 'dashboard/',
		pathMatch: 'full'
	},
	{
		path: 'dashboard',
		redirectTo: 'dashboard/',
		pathMatch: 'full'
	},
	{
		path: 'dashboard/:id',
		component: DashboardComponent
	},
	{
		path: 'page-not-found',
		component: PageNotFoundComponent
	},
	{
		path: 'recorder',
		component: RecorderComponent
	},
	{
		path: 'no-session',
		component: NoSessionComponent
	},
	{
		path: '**',
		component: PageNotFoundComponent
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
	exports: [RouterModule]
})
export class AppRoutingModule { }
