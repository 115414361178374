import { Component, ViewEncapsulation, ChangeDetectorRef, AfterViewInit, OnDestroy } from '@angular/core';
import { CommonService } from '../../../services/common.service';
import { MessageType, ChatRoom, CallOptions, USER_TYPE, SessionMode, RecordingEventType } from 'src/constants';
import { ConnectionService } from 'src/app/services/connection.service';
import { SessionService } from 'src/app/services/session.service';
import { Subscription } from 'rxjs';


@Component({
	selector: 'app-moderator',
	templateUrl: './moderator.component.html',
	styleUrls: ['./moderator.component.styl'],
	encapsulation: ViewEncapsulation.ShadowDom
})
export class ModeratorComponent implements AfterViewInit, OnDestroy {

	private chatRooms: Array<ChatRoom> = [
		{
			title: 'Participant',
			type: MessageType.TESTER_MODERATOR
		},
		{
			title: 'Internal',
			type: MessageType.TEAM
		}
	];

	private callOptions: CallOptions = {
		mic: true,
		cam: true,
		screen: true,
		endSession: true,
		notes: true
	};

	public showText = false;
	public text = '';
	public showButton = false;

	private subscriptions: Array<Subscription> = [];
	private testerJoinListener: Subscription = null;

	private disablePopup = false;

	constructor(
		private connectionService: ConnectionService,
		public commonService: CommonService,
		private sessionService: SessionService,
		private changeDetectorRef: ChangeDetectorRef) {
	}

	ngAfterViewInit() {
		// setting up different tabs in chat window
		this.connectionService.setChatRooms(this.chatRooms);
		this.commonService.callOptions = this.callOptions;
		this.changeDetectorRef.detectChanges();

		const sub = this.sessionService.sessionModeChangeListener.subscribe(sessionState => {
			if (sessionState.sessionMode && this.sessionService.sessionMode !== sessionState.sessionMode) {
				switch (sessionState.sessionMode) {
					case SessionMode.DEBRIEFING:
						this.callOptions = {
							mic: true,
							cam: true,
							screen: true,
							endSession: false,
							notes: true
						};
						if (this.sessionService.sessionMode === SessionMode.IN_SESSION) {
							this.showText = false;
							infoBox({ title: 'The Session has ended' });
							this.sessionService.recordingStatus = RecordingEventType.RECORDING_STOPPED;
						}
						this.sessionService.forceDisconnectParticipant();
						break;
					case SessionMode.IN_SESSION:
						this.callOptions = {
							mic: true,
							cam: true,
							screen: true,
							endSession: true,
							notes: true
						};
						this.showText = false;
						
						if (this.disablePopup)
							this.disablePopup = false;
						else
							infoBox('The Session has started. The participant shall not be able to view Observers and Note-takers');
						break;
				}
				this.sessionService.sessionMode = sessionState.sessionMode;
				this.commonService.callOptions = this.callOptions;
				this.changeDetectorRef.detectChanges();
			}
		});

		if (this.sessionService.sessionMode === SessionMode.DEBRIEFING) {
			// sending request to fetch details of pending request by tester
			this.commonService.fetchPendingRequest().then(() => {
				this.text = this.sessionService.testerName + ' is waiting in the lobby. Click to allow them in the session';
				this.showText = true;
				this.showButton = true;
				this.commonService.playNotification();
				this.changeDetectorRef.detectChanges();
			}).catch(() => {
				this.showText = false;
				this.showButton = false;
				this.changeDetectorRef.detectChanges();
			});

			// listening to tester join event
			this.testerJoinListener = this.connectionService.memberChangeEventListener.subscribe(memberMap => {
				if (memberMap && memberMap.has(MessageType.TESTER_MODERATOR) && memberMap.get(MessageType.TESTER_MODERATOR).find(i => i.userType === USER_TYPE.TESTER)) {
					this.text = 'Participant has joined...';
					this.showText = true;
					this.showButton = false;
					this.changeDetectorRef.detectChanges();
					this.testerJoinListener && this.testerJoinListener.unsubscribe();
					setTimeout(() => {
						this.showText = false;
						this.showButton = false;
						this.changeDetectorRef.detectChanges();
					}, 1000);
				}
			});
		}
		this.subscriptions.push(sub);
	}

	allowJoinRequest() {
		confirmBox({
			title: 'Allow Participant?',
			text: `Call will go into Session. <br> Select “Yes” to proceed`,
			icon: ICON.INFO
		}).then(result => {
			if (result.isConfirmed) {
				this.disablePopup = true;
				this.commonService.allowJoinRequest().then(() => {
					this.text = 'Waiting for participant to join...';
					this.showText = true;
					this.showButton = false;
					this.changeDetectorRef.detectChanges();
				});
			}
		});
	}

	ngOnDestroy() {
		this.subscriptions.forEach(sub => sub && sub.unsubscribe());
	}
}
