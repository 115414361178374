import { environment } from 'src/environments/environment';
import { Component, ElementRef, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { Plugins } from '@capacitor/core';
import 'screen-sharing';
import { TechCheckService } from 'src/app/services/tech-check.service';
import { StreamType, TechCheckItem, TECH_CHECK_STATUS, TECH_CHECK_VIDEO_PLAYER_MAX_TIMEOUT } from 'src/constants';

const { ScreenSharing } = Plugins;

@Component({
	selector: 'app-check-screen',
	templateUrl: './check-screen.component.html',
	styleUrls: ['./check-screen.component.styl'],
	encapsulation: ViewEncapsulation.ShadowDom
})

export class CheckScreenComponent {

	public checkItem: TechCheckItem;
	public TECH_CHECK_STATUS = TECH_CHECK_STATUS;
	private timeout;
	private retryTimeout;
	private testTimeout = 1000 * 7; // 5 sec

	private retryTimeInterval;
	public retryTimeElapsed: number = 0;
	public window = window;

	@ViewChild('player') playerElement: ElementRef<HTMLMediaElement>;

	@Input() set check(value) {
		if (value) {
			this.checkItem = value;
		}
	}

	constructor(private techCheckService: TechCheckService) {}

	ngAfterViewInit() {
		if (this.checkItem) {
			this.performTest();
		}
	}

	private showProgressBar(max: number) {
		// updating progress counter
		const interval = 500;
		if(this.retryTimeInterval) {
			clearInterval(this.retryTimeInterval);
			this.retryTimeInterval = null;
		}
		this.retryTimeInterval = setInterval(() => {
			if (this.checkItem.status == TECH_CHECK_STATUS.PROGRESS) {
				if (this.retryTimeElapsed >= 100) {
					this.retryTimeElapsed = 0;
				}
				this.retryTimeElapsed += interval * 100 / max;
				this.retryTimeElapsed = Math.min(100, this.retryTimeElapsed);
			} else {
				clearInterval(this.retryTimeInterval);
				this.retryTimeInterval = null;
			}
		}, interval);
	}

	private performTest() {
		this.clearTimeouts();
		this.showProgressBar(TECH_CHECK_VIDEO_PLAYER_MAX_TIMEOUT);
		// if for `maxRetryTime` secs the status is not changed from progress (means user dismissed broadcast popup on ios), then mark the status failed to allow retry
		this.retryTimeout = setTimeout(() => {
			if (this.checkItem.status == TECH_CHECK_STATUS.PROGRESS) {
				this.checkItem.failReason = 'Please allow screen sharing.';
				this.checkItem.status = TECH_CHECK_STATUS.FAILED;
				this.clearTimeouts();
			}
		}, TECH_CHECK_VIDEO_PLAYER_MAX_TIMEOUT);

		this.techCheckService.checkStream(StreamType.SCREEN).then(() => {
			this.techCheckService.checkIfVideoPlaying(this.playerElement).then(() => {
				this.showProgressBar(this.testTimeout);
				this.timeout = setTimeout(() => {
					this.checkItem.failReason = '';
					this.checkItem.status = TECH_CHECK_STATUS.SUCCESS;
					this.testCompleted();
				}, this.testTimeout);
			}).catch((err) => {
				this.checkItem.failReason = err ? err : 'Screen Sharing not working.';
				this.checkItem.status = TECH_CHECK_STATUS.FAILED;
				this.testCompleted();
			});
		}).catch((err) => {
			this.checkItem.failReason = err;
			this.checkItem.status = TECH_CHECK_STATUS.FAILED;
			this.testCompleted();
		});
	}

	public testCompleted() {
		this.clearTimeouts();
		this.techCheckService.stopCheck();
	}

	public proceed() {
		this.techCheckService.currentCheck = null;
	}

	public retry() {
		this.checkItem.failReason = '';
		this.checkItem.status = TECH_CHECK_STATUS.PROGRESS;
		this.performTest();
	}

	private clearTimeouts() {
		if(this.timeout) {
			clearTimeout(this.timeout);
			this.timeout = null;
		}

		if(this.retryTimeout) {
			clearTimeout(this.retryTimeout);
			this.retryTimeout = null;
		}

		if(this.retryTimeInterval) {
			clearInterval(this.retryTimeInterval);
			this.retryTimeInterval = null;
		}
	}
}
