<div class="tech-check">
	<!-- Not Participant -->
	<ng-container *ngIf="sessionService.userType != USER_TYPE.TESTER">
		<div class="tech-check-content" *ngIf="!enterSession">
			<div class="tech-check-content-box">
				<div class="tech-check-content-box-img">
					<app-svg name="profile"></app-svg>
				</div>
				<div class="tech-check-content-box-desc">
					<div *ngIf="!checkdone">Please wait while we check your device for compatibility...</div>
					<div *ngIf="checkdone">
						<div *ngIf="checkfail">
							Sorry your device is not compatible for this session, fix the problems listed below and try
							again.
						</div>
						<div *ngIf="!checkfail">
							<div *ngIf="!errors" class="tech-check-content-box-title">
								All good! Proceed to join the session now
							</div>
							<div *ngIf="errors"
								class="tech-check-content-box-desc-sub tech-check-content-box-desc-sub-error">
								You might face some problems, either fix the problems listed below and try again or join with reduced settings!
							</div>
						</div>
					</div>
				</div>
				<div class="tech-check-content-box-test">
					<div class="tech-check-content-box-test-item-container" *ngFor="let item of checks">
						<div class="tech-check-content-box-test-item">
							<app-loader *ngIf="item.status == TECH_CHECK_STATUS.PROGRESS" name="spinner"></app-loader>
							<app-svg *ngIf="item.status != TECH_CHECK_STATUS.PROGRESS"
								name="{{ item.key + item.status }}"></app-svg>
							<div class="tech-check-content-box-test-item-text">{{ item.name }}</div>
						</div>
						<div class="tech-check-content-box-test-item-text-sub">{{ item.failReason }}</div>
					</div>
				</div>
				<button class="tech-check-content-box-button" *ngIf="checkdone && !checkfail"
					(click)="enterSession = true">Enter session</button>
			</div>
			<app-footer></app-footer>
		</div>
		<!-- Join Session -->
		<app-session-wait *ngIf="checkdone && !checkfail && enterSession"></app-session-wait>
	</ng-container>

	<!-- Participant -->
	<ng-container *ngIf="sessionService.userType == USER_TYPE.TESTER">
		<div class="tech-check-content-participant" *ngIf="!enterSession && techCheckService.currentCheck">
			<app-check-browser [check]="findCheckSpec(TECH_CHECK_TYPES.BROWSER)"
				*ngIf="techCheckService.currentCheck == TECH_CHECK_TYPES.BROWSER"></app-check-browser>
			<app-check-mic [check]="findCheckSpec(TECH_CHECK_TYPES.MIC)"
				*ngIf="techCheckService.currentCheck == TECH_CHECK_TYPES.MIC"></app-check-mic>
			<app-check-camera [check]="findCheckSpec(TECH_CHECK_TYPES.CAM)"
				*ngIf="techCheckService.currentCheck == TECH_CHECK_TYPES.CAM"></app-check-camera>
			<app-check-screen [check]="findCheckSpec(TECH_CHECK_TYPES.SCREEN_SHARING)"
				*ngIf="techCheckService.currentCheck == TECH_CHECK_TYPES.SCREEN_SHARING"></app-check-screen>
		</div>
		<!-- Join Session -->
		<app-session-wait *ngIf="!techCheckService.currentCheck"></app-session-wait>
		<app-footer></app-footer>
	</ng-container>
</div>
