import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SvgUseModule } from '../../../svg-use/svg-use.module';
import { SessionWaitComponent } from './session-wait.component';
import { JoinModule } from '../join/join.module';
import { AppFooterModule } from 'src/app/components/app-footer/app-footer.component';

@NgModule({
	declarations: [
		SessionWaitComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		SvgUseModule,
		JoinModule,
		AppFooterModule
	],
	providers: [],
	exports: [
		SessionWaitComponent
	]
})

export class SessionWaitModule { }
